import React from 'react'
import { primary_bg } from '../themes/themes'

const RadioInput = ({ field, id, value, label, onChange }: any) => {
	const isChecked = field?.value === value
	return (
		<div className='flex items-center mb-2'>
			<input
				type='radio'
				id={id}
				checked={isChecked}
				className='hidden'
				{...field}
				onChange={onChange}
			/>
			<label
				htmlFor={id}
				className={`cursor-pointer flex items-center justify-center w-5 h-5 border border-[${primary_bg}] rounded-full mr-2`}
			>
				<div
					className={`w-3 h-3 bg-[#EC7211]  rounded-full ${
						isChecked ? 'visible' : 'invisible'
					}`}
				/>
			</label>
			<span className=' text-[#7C7F80]'>{label}</span>
		</div>
	)
}

export default RadioInput
