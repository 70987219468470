import React from 'react'
import { Outlet } from 'react-router-dom'

export default function AuthBackGround() {
	return (
		<div
			className={
				'min-h-screen relative flex items-center justify-center bg-[#F1F4FB]'
			}
		>
			{/* Background Image */}
			<div className='absolute top-0 left-0 sm:w-1/7 sm:h-1/4 animate__animated  animate__rotateInDownLeft'>
				<img
					src={`${process.env.PUBLIC_URL}/auth-img-left.svg`}
					alt='Top Right Background'
					className='w-full h-full object-contain'
				/>
			</div>

			{/* Bottom Left Image */}
			<div className='absolute bottom-0  right-0 w-1/8 h-1/5  animate__animated  animate__rotateInDownRight'>
				<img
					src={`${process.env.PUBLIC_URL}/auth-img-right.svg`}
					alt='Bottom Left Background'
					className='w-full h-full object-contain'
				/>
			</div>

			<Outlet />
		</div>
	)
}
