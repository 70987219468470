// api.js
import axios from 'axios'
import { queryParam } from './_model'

const BASE_URL = process.env.REACT_APP_BASE_URL // Replace with your actual API base URL

export const get_permissions_api = async (
	params: queryParam
): Promise<PermissionsModel[]> => {
	return await axios.get(
		`${BASE_URL}/role/permissions?${params.queryKey[1] || ''}`
	)
}

export const create_permissions_url = async (data: PermissionsModel) => {
	return await axios.post(`${BASE_URL}/role`, data)
}

export const update_permissions_url = async (updatedItem: PermissionsModel) => {
	return await axios.put(`${BASE_URL}/role/permissions`, updatedItem)
}

export const delete_permissions_url = async (itemId: string) => {
	await axios.delete(`${BASE_URL}/role/${itemId}`)
}

export type PermissionsModel = {
	model_name: string
	create: boolean
	read: boolean
	update: boolean
	delete: boolean
	role_id: string
}
