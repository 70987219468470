import React from 'react'

export default function SwitchToggleInput({
	label,
	id,
	name,
	type,
	value,
	onChange,
	formik,
	required,
	disabled,
}: any) {
	return (
		<div>
			<div className='flex justify-between items-center'>
				<label
					htmlFor={id}
					className={`block flex text-sm  text-[#7C7F80] 
         ${
						formik?.touched[name] && formik.errors[name] ? 'text-[#F88078]' : ''
					}`}
				>
					{label} {required && <div className='text-[#F44336]'>*</div>}
				</label>
				<div className='relative inline-block'>
					<input
						type='checkbox'
						id={id}
						name={name}
						onChange={onChange}
						value={value}
						disabled={disabled}
						checked={value}
						className='
     peer relative w-11 h-6 p-px bg-gray-100 border 
     border-gray-200 text-transparent rounded-full cursor-pointer 
     transition-colors ease-in-out duration-200 focus:ring-[#EC7211] 
     disabled:opacity-50 disabled:pointer-events-none checked:bg-[#EC7211] 
     checked:text-[#EC7211]  checked:border-blue-200 focus:checked:border-blue-200 
    
     before:inline-block before:size-5 before:bg-[#EC7211]  checked:before:bg-white
     before:translate-x-0 checked:before:translate-x-full before:rounded-full
     before:transform before:ring-0 before:transition before:ease-in-out 
     before:duration-200 
     '
					/>
				</div>
			</div>

			{formik?.touched[name] && formik.errors[name] && (
				<div className='text-red-500 text-sm'>{formik.errors[name]}</div>
			)}
		</div>
	)
}
