// Pagination.js
import React, { useMemo } from 'react'

const page_sizes = [2, 10, 25, 50, 100, 200]
const Pagination = ({
	totalCount,
	currentPage,
	pageSize,
	setPageSize,
	setPageNumber,
}: {
	totalCount: number
	currentPage: number
	pageSize: number
	setPageSize: (val: number) => void
	setPageNumber: (val: number) => void
}) => {
	const pageCount = useMemo(
		() => Math.ceil(totalCount / pageSize),
		[totalCount, pageSize]
	)
	return (
		<nav className='flex justify-between  w-full my-4 m-3'>
			<select
				name=''
				id=''
				className='py-3 px-5 block w-fit min-w-[108px] bg-gray-100 border-gray-200 rounded-lg text-sm 
            focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none '
				onChange={(e) => setPageSize(Number(e.target.value))}
				value={pageSize}
			>
				{page_sizes.map((size, i) => (
					<option className='' value={size} key={i}>
						{size}
					</option>
				))}
			</select>

			<ul className='flex space-x-2 justify-between'>
				<button
					className='min-h-[38px] min-w-[48px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3 text-sm rounded-lg 
					focus:outline-none focus:bg-gray-300 
					disabled:opacity-50 disabled:pointer-events-none disabled:border-none disabled:text-white disabled:bg-gray-400'
					onClick={() => setPageNumber(currentPage - 1)}
					disabled={currentPage === 1}
				>
					<svg
						className='flex-shrink-0 size-5.5'
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						stroke='currentColor'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					>
						<path d='m15 18-6-6 6-6'></path>
					</svg>
				</button>
				<button
					className={`min-h-[38px] min-w-[48px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3
				 text-sm rounded-lg focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none
				 ${currentPage === 1 && 'btn-primary-outline'}
				`}
					onClick={() => setPageNumber(1)}
				>
					{1}
				</button>
				{pageCount === 3 && (
					<button
						className={`min-h-[38px] min-w-[48px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3
				 text-sm rounded-lg focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none
				 ${currentPage === 2 && 'btn-primary-outline'}
				`}
						onClick={() => setPageNumber(2)}
					>
						{2}
					</button>
				)}
				{currentPage !== 1 && pageCount > 3 && (
					<li
						className={`min-h-[38px] min-w-[48px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3
				 text-sm rounded-lg focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none
				`}
					>
						...
					</li>
				)}
				{![1, pageCount].includes(currentPage) && pageCount !== 3 && (
					<li
						className={`min-h-[38px] min-w-[48px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3
				 text-sm rounded-lg  btn-primary-outline focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none}
				`}
					>
						{currentPage}
					</li>
				)}
				{currentPage !== pageCount && pageCount > 3 && (
					<li
						className={`min-h-[38px] min-w-[48px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3
				 text-sm rounded-lg focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none
				`}
					>
						...
					</li>
				)}
				{pageCount > 1 && (
					<button
						className={`min-h-[38px] min-w-[48px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3
				 text-sm rounded-lg focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none
				 ${currentPage === pageCount && 'btn-primary-outline'}
				`}
						onClick={() => setPageNumber(pageCount)}
					>
						{pageCount}
					</button>
				)}
				<button
					className='min-h-[38px] min-w-[48px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3 text-sm rounded-lg 
					focus:outline-none focus:bg-gray-300 
					disabled:opacity-50 disabled:pointer-events-none disabled:border-none disabled:text-white disabled:bg-gray-400'
					onClick={() => setPageNumber(currentPage + 1)}
					disabled={currentPage * pageSize >= totalCount}
				>
					<svg
						className='flex-shrink-0 size-5.5'
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						stroke='currentColor'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					>
						<path d='m9 18 6-6-6-6'></path>
					</svg>
				</button>
			</ul>
		</nav>
	)
}

export default Pagination
