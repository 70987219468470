// import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import Button from '../../../component/Button'
import Input from '../../../component/Input'
// import { primary_bg } from '../../../themes/themes'
import { UserModel, update_profile } from '../../../service/user'
import SelectInput from '../../../component/SelectInput'
import * as Yup from 'yup'

export default function PersonalInformation({ data }: { data?: UserModel }) {
	const [edit] = useState(true)
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			firstname: data?.firstname,
			lastname: data?.lastname,
			other_name: data?.other_name,
			gender: data?.gender,
			email: data?.email,
			phone: data?.phone,
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email('Invalid email address')
				.required('Email is required'),
			firstname: Yup.string().required('First name is required'),
			lastname: Yup.string().required('Last name is required'),
			phone: Yup.string(),
			other_name: Yup.string(),
			gender: Yup.string(),
		}),
		onSubmit: async (values) => {
			await update_profile(values)
		},
	})

	return (
		<form onSubmit={formik.handleSubmit} className='sm:p-3'>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-4 my-4'>
				<Input
					disabled={!edit}
					required={true}
					label='First Name'
					id='firstname'
					name='firstname'
					type='text'
					value={formik.values.firstname}
					onChange={formik.handleChange}
				/>
				<Input
					disabled={!edit}
					required={true}
					label='Last Name'
					id='lastname'
					name='lastname'
					type='text'
					value={formik.values.lastname}
					onChange={formik.handleChange}
				/>
			</div>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-4 my-4'>
				<Input
					disabled={!edit}
					label='Other Name'
					id='other_name'
					name='other_name'
					type='text'
					value={formik.values.other_name}
					onChange={formik.handleChange}
				/>
				<SelectInput
					formik={formik}
					required={false}
					label='Gender'
					id='gender'
					name='gender'
					value={formik.values.gender}
					onChange={formik.handleChange}
					options={[
						{ value: 'male', label: 'Male' },
						{ value: 'female', label: 'Female' },
						{ value: '', label: 'Prefer Not to Say' },
					]}
					option={{ value: 'value', label: 'label' }}
					disabled={!edit}
				/>
			</div> 
			<div className='grid grid-cols-1 md:grid-cols-2 gap-4 my-4'>
				<Input
					disabled={!edit}
					required={true}
					label='Email'
					id='email'
					name='email'
					type='email'
					value={formik.values.email}
					onChange={formik.handleChange}
				/>
				<Input
					disabled={!edit}
					required={true}
					label='Phone Number'
					id='phone'
					name='phone'
					type='text'
					value={formik.values.phone}
					onChange={formik.handleChange}
				/>
			</div>

			{edit && (
				<div className='flex mt-4 flex-row-reverse gap-2 ...'>
					<Button type='reset' title='Cancel' btnStyle='btn-outline' />
					<Button type='submit' title='Submit' btnStyle='btn-primary' />
				</div>
			)}
		</form>
	)
}
