import {
	faChevronRight,
	faMessage,
	faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../../../component/Breadcrumb'
import Button from '../../../component/Button'
import FileSelectInput from '../../../component/FileSelectInput'
import Input from '../../../component/Input'
// import InputWithDropDown from '../../../component/InputWithDropDown'
import ProfileCardAvater from '../../../component/ProfileCardAvater'
import SelectInput from '../../../component/SelectInput'
import { role_api } from '../../../service/role'
import {
	UserModel,
	create_users_api,
	update_users_api,
	user_api,
} from '../../../service/user'
import { secondary_bg } from '../../../themes/themes'
import { countries } from '../../../utility/countries'
import * as Yup from 'yup'
import Optional from './Optional'
import Select from 'react-select'
import { service_api } from '../../../service/service'
// import { settings_api } from '../../../service/settings'
import Textarea from '../../../component/textarea'
import { compareObject } from '../../../component/functions'
import { uploadFile } from '../../../component/uploadFile'
import ProfileUploadFiles from '../../Profile/component/ProfileUploadFiles'

export default function AddTeamMember() {
	const [selected, setSelected] = useState('details')
	const { id } = useParams()
	const { data, refetch } = useQuery({
		queryKey: ['user_api', id!],
		queryFn: user_api,
		enabled: !!id,
	})
	const navigate = useNavigate()
	const breadcrumbItems = [
		{ label: 'Team Member list', url: '/users' },
		{ label: `${id ? 'Edit' : 'Add'} team member`, url: '' },
	]

	const create = useMutation({
		mutationFn: create_users_api,
		onSuccess: (res) => {
			if (res.created) {
				navigate('/users')
			} else toast.error('user already exist')
		},
	})
	const update = useMutation({
		mutationFn: update_users_api,
		onSuccess: () => {
			navigate('/users')
		},
	})

	const { data: role_data }: any = useQuery({
		queryKey: ['roles'],
		queryFn: role_api,
	})
	const { data: services }: any = useQuery({
		queryKey: ['services'],
		queryFn: service_api,
	})
	const initialValues: UserModel = data || {
		firstname: '',
		lastname: '',
		other_name: '',
		email: '',
		phone: '',
		gender: '',
		address: '',
		zip_code: '',
		city: '',
		state: '',
		country: '',
		billing_frequency: 'hourly',
		billing_rate: 0,
		job_title: '',
		note: '',
		role_id: '',
		service_ids: [],
		date_hired: new Date().toISOString().split('T')[0],
	}
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: data || initialValues,
		onSubmit: async (values) => {
			let v = { ...values }
			if (values.id) {
				v = compareObject(values, initialValues)
				v.id = values.id
			}
			if (v.media) {
				const res = await uploadFile(v.media, 'profile')
				if (res.status) v.media = res.data
				else delete v.media
			}
			v.id ? update.mutate(v) : create.mutate(v)
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email('Invalid email address')
				.required('Email is required'),
			firstname: Yup.string().required('First name is required'),
			lastname: Yup.string().required('Last name is required'),
			phone: Yup.string(),
			role_id: Yup.string().required('Role is required'),
			// status: Yup.string().required('Status is required'),
			billing_rate: Yup.string(),
			gender: Yup.string(),
		}),
	})
	return (
		<div>
			<div className='ml-3 sm:mb-6'>
				<Breadcrumb items={breadcrumbItems} />
			</div>
			<div className=''>
				<div className='md:flex '>
					<div className='px-3'>
						<ProfileCardAvater data={data} refetch={refetch} />
						{id && (
							<>
								<div className='card px-4 py-2 m-5 '>
									<ContactItem title={data?.email} icon={faMessage} />
									<ContactItem title={data?.phone} icon={faPhone} />
								</div>
								<SideNav selected={selected} setSelected={setSelected} />
							</>
						)}
					</div>

					{id && selected === 'files' ? (
						<div className='w-full'>
							<div className='bg-white sm:rounded-tl-none   rounded-xl w-full p-4'>
								<div className='text-gray-500 dark:text-gray-400'>
									<ProfileUploadFiles id={id} />
								</div>
							</div>
						</div>
					) : (
						<form
							onSubmit={formik.handleSubmit}
							className='sm:p-5 mb-3 p-3 rounded-xl  w-full bg-white'
						>
							<div className='grid grid-cols-1 md:grid-cols-2 my-3 gap-4'>
								<Input
									formik={formik}
									required={true}
									label='First name'
									id='firstname'
									name='firstname'
									type='text'
									value={formik.values.firstname}
									onChange={formik.handleChange}
								/>
								<Input
									formik={formik}
									required={true}
									label='Last name'
									id='lastname'
									name='lastname'
									type='text'
									value={formik.values.lastname}
									onChange={formik.handleChange}
								/>
								<Input
									formik={formik}
									required={true}
									label='Email'
									id='email'
									name='email'
									type='email'
									value={formik.values.email}
									onChange={formik.handleChange}
								/>
								<Input
									formik={formik}
									label='Phone no'
									id='phone'
									name='phone'
									type='text'
									value={formik.values.phone}
									onChange={formik.handleChange}
								/>
								<SelectInput
									formik={formik}
									required={false}
									label='Gender'
									id='gender'
									name='gender'
									value={formik.values.gender}
									onChange={formik.handleChange}
									options={[
										{ value: 'male', label: 'Male' },
										{ value: 'female', label: 'Female' },
										{ value: '', label: 'Prefer Not to Say' },
									]}
									option={{ value: 'value', label: 'label' }}
								/>
								<SelectInput
									required={true}
									label='Role'
									id='role_id'
									formik={formik}
									name='role_id'
									value={formik.values.role_id}
									onChange={formik.handleChange}
									options={role_data?.rows}
									option={{
										value: 'id',
										label: 'name',
									}}
								/>
								<div>
									<label
										htmlFor='user_schedules'
										className='block flex text-sm mb-1 text-[#7C7F80]'
									>
										Services
									</label>
									{services && (
										<Select
											defaultValue={services?.rows
												.filter((service: any) => {
													return formik.values.service_ids?.includes(service.id)
												})
												.map((service: any) => {
													return { value: service.id, label: service.name }
												})}
											isMulti
											name='service_ids'
											options={services?.rows.map((service: any) => {
												return { value: service.id, label: service.name }
											})}
											className='basic-multi-select'
											classNamePrefix='select'
											onChange={(selectedOption: any) => {
												formik.setFieldValue(
													'service_ids',
													selectedOption.map((x: any) => x.value)
												)
											}}
										/>
									)}
								</div>
								<Input
									formik={formik}
									label='Pay rate/Hr'
									id='billing_rate'
									name='billing_rate'
									type='number'
									value={formik.values.billing_rate}
									onChange={formik.handleChange}
								/>
								{/* <InputWithDropDown
								formik={formik}
								dropDownList={[{ label: settings?.currency || 'USD' }]}
								label='Pay rate/Hr'
								id='billing_rate'
								name='billing_rate'
								type='number'
								value={formik.values.billing_rate}
								onChange={formik.handleChange}
							/> */}
								<SelectInput
									label='Country'
									id='country'
									name='country'
									value={formik.values.country}
									onChange={formik.handleChange}
									options={countries.map((country) => {
										return { id: country, name: country }
									})}
									option={{
										value: 'id',
										label: 'name',
									}}
									required={false}
								/>
								<Input
									formik={formik}
									label='Zip Code'
									id='zip_code'
									name='zip_code'
									type='text'
									value={formik.values.zip_code}
									onChange={formik.handleChange}
								/>
								<Input
									formik={formik}
									label='State'
									id='state'
									name='state'
									type='text'
									value={formik.values.state}
									onChange={formik.handleChange}
								/>
								<Input
									formik={formik}
									label='City'
									id='city'
									name='city'
									type='text'
									value={formik.values.city}
									onChange={formik.handleChange}
								/>
								<div className='col-span-2'>
									<Optional
										children={
											<div className='grid grid-cols-2 md:grid-cols-2 gap-4 my-3  col-span-2'>
												<FileSelectInput
													meta={formik.getFieldMeta('media')}
													name='media'
													label='Upload Image(JPG, PNG)'
													onChange={(file: any) =>
														formik.setFieldValue('media', file)
													}
													accept='image/*'
												/>

												<Input
													formik={formik}
													label='Date hired'
													id='date_hired'
													name='date_hired'
													type='date'
													value={formik.values.date_hired}
													onChange={formik.handleChange}
												/>
												<Input
													formik={formik}
													label='Job title'
													id='job_title'
													name='job_title'
													type='text'
													value={formik.values.job_title}
													onChange={formik.handleChange}
												/>
												<Textarea
													label='Note'
													name='note'
													onChange={formik.handleChange}
													value={formik.values.note}
													id='note'
												/>
												<div className='flex mt-3 gap-4' role='group'>
													<p className='text-[#7C7F80]'>Status</p>
													<div className='flex items-center me-4'>
														<input
															type='radio'
															id='two'
															name='status'
															value='true'
															onChange={formik.handleChange}
															className='w-6 h-6 text-orange-500 bg-gray-100 border-gray-300 focus:ring-orange-500 '
															checked={formik.values.status === true}
														/>
														<label
															htmlFor='yellow-radio'
															className='ms-2 text-sm font-medium text-gray-900 '
														>
															Active
														</label>
													</div>
													<div className='flex items-center me-4'>
														<input
															type='radio'
															id='false'
															name='status'
															value='false'
															onChange={formik.handleChange}
															className='w-6 h-6 text-orange-500 bg-gray-100 border-gray-300 focus:ring-orange-500 '
															checked={formik.values.status === false}
														/>
														<label
															htmlFor='yellow-radio'
															className='ms-2 text-sm font-medium text-gray-900 '
														>
															Inactive
														</label>
													</div>
												</div>
												{formik?.errors.status}
												{formik?.touched.status && formik.errors.status && (
													<div className='text-red-500 text-sm'>
														{formik.errors.status}
													</div>
												)}
											</div>
										}
									/>
								</div>
							</div>
							{/* Add other Input components as needed */}

							<div className='flex  flex-row-reverse gap-2 '>
								<Button
									onClick={() => navigate('/users')}
									type='reset'
									btnStyle='btn-outline'
									title='Cancel'
								/>
								{(!formik.values.id ||
									formik.values.onboard_status === 'draft') && (
									<Button
										onClick={() =>
											formik.setFieldValue('onboard_status', 'draft')
										}
										type='submit'
										btnStyle='btn-blue'
										title='Draft'
										loading={create.isPending || update.isPending}
									/>
								)}
								{formik.values.id &&
								formik.values.onboard_status !== 'draft' ? (
									<>
										<Button
											type='submit'
											btnStyle='btn-primary'
											title='Update'
											loading={create.isPending || update.isPending}
										/>
										{!formik.values.status && (
											<Button
												type='submit'
												btnStyle='btn-primary'
												title='Re-Send Invite'
												loading={create.isPending || update.isPending}
												onClick={() =>
													formik.setFieldValue('resend_invite', true)
												}
											/>
										)}
									</>
								) : (
									<Button
										onClick={() =>
											formik.setFieldValue('onboard_status', 'sent')
										}
										type='submit'
										btnStyle='btn-primary'
										title='Save & Send'
										loading={create.isPending || update.isPending}
									/>
								)}
							</div>
							<br />
						</form>
					)}
				</div>
			</div>
		</div>
	)
}

const ContactItem = ({ title, icon }: { title: any; icon: any }) => {
	return (
		<div className='flex my-3'>
			<div
				className={`w-6 h-6 border-3 border-[red] outline outline-offset-2 outline-[#FFF4EC]
                text-[13px] p  text-center bg-[${secondary_bg}] rounded-full`}
			>
				<FontAwesomeIcon color='#EC7211' className='text-[10px]' icon={icon} />
			</div>
			<p className='mb-2 ml-3 text-sm text-[#7C7F80] '>{title}</p>
		</div>
	)
}
const SideNav = ({ selected, setSelected }: any) => {
	return (
		<div className='bg-white rounded p-2 flex flex-col  gap-2 h-fit'>
			<button
				onClick={() => setSelected('details')}
				className={`flex w-full justify-between p-3  transition-all duration-300 
				 ${selected === 'details' && `text-primary bg-[${secondary_bg}]`} 
				 rounded items-center`}
			>
				<p>User Details</p>
				<FontAwesomeIcon icon={faChevronRight} />
			</button>
			<button
				onClick={() => setSelected('files')}
				className={`flex w-full justify-between p-3  transition-all duration-300 
				 ${selected === 'files' && `text-primary bg-[${secondary_bg}]`} 
				 rounded items-center`}
			>
				<p>Onboarding Files</p>
				<FontAwesomeIcon icon={faChevronRight} />
			</button>
		</div>
	)
}
