import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ items }: any) => {
	const style = {
		// fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: '500',
		lineHeight: '24px' /* 171.429% */,
		letterSpacing: '0.1px',
	}
	return (
		<nav className='no-print text-sm ' style={style} aria-label='Breadcrumb'>
			<ol
				className='flex items-center whitespace-nowrap'
				aria-label='Breadcrumb'
			>
				<FontAwesomeIcon className='mr-2' icon={faArrowLeft} />
				{items.map((item: any, index: any) => (
					<li key={index} className='inline-flex items-center'>
						{index > 0 && (
							<div className='flex items-center text-sm text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 '>
								<svg
									className='flex-shrink-0 mx-2 overflow-visible h-4 w-4 text-gray-400 '
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								>
									<path d='m9 18 6-6-6-6' />
								</svg>
							</div>
						)}

						{index === items.length - 1 ? (
							<span className={`text-primary`}>{item.label} </span>
						) : (
							<Link to={item.url} className='hover:underline'>
								{item.label}
							</Link>
						)}
					</li>
				))}
			</ol>
		</nav>
	)
}

export default Breadcrumb
