import {
	faPenToSquare,
	faPlus,
	faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo, useState } from 'react'
import Modal from '../../../component/Modal'
import Table from '../../../component/table'
import 'react-quill/dist/quill.snow.css'
import Input from '../../../component/Input'
import SelectInput from '../../../component/SelectInput'
import Button from '../../../component/Button'
import {
	OnboardingSettingsModel,
	create_onboarding_settings_api,
	delete_onboarding_settings_api,
	onboarding_settings_api,
	update_onboarding_settings_api,
} from '../../../service/settings'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { compareObject } from '../../../component/functions'
import * as Yup from 'yup'
import RadioInput from '../../../component/RadioInput'

const validationSchema = Yup.object({
	name: Yup.string().required().label('Name'),
	type: Yup.string().required().label('Type'),
})
export default function Onboarding() {
	const [showModal, setShowModal] = useState<'create' | 'edit'>()
	const [selectedData, setSelectedData] = useState<OnboardingSettingsModel>()
	const { data, refetch } = useQuery({
		queryKey: ['onboarding_settings'],
		queryFn: onboarding_settings_api,
	})
	const deleteMutation = useMutation({
		mutationFn: delete_onboarding_settings_api,
		onSuccess: () => {
			refetch()
		},
	})
	const columns = [
		// { key: 'id', title: 'File ID' },
		{ key: 'name', title: 'File name' },
		{ key: 'type', title: 'File type' },
		{
			key: 'status',
			title: 'status',
			render: (value: any, row: any) => (
				<div
					className={`${
						value ? ' text-green-600 bg-green-100' : 'text-red-600 bg-red-100'
					} 
                      text-[12px] text-center px-2 py-1 w-fit   rounded-full`}
				>
					{value ? 'Active' : 'Inactive'}
				</div>
			),
		},
		{
			key: 'id',
			title: 'Actions',
			render: (value: any, row: any) => (
				<>
					<button
						onClick={() => {
							setSelectedData(row)
							setShowModal('edit')
						}}
						className={`pl-4`}
					>
						<FontAwesomeIcon icon={faPenToSquare} />
					</button>
					<button
						onClick={() => deleteMutation.mutate(value)}
						className={`pl-4 text-[#F44336]`}
					>
						<FontAwesomeIcon icon={faTrash} />
					</button>
				</>
			),
		},
	]
	const initialValues = useMemo(
		() => selectedData || { name: '', type: '' },
		[selectedData]
	)

	const mutation = useMutation({
		mutationFn: initialValues.id
			? update_onboarding_settings_api
			: create_onboarding_settings_api,

		onSuccess: () => {
			refetch()
			setShowModal(undefined)
		},
	})
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit: async (values) => {
			let v = { ...values }
			if (values.id && selectedData) {
				v = compareObject(values, selectedData)
				v.id = values.id
			}
			mutation.mutate(v)
		},
		validationSchema,
	})

	return (
		<div>
			<div>
				<div className=' sm:flex p-4 justify-between'>
					<p className='text-[16px] font-medium'>Onboarding Settings</p>
					<div className='flex'>
						<Button
							btnStyle='btn-primary'
							rightIcon={<FontAwesomeIcon icon={faPlus} />}
							title='Add Field'
							onClick={() => {
								setSelectedData(undefined)
								setShowModal('create')
							}}
							type={undefined}
							disabled={false}
							className={'mr-2'}
						/>
					</div>
				</div>
				<Table
					columns={columns}
					data={data?.rows}
					onSelectedRowsChange={() => {}}
					showHeaderIcon={undefined}
					hasCheckBox={true}
				/>
			</div>

			<Modal
				showModal={!!showModal}
				setShowModal={setShowModal}
				title=''
				size={'lg'}
			>
				<form
					className='gap-3 flex flex-col h-fit'
					onSubmit={formik.handleSubmit}
				>
					<Input
						required={true}
						label='Name'
						id='name'
						name='name'
						type='text'
						value={formik.values.name}
						onChange={formik.handleChange}
					/>

					<SelectInput
						label='Type'
						id='type'
						name='type'
						onChange={formik.handleChange}
						value={formik.values.type}
						options={['text', 'image', 'pdf'].map((item) => ({
							id: item,
							name: item,
						}))}
						option={{ value: 'id', label: 'name' }}
						required={true}
					/>
					{formik.values.id && (
						<div className=' gap-3 mb-2'>
							<p className='text-[#7C7F80]'>Status</p>
							<div className='flex gap-3 mt-2'>
								<RadioInput
									id='Status'
									name='status'
									value={true}
									label='Active'
									field={formik.getFieldProps('status')}
									onChange={() => formik.setFieldValue('status', true)}
								/>
								<RadioInput
									id='StatusNo'
									name='status'
									value={false}
									label='Inactive'
									field={formik.getFieldProps('status')}
									onChange={() => formik.setFieldValue('status', false)}
								/>
							</div>
						</div>
					)}
					<div className='flex flex-row-reverse gap-2'>
						<Button
							onClick={() => setShowModal(undefined)}
							title='Close'
							btnStyle='btn-outline'
							type='button'
						/>
						<Button
							type='submit'
							btnStyle='btn-primary'
							loading={mutation.isPending}
							title={formik.values.id ? 'Update' : 'Create'}
						/>
					</div>
				</form>
			</Modal>
		</div>
	)
}
