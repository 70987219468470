


export function hoursToMinutes(hours: number) {
  if (typeof hours !== 'number') {
    return 'Invalid input. Please provide a valid number of hours.';
  }
  const minutes = hours * 60;
  return minutes;
}

export function minuteToHours(minutes: any) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const timeObject = {
    hours: hours,
    minutes: remainingMinutes
  };

  return timeObject;


}


export function convertDateFormat(originalDateString: any) {
  const originalDate = new Date(originalDateString);
  const formattedDate = originalDate.toISOString().replace('T', ' ').replace('.000Z', '');
  return formattedDate;
}
