import { useRouteError } from "react-router-dom";

export default function UnderDevelopment() {
  const error:any = useRouteError();
  console.error(error);
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-gray-800 dark:text-gray-200 mb-4">
            Under development
        </h1>
        <p className="text-xl text-gray-600 dark:text-gray-400 mb-8">
          this feature is currently under development
        </p>
        {/* Add any additional content or links as needed */}
      </div>
    </div>
  );
};
