import { faSort } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import Loading from './loading'

const Table = ({
	columns,
	data,
	onSelectedRowsChange,
	showHeaderIcon,
	hasCheckBox,
	tableStyle,
	isLoading,
}: {
	columns: any
	data: any
	onSelectedRowsChange?: any
	showHeaderIcon?: boolean
	hasCheckBox?: any
	tableStyle?: any
	isLoading?: boolean
}) => {
	const [selectedRows, setSelectedRows]: any = useState([])

	useEffect(
		() => {
			// Notify the parent component when selected rows change
			if (onSelectedRowsChange) {
				onSelectedRowsChange(selectedRows)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
		//  [selectedRows, onSelectedRowsChange]
	)

	const handleCheckboxChange = (rowId: any) => {
		if (selectedRows.includes(rowId)) {
			setSelectedRows((prevSelectedRows: any[]) =>
				prevSelectedRows.filter((id) => id !== rowId)
			)
		} else {
			setSelectedRows((prevSelectedRows: any) => [...prevSelectedRows, rowId])
		}
	}

	return (
		<div>
			<div className='flex flex-col m-1'>
				<div className='-m-1.5 overflow-x-auto'>
					<div className='p-1.5 min-w-full inline-block align-middle'>
						<div className={`  overflow-hidden  ${tableStyle}`}>
							<table className='min-w-full   '>
								<thead className={`text-xs text-gray-700 bg-[#FFF4EC]  `}>
									<tr>
										{hasCheckBox && (
											<th
												scope='col'
												className='px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase'
											>
												<input
													type='checkbox'
													className='border-gray-900 rounded text-[primary] focus:ring-blue-500 '
													onChange={() =>
														setSelectedRows(
															selectedRows.length === data.length
																? []
																: data.map((row: any) => row.id)
														)
													}
												/>
											</th>
										)}
										{columns?.map((column: any, i: number) => (
											<th
												key={i}
												scope='col'
												className='px-6 py-3 text-start text-xs font-medium text-gray-500 capitalize'
											>
												<div className='flex'>
													{!showHeaderIcon && !column.excludeSort && (
														<FontAwesomeIcon
															className='sm:mr-1 hidden sm:block pt-0.5 text-gray-500'
															icon={faSort}
														/>
													)}
													<span> {column.title} </span>
												</div>
											</th>
										))}
									</tr>
								</thead>
								<tbody className=' '>
									{isLoading ? (
										<tr>
											<td colSpan={columns.length}>
												<Loading />
											</td>
										</tr>
									) : (
										data?.map((row: any, rowIndex: any) => (
											<tr key={rowIndex}>
												{hasCheckBox && (
													<td className='px-6 py-4 whitespace-nowrap text-sm border-dotted border-b-2 font-medium text-gray-800 '>
														<input
															type='checkbox'
															checked={selectedRows.includes(row.id)}
															onChange={() => handleCheckboxChange(row.id)}
															className='border-gray-900 rounded text-[primary] focus:ring-blue-500 '
														/>
													</td>
												)}
												{columns?.map((column: any, i: number) => (
													<td
														key={i}
														className='px-6 py-4 whitespace-nowrap border-dotted border-b-2  text-sm font-medium text-gray-800 '
													>
														{column.render
															? column.render(row[column.key], row)
															: row[column.key]}
													</td>
												))}
											</tr>
										))
									)}
								</tbody>
							</table>
						</div>
						{data?.length < 1 && (
							<div className='w-full text-center'>
								<h2 className='mt-5 font-semibold text-gray-800 dark:text-white'>
									There is no data in this table
								</h2>
								{/* <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              Draft an invoice and send it to a customer.
            </p> */}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Table
