import { useFormik } from 'formik'
import React from 'react'
import Button from '../../../component/Button'
import Input from '../../../component/Input'
import SelectInput from '../../../component/SelectInput'
import { UserModel, update_profile } from '../../../service/user'
import { countries } from '../../../utility/countries'
import Textarea from '../../../component/textarea'

function Location({ data }: { data?: UserModel }) {
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			address: data?.address,
			city: data?.city,
			country: data?.country,
			state: data?.state,
			zip_code: data?.zip_code,
		},
		onSubmit: (values) => {
			update_profile(values)
		},
	})
	return (
		<form onSubmit={formik.handleSubmit} className='sm:p-3'>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
				<SelectInput
					formik={formik}
					label='Country'
					id='country'
					name='country'
					onChange={formik.handleChange}
					value={formik.values.country}
					options={countries.map((country) => ({
						id: country,
						name: country,
					}))}
					option={{ value: 'id', label: 'name' }}
					required={true}
				/>
				<Input
					formik={formik}
					required={true}
					label='State'
					id='state'
					name='state'
					type='address'
					value={formik.values.state}
					onChange={formik.handleChange}
				/>
			</div>
			<div className='grid grid-cols-1 my-4 md:grid-cols-2 gap-4'>
				<Input
					formik={formik}
					required={true}
					label='City'
					id='city'
					name='city'
					type='address'
					value={formik.values.city}
					onChange={formik.handleChange}
				/>
				<Input
					formik={formik}
					label='Zip Code'
					id='zip_code'
					name='zip_code'
					type='text'
					value={formik.values.zip_code}
					onChange={formik.handleChange}
				/>
			</div>
			<Textarea
				label='Address'
				name='address'
				onChange={formik.handleChange}
				value={formik.values.address}
				id='address'
			/>

			{/* Add other Input components as needed */}

			<div className='flex  flex-row-reverse mt-5 gap-2 ...'>
				<Button type='submit' btnStyle='btn-outline' title='Cancel' />
				<Button type='submit' title='Submit' btnStyle='btn-primary' />
			</div>
		</form>
		// </div>
		// </div>
	)
}
export default Location
