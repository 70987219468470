import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom'
import { primary_bg } from '../../themes/themes'
import { useMutation } from '@tanstack/react-query'
import { forget_password_api } from '../../service/auth'
import { SubmitButton } from '../../component/Buttons'

const Forget = () => {
	const navigate = useNavigate()
	const mutation = useMutation({ mutationFn: forget_password_api })
	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: Yup.object({
			email: Yup.string().email('Invalid email address').required('Required'),
		}),

		onSubmit: (values: any) => mutation.mutate(values),
	})

	return (
		<div className='relative z-10 bg-white p-8 rounded-lg sm:w-96'>
			<h2 className='text-2xl  mb-1 text-center'>Forgot Password</h2>
			{mutation.isSuccess ? (
				<>
					<p className='text-[#7C7F80] text-sm text-center'>
						Please check your mailbox for instructions on how to reset your
						password
					</p>
					<SubmitButton
						text='Back to login'
						onClick={() => navigate('/auth/login')}
					/>
				</>
			) : (
				<>
					<p className='text-[#7C7F80] text-sm text-center'>
						Please enter the email registered to you account
					</p>

					{/* Form */}
					<form onSubmit={formik.handleSubmit}>
						{/* Username Input */}
						<div className='mb-4'>
							<label
								htmlFor='username'
								className='block text-sm flex font-medium mt-5 text-[#7C7F80]'
							>
								Email <div className='text-[#F44336]'>*</div>
							</label>
							<input
								type='text'
								id='email'
								name='email'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.email}
								className={`mt-1 p-2 w-full border ${
									formik.touched.email && formik.errors.email
										? 'border-red-500'
										: 'border-gray-300'
								} rounded-md`}
							/>
							{/* {formik.touched.email && formik.errors.email && (
                            <div className="text-red-500 text-sm">{formik.errors.email}</div>
                        )} */}
						</div>

						<div className='flex justify-between my-2'>
							<Link to={'/auth/login'}>
								<small className={`text-[${primary_bg}] text-sm  `}>
									Back to login
								</small>
							</Link>
						</div>
						{/* Submit Button */}
						<SubmitButton loading={mutation.isPending} text='Submit' />
					</form>
				</>
			)}
		</div>
	)
}

export default Forget
