import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

export default function PasswordInput({
	label,
	id,
	name,
	value,
	onChange,
	required,
	className,
	formik,
}: any) {
	const [showPassword, setShowPassword] = useState(true)

	const handleTogglePassword = () => {
		setShowPassword(!showPassword)
	}

	return (
		<div>
			<div className=' relative'>
				<label
					htmlFor={id}
					className={`block flex text-sm  text-[#7C7F80]  ${
						formik.touched[name] && formik.errors[name] ? 'text-[#F88078]' : ''
					}`}
				>
					{label} {required && <div className='text-[#F44336]'>*</div>}
				</label>
				<input
					type={showPassword ? 'password' : 'text'}
					id={id}
					name={name}
					onChange={onChange}
					className={`mt-1 p-2 w-full bg-transparent border ${className} ${
						formik.touched[name] && formik.errors[name]
							? 'border-red-500'
							: 'border-gray-300'
					} rounded-md`}
					value={value}
				/>

				<button
					type='button'
					className={`absolute inset-y-2 text-gray-900 right-0 pt-6 p-2 focus:outline-none 
                        ${
													formik.touched[name] && formik.errors[name]
														? 'text-[#F88078]'
														: ''
												}
                    `}
					onClick={handleTogglePassword}
				>
					{showPassword ? (
						<FontAwesomeIcon icon={faEye} />
					) : (
						// <img  src={`${process.env.PUBLIC_URL}/icon/eye-slash.svg`} />
						<img src={`${process.env.PUBLIC_URL}/icon/eye-slash.svg`} alt='' />
					)}
				</button>
			</div>

			{formik.touched[name] && formik.errors[name] && (
				<p className='text-red-500 text-sm'>{formik.errors[name]}</p>
			)}
		</div>
	)
}
