import { useFormik } from 'formik'
import React from 'react'
import { useMutation } from '@tanstack/react-query'
import FileSelectInput from '../../component/FileSelectInput'
import Input from '../../component/Input'
// import InputWithDropDown from '../../component/InputWithDropDown'
import RadioInput from '../../component/RadioInput'
import {
	create_service_api,
	initialService,
	update_service_api,
} from '../../service/service'
import Textarea from '../../component/textarea'
import Button from '../../component/Button'
import { compareObject } from '../../component/functions'
import { uploadFile } from '../../component/uploadFile'

export function CreateService({ setShowModal, refetch, selectedData }: any) {
	const mutation = useMutation({
		mutationFn: selectedData?.id ? update_service_api : create_service_api,
		onSuccess: () => {
			refetch()
			setShowModal(false)
		},
	})
	const initialValues = selectedData
		? {
				...selectedData,
				duration_hour: Math.floor(selectedData.duration_min / 60),
				duration_min: selectedData.duration_min % 60,
		  }
		: initialService
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit: async (values) => {
			let v = { ...values }
			v.duration_min = values.duration_hour * 60 + values.duration_min
			delete v.duration_hour
			if (values.id) {
				v = compareObject(values, initialValues)
				v.id = values.id
			}
			if (v.media) {
				const res = await uploadFile(values.media, 'service')
				if (res.status) v.media = res.data
				else delete v.media
			}
			mutation.mutate(v)
		},
	})

	return (
		<div>
			<form onSubmit={formik.handleSubmit} className='sm:p-3'>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
					<Input
						label='Name'
						id='name'
						name='name'
						type='text'
						value={formik.values.name}
						onChange={formik.handleChange}
					/>

					<Input
						label='Charge/hr'
						id='charge'
						name='charge'
						type='number'
						value={formik.values.charge}
						onChange={formik.handleChange}
					/>
					{formik.values.id && (
						<div className=' gap-3'>
							<p className='text-[#7C7F80] font-[400] text-[14px]'>Status</p>
							<div className='flex gap-3 pt-2'>
								<RadioInput
									id='Status'
									name='status'
									value={true}
									label='Active'
									field={formik.getFieldProps('status')}
									onChange={() => formik.setFieldValue('status', true)}
								/>
								<RadioInput
									id='StatusNo'
									name='status'
									value={false}
									label='Inactive'
									field={formik.getFieldProps('status')}
									onChange={() => formik.setFieldValue('status', false)}
								/>
							</div>
						</div>
					)}
				</div>

				<div className='mt-2'>
					<Textarea
						label='Note'
						id='note'
						name='note'
						type='text'
						value={formik.values.note}
						onChange={formik.handleChange}
					/>
					<FileSelectInput
						meta={formik.getFieldMeta('media')}
						name='media'
						label='Upload Image(JPG, PNG)'
						onChange={(file: any) => formik.setFieldValue('media', file)}
						accept='image/*'
					/>
				</div>
				<br />
				{/* Add other Input components as needed */}

				<div className='flex flex-row-reverse gap-2 ...'>
					<Button
						btnStyle='btn-outline'
						title='Cancel'
						type='button'
						onClick={() => setShowModal()}
					/>
					<Button
						type='submit'
						btnStyle='btn-primary'
						loading={mutation.isPending}
						title={formik.values.id ? 'Update' : 'Create'}
					/>
				</div>
			</form>
		</div>
	)
}
