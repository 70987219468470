import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDebounce } from '@uidotdev/usehooks'
import React, { useMemo, useState } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import Modal from '../../../component/Modal'
import Pagination from '../../../component/Pagination'
import Table from '../../../component/table'
import PayrollForm from './PayrollForm'
import { delete_payroll_api, payroll_api } from '../../../service/payroll'
import { useNavigate } from 'react-router-dom'
import { CreateButton, DeleteButton } from '../../../component/Buttons'
import FilterForm, { initialParams } from '../../../component/FilterForm'
import CsvExportButton from '../../../component/CsvExportButton'

export default function Payroll() {
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)
	const [pageParams, setPageParams] = useState(initialParams)
	const [search] = useState('')
	const searchInput = useDebounce(search, 500)
	const queryParams = useMemo(() => {
		let res = `page_number=${pageParams.page_number}&page_size=${pageParams.page_size}`
		if (searchInput) res += `&search=${searchInput}`
		if (pageParams.filter) res += `&filter=${pageParams.filter}`
		return res
	}, [pageParams, searchInput])
	const updatePageParams = (key: string, value: any) => {
		setPageParams((prev) => ({ ...prev, [key]: value }))
	}
	const { data, refetch } = useQuery({
		queryKey: ['payrolls', queryParams],
		queryFn: payroll_api,
	})
	const deleteMutation = useMutation({
		mutationFn: delete_payroll_api,
		onSuccess: () => {
			refetch()
		},
	})
	const columns = [
		{ key: 'user_id', title: 'User ID' },
		{
			key: 'user_id',
			title: 'User',
			render: (value: any, row: any) =>
				row.user.firstname + ' ' + row.user.lastname,
		},
		{
			key: 'user.role_id',
			title: 'Role',
			render: (value: any, row: any) => row.user.role.name,
		},
		{
			key: 'start_date',
			title: 'Interval',
			render: (value: any, row: any) => value + ' - ' + row.end_date,
		},
		{
			key: 'net_pay',
			title: 'Salary',
			render: (value: any, row: any) =>
				value?.toLocaleString('en-US', {
					style: 'currency',
					currency: row.currency || 'USD',
				}),
		},
		{
			key: 'status',
			title: 'Status',
			render: (value: any, row: any) => (
				<>
					<div
						className={`${
							value ? ' text-green-600 bg-green-100' : 'text-red-600 bg-red-100'
						} 
                  text-[12px] text-center p-1 px-2 w-fit   rounded-full`}
					>
						{value ? 'Active' : 'Inactive'}
					</div>
				</>
			),
		},
		{
			key: 'id',
			title: 'Action',
			render: (value: any, row: any) => (
				<div className={`flex gap-2`}>
					<FontAwesomeIcon
						onClick={() => navigate('/finances/payrolls/preview/' + value)}
						icon={faEye}
					/>
					<DeleteButton
						onClick={() => deleteMutation.mutate(value)}
						id='payrolls'
					/>
				</div>
			),
		},
	]
	return (
		<div className=' bg-white rounded-lg'>
			{/* <button onClick={() => setShowModal(true)}>Open Modal</button> */}
			<div className=' sm:flex p-4 justify-between'>
				<p className='text-[24px] font-medium'>Payroll</p>
				<div className='flex'>
					<CreateButton
						onClick={() => setShowModal(!showModal)}
						title='Add Payroll'
						id='payrolls'
					/>
					<CsvExportButton
						data={data?.rows}
						headers={undefined}
						filename={'Payroll'}
						buttonText={'Export as CSV'}
					/>
				</div>
			</div>
			<div className='sm:grid sm:grid-cols-8 gap-4 border-dashed border-t  p-4'>
				<FilterForm
					fields={[
						'status',
						'payment_status',
						'user_id',
						'currency',
						{ name: 'start_date', label: 'Start date', type: 'date' },
						{ name: 'end_date', label: 'End date', type: 'date' },
					]}
					values={JSON.parse(pageParams.filter || '{}')}
					action={(val) => updatePageParams('filter', val)}
				/>
				<div className='col-span-7 '>
					{/* <SearchInput
						placeholder='Search ...'
						value={search}
						onChange={(e: any) => setSearch(e.target.value)}
					/> */}
				</div>
			</div>
			{/* <UnderDevelopment /> */}

			<Table columns={columns} data={data?.rows} />

			<div
				className='flex overflow-x-auto sm:justify-end'
				id='pagination-globle-style'
			>
				<Pagination
					setPageSize={(val: number) => updatePageParams('page_size', val)}
					setPageNumber={(val: number) => updatePageParams('page_number', val)}
					currentPage={pageParams.page_number}
					pageSize={pageParams.page_size}
					totalCount={data?.count || 0}
				/>
			</div>

			<Modal
				showModal={showModal}
				setShowModal={setShowModal}
				title={'Add Payroll'}
				size={'md'}
			>
				<PayrollForm />
			</Modal>
		</div>
	)
}
