import React, { useState } from 'react'
import Breadcrumb from '../../../component/Breadcrumb'
import InvoicePreviewOne from './InvoicePreviewOne'
import InvoicePreviewThree from './InvoicePreviewThree'
import InvoicePreviewTwo from './InvoicePreviewTwo'
import { useQuery } from '@tanstack/react-query'
import { settings_api } from '../../../service/settings'
import { useParams } from 'react-router-dom'
import { one_invoice_api } from '../../../service/Invoice'
import Modal from '../../../component/Modal'
import PaymentModal from './PaymentModal'

export default function InvoicePreview() {
	const [showModal, setShowModal] = useState(false)
	const { id } = useParams()
	const { data: settings } = useQuery({
		queryKey: ['settings'],
		queryFn: settings_api,
	})
	const { data } = useQuery({
		queryKey: ['invoice', id!],
		queryFn: one_invoice_api,
		enabled: !!id,
	})
	const breadcrumbItems = [
		{ label: 'Finance', url: '/Finance' },
		{ label: 'Preview', url: '' },
	]

	return (
		<div>
			<div className='ml-3 '>
				<Breadcrumb items={breadcrumbItems} />
			</div>
			{settings?.invoice_template === '3' ? (
				<InvoicePreviewThree
					data={data}
					settings={settings}
					setShowModal={setShowModal}
				/>
			) : settings?.invoice_template === '2' ? (
				<InvoicePreviewTwo
					data={data}
					settings={settings}
					setShowModal={setShowModal}
				/>
			) : (
				<InvoicePreviewOne
					data={data}
					settings={settings}
					setShowModal={setShowModal}
				/>
			)}
			{id && (
				<Modal
					showModal={showModal}
					setShowModal={setShowModal}
					title={'Confirm Payment'}
					size={'md'}
				>
					<PaymentModal id={id} setShowModal={setShowModal} />
				</Modal>
			)}
		</div>
	)
}
