import { useFormik } from 'formik'
import React, { useMemo } from 'react'
import Input from '../../../component/Input'
import Textarea from '../../../component/textarea'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useAuth } from '../../../context/AuthContext'
import { users_api } from '../../../service/user'
import SelectInput from '../../../component/SelectInput'
import {
	create_timeoff_request_api,
	delete_timeoff_request_date_api,
	TimeOffRequestModel,
	update_timeoff_request_api,
} from '../../../service/timeOffRequest'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { timeoff_category_api } from '../../../service/timeoffCategory'
import dayjs from 'dayjs'
import Button from '../../../component/Button'
import * as Yup from 'yup'
import { MultiSelect } from '../../../component/MultiSelect'
import { primary_bg } from '../../../themes/themes'
import { compareObject } from '../../../component/functions'

const validationSchema = Yup.object().shape({
	user_id: Yup.string().required().label('User'),
	time_off_category_id: Yup.string().required().label('Time Off Category'),
	time_off_request_dates: Yup.array()
		.min(1)
		.of(
			Yup.object().shape({
				date: Yup.string().required().label('Date'),
				duration_in_hours: Yup.number().required(),
			})
		),
})
export default function CreateTimeOffRequest({
	refetch,
	setShowModal,
	selectedData,
}: any) {
	const { currentUser } = useAuth()
	const admin = process.env.REACT_APP_ADMIN?.includes(currentUser?.role?.name)
	const { data: users } = useQuery({
		queryKey: ['users'],
		queryFn: users_api,
		enabled: !!admin,
	})
	const userOptions = useMemo(() => {
		return (
			users?.rows?.map((data: any) => ({
				value: data.id,
				label: data.firstname + ' ' + data.lastname,
			})) || null
		)
	}, [users])

	const createMutation = useMutation({
		mutationFn: selectedData?.id
			? update_timeoff_request_api
			: create_timeoff_request_api,

		onSuccess: () => {
			refetch()
			setShowModal(false)
		},
	})
	const initialValues: TimeOffRequestModel = {
		user_id: admin ? '' : currentUser.id,
		time_off_category_id: '',
		time_off_request_dates: [],
	}
	const init = selectedData
		? {
				...selectedData,
				time_off_request_dates: selectedData.time_off_request_dates?.map(
					(d: any) => {
						return {
							...d,
							date: dayjs(d.date).format('YYYY-MM-DD'),
						}
					}
				),
		  }
		: initialValues
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: init,
		onSubmit: (values) => {
			let v = { ...values }
			if (values.id) {
				v = compareObject(values, init)
				v.id = values.id
			}
			createMutation.mutate(v)
		},
		validationSchema,
	})

	const { data: timeoff_categories } = useQuery({
		queryKey: [
			'timeoff_categories',
			`role_id=${
				users?.rows?.find((u) => u.id === formik.values.user_id?.value)
					?.role_id || currentUser.role_id
			}&filter={"status":true}`,
		],
		queryFn: timeoff_category_api,
		enabled: !!formik.values.user_id,
	})
	const handleAddDate = () => {
		const last_date =
			formik.values.time_off_request_dates[
				formik.values.time_off_request_dates.length - 1
			]
		formik.setFieldValue('time_off_request_dates', [
			...formik.values.time_off_request_dates,
			{
				date: last_date?.date
					? dayjs(last_date.date).add(1, 'day').format('YYYY-MM-DD')
					: dayjs().format('YYYY-MM-DD'),
				duration_in_hours: 8,
			},
		])
	}
	const handleRemoveDate = (index: number, date_id?: string) => {
		const updatedDates = [...formik.values.time_off_request_dates]
		updatedDates.splice(index, 1)
		formik.setFieldValue('time_off_request_dates', updatedDates)
		if (date_id) {
			delete_timeoff_request_date_api(date_id)
		}
	}
	return (
		<div>
			<form
				onSubmit={formik.handleSubmit}
				className='sm:px-3 flex flex-col gap-2'
			>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
					{userOptions ? (
						<MultiSelect
							formik={formik}
							options={userOptions}
							label='User'
							name='user_id'
							disabled={!admin || !!selectedData}
							required={true}
						/>
					) : null}
					<SelectInput
						formik={formik}
						label={'Time off category*'}
						id='time_off_category_id'
						name='time_off_category_id'
						options={timeoff_categories?.rows}
						option={{ value: 'id', label: 'name' }}
						onChange={formik.handleChange}
						value={formik.values.time_off_category_id}
						required={false}
					/>
					{formik.values.time_off_request_dates?.map(
						(date: any, index: number) => (
							<React.Fragment key={index}>
								<Input
									label='Date'
									id={`time_off_request_dates[${index}].date`}
									name={`time_off_request_dates[${index}].date`}
									type='date'
									value={date.date}
									onChange={formik.handleChange}
								/>
								<div key={index} className='flex gap-2'>
									<Input
										label='Duration'
										id={`time_off_request_dates[${index}].duration_hour`}
										name={`time_off_request_dates[${index}].duration_hour`}
										type='number'
										value={date.duration_in_hours}
										onChange={formik.handleChange}
									/>
									<button
										type='button'
										className='text-red-500 m-3 ml-5 p-1'
										onClick={() => handleRemoveDate(index, date.id)}
									>
										<FontAwesomeIcon
											className='text-red-500 m-1 p-3'
											icon={faTrash}
										/>
									</button>
								</div>
							</React.Fragment>
						)
					)}
					<button
						type='button'
						className={`text-[${primary_bg}] text-left`}
						onClick={handleAddDate}
					>
						Add dates +
					</button>
					{formik?.touched['time_off_request_dates'] &&
						!formik.values['time_off_request_dates']?.length && (
							<div className='text-red-500 text-sm'> Dates required</div>
						)}
				</div>
				<Textarea
					label='Leave Note'
					id='note'
					name='note'
					type='text'
					value={formik.values.note}
					onChange={formik.handleChange}
				/>

				{/* <div>
                <label className={`block flex text-sm  text-[#7C7F80] mb-1`}>
                  Status
                </label>
                <div className={'flex items-center '}>

                  <RadioInput
                    onChange={formik.handleChange}
                    value={formik.values.name} />
                    <p className=' text-sm  text-[#7C7F80] pb-2 pr-2'>Active</p>
                  <RadioInput
                    onChange={formik.handleChange}
                    value={formik.values.name} />
                    <p className=' text-sm  text-[#7C7F80] pb-2 pr-2'>Inactive</p>
                </div>
              </div> */}

				{/* Add other Input components as needed */}

				<div className='flex flex-row-reverse gap-2 ...'>
					<Button
						btnStyle='btn-outline'
						title='Cancel'
						type='button'
						onClick={() => setShowModal()}
					/>
					<Button
						type='submit'
						btnStyle='btn-primary'
						loading={createMutation.isPending}
						title={formik.values.id ? 'Update' : 'Create'}
					/>
				</div>
			</form>
		</div>
	)
}
