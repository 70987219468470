// api.js
import axios from 'axios'
import { Response, queryParam } from './_model'
import { ServiceModel } from './service'
import { LocationModel } from './location'
import { UserModel } from './user'

const BASE_URL = process.env.REACT_APP_BASE_URL // Replace with your actual API base URL

export const fetchSchedules = async () => {
	return await axios.get(`${BASE_URL}/schedule`)
}
export const fetchEmptySchedules = async (
	data: queryParam
): Promise<Response<UserScheduleModel[]>> => {
	return await axios.get(`${BASE_URL}/schedule/empty?${data.queryKey[1] || ''}`)
}
export const fetchAllUserSchedules = async (
	data: queryParam
): Promise<Response<UserScheduleModel[]>> => {
	return await axios.get(`${BASE_URL}/user_schedule?${data.queryKey[1] || ''}`)
}

export const createUserSchedule = async (newItem: any) => {
	return await axios.post(`${BASE_URL}/user_schedule`, newItem)
}
export const updateUserSchedule = async (updatedItem: any) => {
	return await axios.put(
		`${BASE_URL}/user_schedule/${updatedItem.id}/${
			updatedItem.old_staff_id || updatedItem.staff_id
		}`,
		updatedItem
	)
}
export const deleteUserSchedule = async (
	scheduleId: string,
	staffId: string
) => {
	return await axios.delete(
		`${BASE_URL}/user_schedule/${scheduleId}/${staffId}`
	)
}
export const fetchOneSchedule = async (
	params: queryParam
): Promise<ScheduleModel> => {
	return await axios.get(`${BASE_URL}/schedule/${params.queryKey[1]}`)
}

export const createSchedule = async (newItem: any) => {
	return await axios.post(`${BASE_URL}/schedule`, newItem)
}

export const updateSchedule = async (updatedItem: any) => {
	return await axios.put(`${BASE_URL}/schedule/${updatedItem.id}`, updatedItem)
}

export const deleteSchedule = async (itemId: any) => {
	await axios.delete(`${BASE_URL}/schedule/${itemId}`)
}
export type UserScheduleModel = {
	user_id: string
	schedule_id: string
	staff_id: string
	schedule: ScheduleModel
	user: UserModel
}
export type ScheduleModel = {
	id: string
	service_id: string
	location_id: string
	start_time: string
	end_time: string
	date: string
	duration: number
	color: string
	custom_charge: number
	note?: string
	status?: 'draft' | 'published' | 'completed'
	service: ServiceModel
	location: LocationModel
	user_schedules: UserScheduleModel[]
	duration_hours?: number
	duration_min?: number
	Repeat_duration?: number
	every?: number
}
