
export function CheckBox({ label }: any) {
	return (
		<div>
			<div className='flex'>
				<input
					type='checkbox'
					className='shrink-0 mt-0.5 border-gray-200 rounded text-[#EC7211] focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
    '
					id='hs-checkbox-group-1'
				/>
				<label htmlFor='hs-checkbox-group-1' className='text-sm text-gray-500 '>
					{label}
				</label>
			</div>
		</div>
	)
}
