import React from 'react'
import chroma from 'chroma-js'

import Select, { StylesConfig } from 'react-select'
type ColourOption = {
	readonly value: string
	readonly label: string
	readonly color: string
	readonly isFixed?: boolean
	readonly isDisabled?: boolean
}

const colourOptions: readonly ColourOption[] = [
	{ value: '#00B8D9', label: 'Ocean', color: '#00B8D9', isFixed: true },
	{ value: '#0052CC', label: 'Blue', color: '#0052CC' },
	{ value: '#5243AA', label: 'Purple', color: '#5243AA' },
	{ value: '#FF5630', label: 'Red', color: '#FF5630', isFixed: true },
	{ value: '#FF8B00', label: 'Orange', color: '#FF8B00' },
	{ value: '#FFC400', label: 'Yellow', color: '#FFC400' },
	{ value: '#36B37E', label: 'Green', color: '#36B37E' },
	{ value: '#00875A', label: 'Forest', color: '#00875A' },
	{ value: '#253858', label: 'Slate', color: '#253858' },
	{ value: '#666666', label: 'Silver', color: '#666666' },
]
const dot = (color = 'transparent') => ({
	alignItems: 'center',
	display: 'flex',

	':before': {
		backgroundColor: color,
		borderRadius: 10,
		content: '" "',
		display: 'block',
		marginRight: 8,
		height: 10,
		width: 10,
	},
})

const colourStyles: StylesConfig<ColourOption> = {
	control: (styles) => ({ ...styles, backgroundColor: 'white' }),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		const color = chroma(data.color)
		return {
			...styles,
			backgroundColor: isDisabled
				? undefined
				: isSelected
				? data.color
				: isFocused
				? color.alpha(0.1).css()
				: undefined,
			color: isDisabled
				? '#ccc'
				: isSelected
				? chroma.contrast(color, 'white') > 2
					? 'white'
					: 'black'
				: data.color,
			cursor: isDisabled ? 'not-allowed' : 'default',

			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
					? isSelected
						? data.color
						: color.alpha(0.3).css()
					: undefined,
			},
		}
	},
	input: (styles) => ({ ...styles, ...dot() }),
	placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
	singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
}

type InputProps = {
	name: string
	value: string
	onChange: (value: string) => void
}
export const ColorSelect = ({ name, value, onChange }: InputProps) => {
	return (
		<Select
			value={colourOptions.find((option) => option.value === value)}
			options={colourOptions}
			styles={colourStyles}
			name={name}
			onChange={(selectedOption: any) => onChange(selectedOption?.value)}
		/>
	)
}
