import { useFormik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import Button from '../../../component/Button'
import PasswordInput from '../../../component/PasswordInput'
import { Input2 } from '../../../component/Input'
import { useMutation } from '@tanstack/react-query'
import { update_password_api, update_email_api } from '../../../service/auth'

const emailValidationSchema = Yup.object({
	old_email: Yup.string()
		.email('Invalid email address')
		.required('Current email address is required'),
	new_email: Yup.string()
		.email('Invalid email address')
		.required('New email address is required'),
	confirm_email: Yup.string()
		.email('Invalid email address')
		.oneOf([Yup.ref('new_email')], 'Email addresses must match')
		.required('Confirm email addresses is required'),
})

const passwordValidationSchema = Yup.object({
	old_password: Yup.string().required('Current Password is required'),
	new_password: Yup.string()
		.matches(/^(?=.*[a-z])/, 'Must contain one lowercase character')
		.matches(/^(?=.*[A-Z])/, 'Must contain one uppercase character')
		.matches(/^(?=.*[0-9])/, 'Must contain one number character')
		.matches(/^(?=.*[!/@#$%^&*])/, 'Must contain one special case character')
		.min(8, 'Password must contain at least 8 characters')
		.required('New Password is required'),
	confirm_password: Yup.string()
		.oneOf([Yup.ref('new_password')], 'Passwords must match')
		.required('Confirm Password is required'),
})

function EmailUpdateForm() {
	const mutation = useMutation({
		mutationFn: update_email_api,
		onSuccess: () => {},
	})

	const formik = useFormik({
		initialValues: {
			old_email: '',
			new_email: '',
			confirm_email: '',
		},
		validationSchema: emailValidationSchema,
		onSubmit: async (values) => {
			try {
				await mutation.mutateAsync({
					old_email: values.old_email,
					new_email: values.new_email,
				})
				formik.resetForm()
			} catch (error) {}
		},
	})

	return (
		<form onSubmit={formik.handleSubmit}>
			<Input2
				{...formik.getFieldProps('old_email')}
				meta={formik.getFieldMeta('old_email')}
				label='Current Email Address'
				required={true}
			/>
			<div className='grid grid-cols-1 my-4 md:grid-cols-2 gap-4'>
				<Input2
					{...formik.getFieldProps('new_email')}
					meta={formik.getFieldMeta('new_email')}
					label='New Email Address'
					required={true}
				/>
				<Input2
					{...formik.getFieldProps('confirm_email')}
					meta={formik.getFieldMeta('confirm_email')}
					label='Confirm Email Address'
					required={true}
				/>
			</div>
			<div className='flex flex-row-reverse gap-2'>
				<Button type='button' title='Cancel' btnStyle='btn-outline' />
				<Button
					type='submit'
					title='Change Email'
					loading={mutation.isPending}
					btnStyle='btn-primary'
				/>
			</div>
		</form>
	)
}

// Password Update Form
function PasswordUpdateForm() {
	const mutation = useMutation({
		mutationFn: update_password_api,
		onSuccess: () => {},
	})
	const formik = useFormik({
		initialValues: {
			old_password: '',
			new_password: '',
			confirm_password: '',
		},
		validationSchema: passwordValidationSchema,
		onSubmit: async (values) => {
			try {
				await mutation.mutateAsync({
					old_password: values.old_password,
					new_password: values.new_password,
				})
				formik.resetForm()
			} catch (error) {}
		},
	})

	return (
		<form onSubmit={formik.handleSubmit} className='sm:p-3'>
			<PasswordInput
				required={true}
				formik={formik}
				label='Current Password'
				id='old_password'
				name='old_password'
				type='password'
				value={formik.values.old_password}
				onChange={formik.handleChange}
			/>
			<div className='grid grid-cols-1 my-4 md:grid-cols-2 gap-4'>
				<PasswordInput
					required={true}
					formik={formik}
					label='New Password'
					id='new_password'
					name='new_password'
					type='password'
					value={formik.values.new_password}
					onChange={formik.handleChange}
				/>
				<PasswordInput
					required={true}
					formik={formik}
					label='Confirm Password'
					id='confirm_password'
					name='confirm_password'
					type='password'
					value={formik.values.confirm_password}
					onChange={formik.handleChange}
				/>
			</div>
			<div className='flex flex-row-reverse gap-2'>
				<Button type='button' title='Cancel' btnStyle='btn-outline' />
				<Button
					type='submit'
					title='Change Password'
					loading={mutation.isPending}
					btnStyle='btn-primary'
				/>
			</div>
		</form>
	)
}

const Authentication = () => {
	const [activeTab, setActiveTab] = useState('email')

	return (
		<div>
			<div className='flex justify-center gap-2'>
				<button
					className={`p-2 ${
						activeTab === 'email' ? 'bg-primary text-white' : 'bg-gray-200'
					}`}
					onClick={() => setActiveTab('email')}
				>
					Update Email
				</button>
				<button
					className={`p-2 ${
						activeTab === 'password' ? 'bg-primary text-white' : 'bg-gray-200'
					}`}
					onClick={() => setActiveTab('password')}
				>
					Update Password
				</button>
			</div>
			<div className='p-4'>
				{activeTab === 'email' ? <EmailUpdateForm /> : <PasswordUpdateForm />}
			</div>
		</div>
	)
}

export default Authentication
