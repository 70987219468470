// api.js

import axios from 'axios'
import { Response, queryParam } from './_model'
import { FileModel } from './misc'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const service_api = async (
	data: queryParam
): Promise<Response<ServiceModel[]>> => {
	return await axios.get(`${BASE_URL}/service?${data.queryKey[1] || ''}`)
}

export const create_service_api = async (data: ServiceModel) => {
	return await axios.post(`${BASE_URL}/service`, data)
}

export const update_service_api = async (data: ServiceModel) => {
	return await axios.put(`${BASE_URL}/service/${data.id}`, data)
}

export const delete_service_api = async (data: string) => {
	return await axios.delete(`${BASE_URL}/service/${data}`)
}

export type ServiceModel = {
	id?: string
	name: string
	duration_hour: number
	duration_min: number
	charge: number
	status?: boolean
	note?: string
	file?: FileModel
}
export const initialService: ServiceModel = {
	name: '',
	duration_hour: 0,
	duration_min: 0,
	charge: 0,
}
