import * as Yup from 'yup'

export const LoginValidation = Yup.object({
	email: Yup.string()
		.email('Invalid email address')
		.required('Email is required'),
	password: Yup.string()
		.required('Password is required')
		.min(8, 'Password must contain at least 8 characters'),
})

export const ChangePasswordValidation = Yup.object({
	password: Yup.string()
		.required('Password is required')
		.matches(/^(?=.*[a-z])/, ' Must Contain One Lowercase Character')
		.matches(/^(?=.*[A-Z])/, '  Must Contain One Uppercase Character')
		.matches(/^(?=.*[0-9])/, '  Must Contain One Number Character')
		.matches(/^(?=.*[!@#$%^&*])/, '  Must Contain  One Special Case Character')
		.min(8, 'Password must contain at least 8 characters'),
	confirm_password: Yup.string().oneOf(
		[Yup.ref('password')],
		'Passwords must match'
	),
})
