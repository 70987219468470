import React from 'react'

export default function SearchInput({
	label,
	id,
	name,
	value,
	onChange,
	placeholder,
}: any) {
	return (
		<div>
			{label && (
				<label
					htmlFor='default-search'
					className='mb-2 text-sm font-medium text-gray-900 sr-only '
				>
					{label}
				</label>
			)}
			<div className='relative'>
				<div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
					<svg
						className='w-5 h-5 text-gray-500 '
						aria-hidden='true'
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 20 20'
					>
						<path
							stroke='currentColor'
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth='2'
							d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
						/>
					</svg>
				</div>
				<input
					type='search'
					id={id || 'search'}
					onChange={onChange}
					value={value}
					name={name || 'search'}
					className='pl-10 p-2 border border-gray-200 rounded-md w-full  border'
					placeholder={placeholder || 'Search ...'}
				/>
			</div>
		</div>
	)
}
