import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { primary_bg } from '../themes/themes'
import Select from 'react-select'
import { useQuery } from '@tanstack/react-query'
import { RoleModel, role_api } from '../service/role'
import { countries } from '../utility/countries'
import { LocationModel, location_api } from '../service/location'
import { UserModel, users_api } from '../service/user'
import {
	TimeoffCategoryModel,
	timeoff_category_api,
} from '../service/timeoffCategory'

type FieldProps = {
	name: string
	label: string
	options?: { value: any; label: string }[]
	type?: 'select' | 'date'
}

type Props = {
	fields: (FieldProps | string)[]
	values: Record<string, any>
	action: (val: string) => void
}
const FilterForm = ({ fields, values, action }: Props) => {
	const [isOpen, setIsOpen] = useState(false)
	const [inputValue, setInputValue] = useState<any>({})

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}
	const handleSubmit = (e: any) => {
		e.preventDefault()
		const d: any = {}
		Object.keys(inputValue).forEach((k: string) => {
			if (inputValue[k]) d[k] = inputValue[k]
		})
		action(JSON.stringify(d))
		setIsOpen(false)
	}
	const { data: role } = useQuery({
		queryKey: ['roles', `minimal=true`],
		queryFn: role_api,
		enabled: fields.some((f) => f === 'role_id'),
	})
	const { data: location } = useQuery({
		queryKey: ['locations', `minimal=true`],
		queryFn: location_api,
		enabled: fields.some((f) => f === 'location_id'),
	})
	const { data: user } = useQuery({
		queryKey: ['users', `minimal=true`],
		queryFn: users_api,
		enabled: fields.some((f) => f === 'user_id' || f === 'email'),
	})
	const { data: timeoff_categories } = useQuery({
		queryKey: ['timeoff_categories', `minimal=true`],
		queryFn: timeoff_category_api,
		enabled: fields.some((f) => f === 'time_off_category_id'),
	})
	const sampleFields: Record<string, FieldProps> = {
		status: {
			name: 'status',
			label: 'Status',
			options: [
				{ value: true, label: 'Active' },
				{ value: false, label: 'Inactive' },
			],
		},
		status1: {
			name: 'status',
			label: 'Status',
			options: [
				{ value: 'pending', label: 'Pending' },
				{ value: 'approved', label: 'Approved' },
				{ value: 'rejected', label: 'Rejected' },
			],
		},
		timesheet_status: {
			name: 'status',
			label: 'Status',
			options: [
				{ value: 'pending', label: 'Pending' },
				{ value: 'accepted', label: 'Accepted' },
				{ value: 'completed', label: 'Completed' },
			],
		},
		payment_status: {
			name: 'payment_status',
			label: 'Payment Status',
			options: [
				{ value: true, label: 'Paid' },
				{ value: false, label: 'Unpaid' },
			],
		},
		paid: {
			name: 'paid',
			label: 'Type',
			options: [
				{ value: true, label: 'Paid' },
				{ value: false, label: 'Unpaid' },
			],
		},
		type: {
			name: 'type',
			label: 'Type',
			options: [
				{ value: 'physical', label: 'Physical' },
				{ value: 'virtual', label: 'Virtual' },
			],
		},
		method: {
			name: 'method',
			label: 'Method',
			options: [
				{ value: 'POST', label: 'Create' },
				{ value: 'PUT', label: 'Update' },
				{ value: 'DELETE', label: 'Delete' },
			],
		},
		status_code: {
			name: 'status_code',
			label: 'Status Code',
			options: [
				{ value: '200', label: '200' },
				{ value: '400', label: '400' },
				{ value: '401', label: '401' },
			],
		},
		assignment: {
			name: 'assignment',
			label: 'Assignment',
			options: [
				{ value: 'assigned', label: 'Assigned' },
				{ value: 'unassigned', label: 'Unassigned' },
			],
		},
		country: {
			name: 'country',
			label: 'Country',
			options: countries.map((country) => {
				return {
					value: country,
					label: country,
				}
			}),
		},
		currency: {
			name: 'currency',
			label: 'Currency',
			options: Intl.supportedValuesOf('currency').map((currency: any) => {
				return {
					value: currency,
					label: currency,
				}
			}),
		},
		role_id: {
			name: 'role_id',
			label: 'Role',
			options: role?.rows.map((r: RoleModel) => ({
				value: r.id,
				label: r.name,
			})),
		},
		location_id: {
			name: 'location_id',
			label: 'Location',
			options: location?.rows.map((r: LocationModel) => ({
				value: r.id,
				label: r.name,
			})),
		},
		user_id: {
			name: 'user_id',
			label: 'Staff',
			options: user?.rows.map((r: UserModel) => ({
				value: r.id,
				label: r.firstname + ' ' + r.lastname,
			})),
		},
		email: {
			name: 'email',
			label: 'Staff',
			options: user?.rows.map((r: UserModel) => ({
				value: r.email,
				label: r.firstname + ' ' + r.lastname,
			})),
		},
		time_off_category_id: {
			name: 'time_off_category_id',
			label: 'Time off category',
			options: timeoff_categories?.rows.map((r: TimeoffCategoryModel) => ({
				value: r.id,
				label: r.name,
			})),
		},
	}
	return (
		<div className='relative inline-block text-left'>
			<button
				onClick={toggleDropdown}
				type='button'
				className={`
        gap-x-2  items-center
         inline-flex justify-center w-full  rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[${primary_bg}]
          font-medium  hover:bg-[${primary_bg}] focus:outline-none focus:ring-2 
         btn-primary
         focus:ring-[${primary_bg}] focus:ring-offset-2 focus:ring-offset-gray-100`}
				id='options-menu'
				aria-haspopup='true'
				aria-expanded='true'
			>
				Filter
				{!isOpen ? (
					<FontAwesomeIcon icon={faChevronDown} />
				) : (
					<FontAwesomeIcon icon={faChevronUp} />
				)}
			</button>

			{isOpen && (
				<div className='origin-top-left absolute left-0 mt-2 w-72 z-40  md:w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
					<div className='py-2 px-4'>
						<form onSubmit={handleSubmit}>
							{fields.map((f: FieldProps | string, i: number) => {
								let field: FieldProps
								if (typeof f === 'string') field = sampleFields[f]
								else field = f
								return field.type === 'date' ? (
									<input
										className='w-full my-2'
										type='text'
										onFocus={(e) => (e.target.type = 'date')}
										onBlur={(e) => (e.target.type = 'text')}
										placeholder={field.label}
										onChange={(e) =>
											setInputValue((prev: any) => {
												return { ...prev, [field.name]: e.target.value }
											})
										}
										value={values[field.name]}
										key={i}
									/>
								) : (
									<Select
										defaultValue={field.options?.filter((v) =>
											values[field.name]?.includes(v.value)
										)}
										isMulti
										name={field.name}
										options={field.options}
										className='basic-multi-select my-3'
										classNamePrefix='select'
										placeholder={field.label}
										onChange={(selectedOption: any) => {
											const vv: any = {}
											vv[field.name] = selectedOption.map((x: any) => x.value)
											if (selectedOption.length === 0)
												vv[field.name] = undefined
											setInputValue((prev: any) => {
												return { ...prev, ...vv }
											})
										}}
										key={i}
									/>
								)
							})}
							<button
								type='submit'
								className={`mt-2 bg-[${primary_bg}] hover:bg-[${primary_bg}] w-full text-white  px-4 py-2 rounded-md`}
							>
								Filter
							</button>
						</form>
					</div>
				</div>
			)}
		</div>
	)
}

export default FilterForm
export const initialParams = {
	page_number: 1,
	page_size: 10,
	filter: '',
}
