import React, { FC, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import Button from '../../component/Button'
import Table from '../../component/table'
import {
	createUserSchedule,
	deleteSchedule,
	deleteUserSchedule,
	fetchOneSchedule,
	updateUserSchedule,
} from '../../service/schedule'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useAuth } from '../../context/AuthContext'
import { OtherButton } from '../../component/Buttons'
dayjs.extend(utc)
function ItemList({ title, value }: { title: string; value: any }) {
	return (
		<li className='  border-b p-2  border-gray-200 py-3 px-4 text-sm font-medium text-gray-800 flex justify-between'>
			<p className='text-[#7C7F80] font-[400]'> {title}</p>
			<p className='font-[400] text-[14px] capitalize'>{value}</p>
		</li>
	)
}
export const GetDuration: FC<{ row: any }> = ({ row }) => {
	const duration = useMemo(() => {
		if (row.clock_in)
			return dayjs(row.clock_out || new Date()).diff(row.clock_in, 'second')
		else return 0
	}, [row.clock_in, row.clock_out])
	let hour, min, sec

	const [timer, setTimer] = useState(duration)
	if (row.clock_in) {
		hour = Math.floor(timer / 3600)
		min = Math.floor((timer - hour * 3600) / 60)
		sec = timer % 60
		let interval: any
		const calc: any = () => {
			setTimer(timer + 1)
			clearInterval(interval)
		}
		if (!row.clock_out) interval = setInterval(calc, 1000)
	}
	return (
		<>
			{hour ? `${hour} hr ` : ''}
			{min ? `${min} min ` : ''}
			{sec ? `${sec} sec ` : ''}
		</>
	)
}
export default function ScheduleDetails({ id, setShow }: any) {
	const { currentUser } = useAuth()
	const { data } = useQuery({
		queryKey: ['fetch_one_schedule', `${id}`],
		queryFn: fetchOneSchedule,
		enabled: !!id,
	})
	const queryClient = useQueryClient()

	const dataScheduleUser = useMemo(
		() =>
			data?.user_schedules?.map((res: any) => {
				if (!res.user) return {}
				res.user.clock_in = res.clock_in
				res.user.clock_out = res.clock_out
				res.user.schedule_status = res.status
				return res.user
			}) || [],
		[data]
	)

	const updateUSchedule = async (
		staffId: string,
		updatedItem: Record<string, string | Date>
	) => {
		await updateUserSchedule({
			...updatedItem,
			id,
			staff_id: staffId,
		})
		queryClient.setQueryData(['fetch_one_schedule', `${id}`], (old: any) => {
			return {
				...old,
				user_schedules: old.user_schedules.map((res: any) => {
					if (res.user.id === staffId) {
						return {
							...res,
							...updatedItem,
						}
					}
					return res
				}),
			}
		})
	}
	const deleteUSchedule = async (staffId: string) => {
		await deleteUserSchedule(id, staffId)
		queryClient.setQueryData(['fetch_one_schedule', `${id}`], (old: any) => {
			return {
				...old,
				user_schedules: old.user_schedules.filter(
					(res: any) => res.staff_id !== staffId
				),
			}
		})
	}
	const deleteMutation = useMutation({
		mutationFn: deleteSchedule,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['user_schedules'] })
			queryClient.invalidateQueries({ queryKey: ['empty_user_schedules'] })
			setShow(false)
		},
	})
	const columns = [
		{
			key: 'firstname',
			title: 'team members',
			render: (value: any, row: any) => value + ' ' + row.lastname,
		},
		{ key: 'email', title: 'Email' },
		{
			key: 'clock_in',
			title: 'Timer',
			render: (value: any, row: any) => <GetDuration row={row} />,
		},
		{
			key: 'id',
			title: 'Action',
			render: (value: any, row: any) => (
				<div className={`flex gap-1`}>
					{row.id === currentUser?.id ? (
						<>
							{row.schedule_status === 'pending' ? (
								<Button
									title='Accept'
									btnStyle='btn-primary'
									onClick={() =>
										updateUSchedule(row.id, { status: 'accepted' })
									}
								/>
							) : !row.clock_in ? (
								<Button
									title='Clock in'
									btnStyle='btn-primary'
									onClick={() =>
										updateUSchedule(row.id, { clock_in: new Date() })
									}
								/>
							) : !row.clock_out ? (
								<Button
									title='Clock Out'
									btnStyle='btn-primary'
									onClick={() =>
										updateUSchedule(row.id, { clock_out: new Date() })
									}
								/>
							) : null}
						</>
					) : null}

					{process.env.REACT_APP_ADMIN?.includes(currentUser?.role?.name) &&
						row.schedule_status === 'pending' && (
							<Button
								title='Delete'
								btnStyle='btn-outline'
								onClick={() => deleteUSchedule(row.id)}
							/>
						)}
				</div>
			),
		},
	]
	const acceptMutation = useMutation({
		mutationFn: createUserSchedule,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['fetch_one_schedule'] })
			queryClient.invalidateQueries({ queryKey: ['user_schedules'] })
			queryClient.invalidateQueries({ queryKey: ['empty_user_schedules'] })
		},
	})
	return (
		<div>
			<ul className=' flex flex-col   pb-2'>
				<ItemList title={'Service'} value={data?.service?.name} />
				<ItemList title={'Location'} value={data?.location.name} />
				<ItemList
					title={'Start Date'}
					value={dayjs(data?.date).format('D/M/YYYY')}
				/>
				<ItemList
					title={'Start time'}
					value={dayjs.utc(data?.start_time).format('hh:mm a')}
				/>
				<ItemList
					title={'End time'}
					value={dayjs.utc(data?.end_time).format('hh:mm a')}
				/>
				<ItemList
					title={'Duration'}
					value={
						data?.duration
							? `${Math.floor(data?.duration / 60)}h ${data?.duration % 60}m`
							: ''
					}
				/>
				<ItemList title={'Status'} value={data?.status} />
				<ItemList
					title={'Color'}
					value={
						<div
							style={{
								backgroundColor: data?.color,
								width: '20px',
								height: '20px',
								borderRadius: '50%',
							}}
						></div>
					}
				/>
			</ul>

			<Table
				showHeaderIcon={true}
				columns={columns}
				data={dataScheduleUser}
				// onSelectedRowsChange={handleSelectedRowsChange}
				hasCheckBox={false}
			/>

			<h2 className='text-[20px] font-[600] m-4'>Optional</h2>
			<ul className=' flex flex-col divide-y divide-gray-200 dark:divide-gray-700 pb-2'>
				<li className='  border-b p-2  border-gray-200 py-3 px-4 text-sm font-medium text-gray-800 flex justify-between'>
					<p className='text-[#7C7F80]'>Time zone</p>
					<p>GMT+1</p>
				</li>
				<li className='  border-b p-2  border-gray-200 py-3 px-4 text-sm font-medium text-gray-800 flex justify-between'>
					<p className='text-[#7C7F80] font-[400]'> Custom charges </p>
					<p>
						{data?.custom_charge?.toLocaleString('en-US', {
							style: 'currency',
							currency: 'USD',
						})}
					</p>
				</li>
			</ul>
			<div className='flex gap-2 flex-row-reverse p-3'>
				<OtherButton
					onClick={() => deleteMutation.mutate(id)}
					id='schedules'
					title='Delete'
					action='delete'
					className='ml-2'
				/>
				<OtherButton
					onClick={() => setShow('edit')}
					id='schedules'
					title='Edit'
					action='update'
				/>
				{data && !dataScheduleUser.length && (
					<Button
						onClick={() => acceptMutation.mutate({ schedule_id: id })}
						title='Accept'
						btnStyle='btn-primary'
						loading={acceptMutation.isPending}
					/>
				)}
			</div>
		</div>
	)
}
