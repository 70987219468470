import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

export default function Optional({ children }: { children: any }) {
	const [show, setShow] = useState(false)

	return (
		<div>
			<button
				className='flex text-[16px] font-medium'
				type='button'
				onClick={() => setShow(!show)}
			>
				Optional
				<div className='mb-1 ml-2'>
					{show ? (
						<FontAwesomeIcon icon={faCaretDown} />
					) : (
						<FontAwesomeIcon icon={faCaretUp} />
					)}
				</div>
			</button>
			{show && children}
		</div>
	)
}
