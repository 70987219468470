import { useFormik } from 'formik'
import React from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Input2 } from '../../../component/Input'
import { MultiSelect } from '../../../component/SelectInput'
import Textarea from '../../../component/textarea'
import { SubmitButton } from '../../../component/Buttons'
import { update_invoice_api } from '../../../service/Invoice'

export default function PaymentModal({
	id,
	setShowModal,
}: {
	id: string
	setShowModal: (a: boolean) => void
}) {
	const queryClient = useQueryClient()
	const updateInvoice = useMutation({
		mutationFn: update_invoice_api,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['invoice', id] })
			setShowModal(false)
		},
	})
	const formik = useFormik({
		initialValues: {
			payment_method: '',
			payment_method1: '',
			payment_note: '',
		},
		onSubmit: (values) => {
			const v: any = { ...values, id, payment_status: true }
			v.payment_method =
				values.payment_method === 'other'
					? values.payment_method1
					: values.payment_method
			delete v.payment_method1
			updateInvoice.mutate(v)
		},
	})
	return (
		<div>
			<form
				onSubmit={formik.handleSubmit}
				className='sm:p-3 flex flex-col gap-2'
			>
				<MultiSelect
					meta={formik.getFieldMeta('payment_method')}
					onChange={(v: any) =>
						formik.setFieldValue('payment_method', v?.value)
					}
					label='Payment Method'
					name='payment_method'
					options={[
						'Cash',
						'Cheque',
						'Direct Transfer',
						'Credit Card',
						'Paypal',
						'Other',
					].map((data) => {
						return {
							value: data,
							label: data,
						}
					})}
				/>
				{formik.values.payment_method === 'Other' && (
					<Input2
						{...formik.getFieldProps('payment_method1')}
						meta={formik.getFieldMeta('payment_method1')}
						placeholder='Enter Payment Method'
					/>
				)}
				<Textarea
					label='Payment Note'
					name='payment_note'
					onChange={formik.handleChange}
					value={formik.values.payment_note}
				/>
				<br />
				{/* Add other Input components as needed */}

				<div className='flex'>
					<SubmitButton />
				</div>
			</form>
		</div>
	)
}
