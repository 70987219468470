import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Button from '../../../component/Button'
import Table from '../../../component/table'
import { SettingModel } from '../../../service/settings'
import { PayrollModel } from '../../../service/payroll'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { fileSrc } from '../../../component/FileLoader'
import { print } from '../../../component/print'
dayjs.extend(utc)

export default function PayrollDetailsOne({
	data,
	settings,
}: {
	data?: PayrollModel
	settings?: SettingModel
}) {
	const columns = [
		{
			key: 'rate',
			title: 'Rate/HR',
			render: (value: any, row: any) =>
				value.toLocaleString('en-US', {
					style: 'currency',
					currency: row.currency || 'USD',
				}),
		},
		{ key: 'hours_worked', title: 'Period Hours Worked' },
		{
			key: 'gross_pay',
			title: 'Period Pay',
			render: (value: any, row: any) =>
				value?.toLocaleString('en-US', {
					style: 'currency',
					currency: row.currency || 'USD',
				}),
		},
		{ key: 'ytd_hours_worked', title: 'YTD Hours Worked' },
		{
			key: 'ytd_pay',
			title: 'YTD Pay',
			render: (value: any, row: any) =>
				value?.toLocaleString('en-US', {
					style: 'currency',
					currency: row.currency || 'USD',
				}),
		},
		{
			key: 'net_pay',
			title: 'Net Pay',
			render: (value: any, row: any) =>
				value?.toLocaleString('en-US', {
					style: 'currency',
					currency: row.currency || 'USD',
				}),
		},
	]
	return (
		<div>
			<div className='rounded-lg bg-white p-8'>
				<div className='flex justify-between'>
					<div className='flex-none text-xl font-semibold flex'>
						{settings?.business_logo && (
							<img
								className=' h-[30px] w-[30px] object-contain'
								src={fileSrc({
									id: settings?.business_logo,
									provider: 'cloudinary',
									format: '',
								})}
								alt=''
							/>
						)}
						<p className='px-2 text-[14px]'>{settings?.business_name} </p>
					</div>
					<p className='text-[#1E2325]'>PAYSLIP #{data?.id}</p>
				</div>
				<div className='font-[500] text-[14px] my-5'>
					<p
						dangerouslySetInnerHTML={{
							__html: settings?.business_address || '',
						}}
					></p>
				</div>
				<div className='font-[400] text-[14px] text-[#7C7F80]'>
					<p className='my-2'>
						{data?.user?.firstname} {data?.user?.lastname}
					</p>
					<p>{data?.user?.role?.name}</p>
					<p>User ID: {data?.user_id}</p>
				</div>

				<p className='font-[700] text-center m-6 text-[20px]'>
					{dayjs.utc(data?.start_date).format('Do MMMM YYYY')} -{' '}
					{dayjs.utc(data?.end_date).format('Do MMMM YYYY')}
				</p>

				<Table
					showHeaderIcon={true}
					columns={columns}
					tableStyle={'rounded-[10px] border border-gray-200 '}
					data={data ? [data] : []}
					hasCheckBox={false}
				/>

				<p className='font-[500] text-[14px] mt-5 ml-1'>DEDUCTIONS</p>

				<div className=' grid grid-cols-2 gap-4'>
					<div>
						<div className='grid grid-cols-2 gap-4 text-[14px] font-[400] rounded-lg border border-2'>
							<div className='bg-[#FFF4EC]  p-3 flex flex-col gap-3 '>
								{[
									'FEDERAL TAX',
									'PROVINCIAL TAX',
									'CPP',
									'EI',
									'OTHER',
									'TOTAL DEDUCTIONS',
								].map((item) => (
									<div>{item}</div>
								))}
							</div>
							<div className=' p-3 flex flex-col gap-3 '>
								{[
									'REGULAR',
									'$28/HR',
									'3 hours ',
									'$4000',
									'$28000',
									'$28000',
								].map((item) => (
									<div>{item}</div>
								))}
							</div>
						</div>

						{/* <div>
                        <p className='font-[400] text-[14px] text-[#7C7F80]'>REGULAR PAY (PLUS GST/HST)</p>
                        <p className='text-[14px] font-[500] text-[#1E2325]'>$1,742.4</p>

                        </div> */}
					</div>
				</div>

				<div className='flex   justify-between  mt-6'>
					<div>
						<p className='text-[14px] font-[400] text-[#7C7F80]'>
							REGULAR PAY (Excluding deductions)
						</p>
						<p className='text-[16px] mt-1 font-[500]'>
							{data?.net_pay?.toLocaleString('en-US', {
								style: 'currency',
								currency: data.currency || 'USD',
							})}
						</p>
					</div>

					<div className='no-print flex gap-2'>
						<Button
							title='Download'
							className='h-fit'
							btnStyle='btn-primary'
							rightIcon={<FontAwesomeIcon icon={faDownload} />}
							onClick={() => print('landscape')}
						/>
						<Button title='Cancel' className='h-fit' btnStyle='btn-outline' />
					</div>
				</div>
			</div>
		</div>
	)
}
