import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'

const DoughnutChartComponent = () => {
	const chartRef = useRef(null)

	useEffect(() => {
		const data = [21000000, 10000000, 11000000]
		if (chartRef && chartRef.current) {
			const myChart = new Chart(chartRef.current, {
				type: 'doughnut',
				data: {
					labels: [
						// 'Invoice Amount',
						// 'Paid Invoice',
						// 'Overdue Invoices',
					],
					datasets: [
						{
							// borderAlign: 'inner',
							label: '',
							data: data,
							backgroundColor: ['#99FFA3', '#FF9364', '#B09FFF'],
							hoverOffset: 4,
						},
					],
				},
			})

			return () => {
				myChart.destroy() // Cleanup chart on component unmount
			}
		}
	}, [])

	return <canvas ref={chartRef} />
}

export default DoughnutChartComponent
