import React from 'react'
import Breadcrumb from '../../../component/Breadcrumb'
import PayrollPreviewOne from './PayrollDetailsOne'
import PayrollPreviewThree from './PayrollDetailsThree'
import PayrollPreviewTwo from './PayrollDetailsTwo'
import { useQuery } from '@tanstack/react-query'
import { settings_api } from '../../../service/settings'
import { useParams } from 'react-router-dom'
import { one_payroll_api } from '../../../service/payroll'

export default function PayrollPreview() {
	const { id } = useParams()
	const { data: settings } = useQuery({
		queryKey: ['settings'],
		queryFn: settings_api,
	})
	const { data } = useQuery({
		queryKey: ['payroll', id!],
		queryFn: one_payroll_api,
		enabled: !!id,
	})
	const breadcrumbItems = [
		{ label: 'Finance', url: '/finances/payrolls' },
		{ label: 'Payroll', url: '/finances/payrolls' },
		{ label: 'Preview', url: '' },
	]

	return (
		<div>
			<div className='ml-3 '>
				<Breadcrumb items={breadcrumbItems} />
			</div>
			{settings?.payroll_template === '3' ? (
				<PayrollPreviewThree data={data} settings={settings} />
			) : settings?.payroll_template === '2' ? (
				<PayrollPreviewTwo data={data} settings={settings} />
			) : (
				<PayrollPreviewOne data={data} settings={settings} />
			)}
		</div>
	)
}
