// Button.jsx
import React from 'react'
import { Loading2 } from './loading'

const Button = ({
	type,
	onClick,
	disabled,
	title,
	className,
	btnStyle,
	rightIcon,
	loading,
}: {
	loading?: boolean
	rightIcon?: any
	type?: any
	disabled?: boolean
	onClick?: any
	title?: any
	className?: any
	btnStyle?: 'btn-outline' | 'btn-primary' | 'btn-blue'
}) => {
	return (
		<button
			disabled={disabled}
			type={type || 'button'}
			onClick={onClick}
			className={`py-2 px-4 flex align-items-center align-center justify-center rounded-md ${className} hover:opacity: 12
       ${btnStyle === 'btn-outline' && `border border-[#7C7F80] text-[#7C7F80]`}
       ${disabled && 'opacity-50'}
       ${btnStyle === 'btn-blue' && `text-white bg-[#2196F3] `}
       ${
					btnStyle === 'btn-primary' &&
					`'btn-primary text-white bg- bg-[#EC7211]  `
				}

       `}
		>
			{title} {rightIcon && <div className='ml-2'>{rightIcon}</div>}
			{loading && (
				<span className='ml-2'>
					<Loading2 />
				</span>
			)}
		</button>
	)
}

export default Button
