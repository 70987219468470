import { useFormik } from 'formik'
import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import Input from '../../../component/Input'
import { primary_bg } from '../../../themes/themes'
import { users_api } from '../../../service/user'
import Select from 'react-select'
import { create_payroll_api } from '../../../service/payroll'

export default function PayrollForm() {
	const { data: usersData } = useQuery({
		queryKey: ['users', 'filter={"status":true}'],
		queryFn: users_api,
	})
	const navigate = useNavigate()

	const create = useMutation({
		mutationFn: create_payroll_api,
		onSuccess: (a) => {
			navigate(`/finances/payrolls/preview/${a.id}`)
		},
	})

	const formik = useFormik({
		initialValues: {
			user_id: '',
			start_date: '',
			end_date: '',
		},
		onSubmit: (values) => {
			create.mutate(values)
		},
	})
	return (
		<div>
			<form
				onSubmit={formik.handleSubmit}
				className='sm:p-3 flex flex-col gap-2'
			>
				<label
					htmlFor='user_schedules'
					className='block flex text-sm mb-1 text-[#7C7F80]'
				>
					Payroll
				</label>
				<Select
					// defaultValue={formik.values.user_schedules}
					isMulti
					name='Staffs'
					options={usersData?.rows.map((data: any) => ({
						value: data.id,
						label: data.firstname + ' ' + data.lastname,
					}))}
					className='basic-multi-select'
					classNamePrefix='select'
					onChange={(selectedOption: any) => {
						formik.setFieldValue(
							'user_id',
							selectedOption.map((option: any) => option.value)
						)
					}}
				/>
				<div className='grid grid-cols-1 md:grid-cols-2 my-3 gap-4 my-3 '>
					<Input
						required={true}
						label='Interval from'
						formik={formik}
						type='date'
						id='start_date'
						name='start_date'
						onChange={formik.handleChange}
						value={formik.values.start_date}
					/>
					<Input
						required={true}
						formik={formik}
						label='Interval to'
						type='date'
						id='end_date'
						name='end_date'
						onChange={formik.handleChange}
						value={formik.values.end_date}
					/>
				</div>
				{/* <Input
					label='Interval'
					id='Interval'
					name='Interval'
					type='date'
					value={formik.values.Interval}
					onChange={formik.handleChange}
				/> */}
				<br />
				{/* Add other Input components as needed */}

				<div className='flex'>
					<button
						type='submit'
						className={`bg-[${primary_bg}] text-white p-2 rounded-md  flex-1`}
					>
						Generate
					</button>
				</div>
			</form>
		</div>
	)
}
