import axios from 'axios'

export const get_signed_url = async (
	folder?: string
): Promise<{
	signature: string
	timestamp: string
	cloudname: string
	apikey: string
	folder: string
}> => {
	return await axios.get(
		`${process.env.REACT_APP_BASE_URL}/upload_sign_url${
			folder ? `?folder=${folder}` : ''
		}`
	)
}

export const upload_file = async (
	data: FileProps,
	cloudname: string
): Promise<any> => {
	const new_axios = axios.create({
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
	return await new_axios.post(
		'https://api.cloudinary.com/v1_1/' + cloudname + '/auto/upload',
		data
	)
}
type FileProps = {
	file: File
	api_key: string
	timestamp: string
	signature: string
	folder: string
}

export type FileModel = {
	id: string
	format?: string
	provider?: string
}
