import React from 'react'
import { ServiceModel } from '../../service/service'
import { useQuery } from '@tanstack/react-query'
import { settings_api } from '../../service/settings'
import { OtherButton } from '../../component/Buttons'
import { fileSrc } from '../../component/FileLoader'

function Item({ title, subtitle }: { title: string; subtitle: string }) {
	return (
		<div className='flex justify-between p-3 font-[400] text-[13px] text-[#1E2325]'>
			<p> {title} </p>
			<p> {subtitle} </p>
		</div>
	)
}

export default function ViewService({
	selectedData,
	setShowModal,
}: {
	selectedData: ServiceModel
	setShowModal: any
}) {
	const { data: settings } = useQuery({
		queryKey: ['settings'],
		queryFn: settings_api,
	})
	return (
		<div>
			<div className='"grid grid-cols-1 divide-y'>
				{selectedData.file && (
					<img
						src={fileSrc(selectedData.file, 250, 250)}
						alt={selectedData.name}
						style={{ margin: 'auto' }}
					/>
				)}
				<Item title='Name' subtitle={selectedData.name} />
				<Item
					title='Charge'
					subtitle={selectedData.charge.toLocaleString('en-US', {
						style: 'currency',
						currency: settings?.currency || 'USD',
					})}
				/>
				<Item
					title='Status'
					subtitle={selectedData.status ? 'Active' : 'Inactive'}
				/>
				<Item title='Note' subtitle={selectedData.note || ''} />
			</div>
			<div className='flex flex-row-reverse m-3'>
				<OtherButton
					onClick={() => setShowModal('edit')}
					id='services'
					title='Edit'
					action='update'
				/>
			</div>
		</div>
	)
}
