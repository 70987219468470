import React from 'react'
import Button from '../../../component/Button'
import { TimeOffRequestModel } from '../../../service/timeOffRequest'
import { useAuth } from '../../../context/AuthContext'

function Item({ title, subtitle }: { title: string; subtitle: any }) {
	return (
		<div>
			<div className='flex justify-between p-3 font-[400] text-[13px] text-[#1E2325]'>
				<p> {title}</p>
				<p> {subtitle}</p>
			</div>

			{/* <hr /> */}
		</div>
	)
}

export default function ViewTimeOffRequest({
	selectedData,
	setShowModal,
}: {
	selectedData: TimeOffRequestModel
	setShowModal: any
}) {
	const { currentUser } = useAuth()
	const admin = process.env.REACT_APP_ADMIN?.includes(currentUser?.role?.name)
	return (
		<>
			<div className='"grid grid-cols-1 divide-y'>
				<Item
					title={'Leave Type'}
					subtitle={selectedData.time_off_category?.name}
				/>
				<Item title={'Status'} subtitle={selectedData.status} />
				<Item
					title={'Duration'}
					subtitle={selectedData.time_off_request_dates.length + ' days'}
				/>
				<hr />
			</div>
			<div className='flex flex-row-reverse m-3 gap-2'>
				<Button
					btnStyle='btn-primary'
					onClick={() => setShowModal('edit')}
					title='Edit'
				/>
				{admin && (
					<Button
						btnStyle='btn-primary'
						onClick={() => setShowModal('decision')}
						title='Decide'
					/>
				)}
			</div>
		</>
	)
}
