import { useFormik } from 'formik'
import React, { useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import Button from '../../../component/Button'
import Input from '../../../component/Input'
import RadioInput from '../../../component/RadioInput'
import Textarea from '../../../component/textarea'
import { role_api } from '../../../service/role'
import Select from 'react-select'
import {
	TimeoffCategoryModel,
	create_timeoff_category_api,
	initialValues,
	update_timeoff_category_api,
} from '../../../service/timeoffCategory'
type Props = {
	setShowModal: (val?: 'create' | 'edit' | 'view') => void
	refetch: () => void
	selectedData?: TimeoffCategoryModel
}
export default function CreateTimeOffCategory({
	setShowModal,
	refetch,
	selectedData,
}: Props) {
	const { data: roles } = useQuery({ queryKey: ['roles'], queryFn: role_api })
	const mutation = useMutation({
		mutationFn: selectedData?.id
			? update_timeoff_category_api
			: create_timeoff_category_api,

		onSuccess: () => {
			refetch()
			setShowModal()
		},
	})
	const data = useMemo(() => {
		if (selectedData) {
			return {
				...selectedData,
				roles: selectedData.roles?.map((role: any) => {
					const res = roles?.rows.find((r) => r.id === role)
					if (res) return { value: res.id, label: res.name }
					else return { value: role, label: '' }
				}),
			}
		}
		return initialValues
	}, [roles, selectedData])
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: data,
		onSubmit: (values) => {
			const vv = { ...values }
			vv.roles = values.roles.map((data: any) => data.value)
			mutation.mutate(vv)
		},
	})
	return (
		<div>
			<form onSubmit={formik.handleSubmit} className='sm:p-3'>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
					<Input
						label='Timeoff Category Name*'
						id='name'
						name='name'
						type='text'
						value={formik.values.name}
						onChange={formik.handleChange}
					/>

					<div>
						<label
							htmlFor='user_schedules'
							className='block flex text-sm mb-1 text-[#7C7F80]'
						>
							Roles
						</label>
						<Select
							value={formik.values.roles}
							isMulti
							name='roles'
							options={roles?.rows.map((data: any) => ({
								value: data.id,
								label: data.name,
							}))}
							className='basic-multi-select'
							classNamePrefix='select'
							onChange={(selectedOption: any) => {
								formik.setFieldValue('roles', selectedOption)
							}}
						/>
					</div>

					<div className=' gap-3'>
						{/* <p className='text-[#7C7F80] font-[400] text-[14px]'>Status</p> */}
						<div className='flex gap-3 pt-2'>
							<RadioInput
								id='Paid'
								name='paid'
								value={true}
								label='Paid'
								field={formik.getFieldProps('paid')}
								onChange={() => formik.setFieldValue('paid', true)}
							/>
							<RadioInput
								id='Unpaid'
								name='paid'
								value={false}
								label='Unpaid'
								field={formik.getFieldProps('paid')}
								onChange={() => formik.setFieldValue('paid', false)}
							/>
						</div>
					</div>

					{formik.values.id && (
						<div className=' gap-3'>
							<p className='text-[#7C7F80] font-[400] text-[14px]'>Status</p>
							<div className='flex gap-3 pt-2'>
								<RadioInput
									id='Status'
									name='status'
									value={true}
									label='Active'
									field={formik.getFieldProps('status')}
									onChange={() => formik.setFieldValue('status', true)}
								/>
								<RadioInput
									id='StatusNo'
									name='status'
									value={false}
									label='Inactive'
									field={formik.getFieldProps('status')}
									onChange={() => formik.setFieldValue('status', false)}
								/>
							</div>
						</div>
					)}
				</div>
				<div className='mt-2'>
					<Textarea
						label='Note'
						id='note'
						name='note'
						type='text'
						value={formik.values.note}
						onChange={formik.handleChange}
					/>
				</div>

				<br />
				{/* Add other Input components as needed */}

				<div className='flex flex-row-reverse gap-2 ...'>
					<Button
						btnStyle='btn-outline'
						title='Cancel'
						type='button'
						onClick={() => setShowModal()}
					/>
					<Button
						type='submit'
						btnStyle='btn-primary'
						loading={mutation.isPending}
						title={formik.values.id ? 'Update' : 'Create'}
					/>
				</div>
			</form>
		</div>
	)
}
