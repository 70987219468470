import React from 'react'
import { useFormik } from 'formik'
import { primary_bg } from '../../themes/themes'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { otp_api } from '../../service/auth'

const OTPPage = () => {
	const navigate = useNavigate()

	let { email } = useParams()

	const mutation = useMutation({
		mutationFn: otp_api,
		onSuccess: (res) => {
			navigate('/dashboard')
		},
		onError: (error: any) => {
			toast.error(error.response.data.message)
		},
	})

	const numDigits = 5

	const initialValues = {
		otp: ['', '', '', ''],
	}

	const validate = (values: { otp: any[] }) => {
		const errors: any = {}
		values.otp.forEach((digit: string, index: any) => {
			if (!digit) {
				errors[`otp[${index}]`] = 'Required'
			} else if (!/^\d$/.test(digit)) {
				errors[`otp[${index}]`] = 'Invalid digit. Must be a number.'
			}
		})
		return errors
	}

	const formik: any = useFormik({
		initialValues,
		validate,
		onSubmit: (values) => {
			// Add your OTP verification logic here
			navigate('/dashboard')
			const otpValue = values.otp.join('')
			mutation.mutate({ email, reset_token: otpValue })
		},
	})

	const handleDigitChange = (index: any, e: any) => {
		const { value } = e.target
		formik.setFieldValue(`otp[${index}]`, value)

		// Move focus to the next or previous input
		const nextIndex = value ? index + 1 : index - 1

		// Check if it's within the valid range of inputs
		if (nextIndex >= 0 && nextIndex < numDigits) {
			const nextInput = document.getElementById(`otp-input-${nextIndex}`)
			if (nextInput) {
				nextInput.focus()
			}
		}
	}

	return (
		<div className='relative z-10 bg-white p-8 rounded-lg sm:w-96'>
			<h2 className='text-2xl  mb-1 text-center'>Verify Your Email</h2>
			<p className='text-gray-400 text-sm text-center'>
				Please enter the 4 digit code sent to
			</p>
			<p className='text-[#EC7211] text-sm text-center'>{email}</p>

			<form onSubmit={formik.handleSubmit}>
				{formik.values.otp.map((digit: any, index: any) => (
					<input
						key={index}
						type='text'
						id={`otp-input-${index}`}
						name={`otp[${index}]`}
						onChange={(e) => handleDigitChange(index, e)}
						value={digit}
						max='1'
						className={`border border-gray-300 bg-[#F5F5F5] rounded flex-1 p-2 m-2 mx-4 sm:mx-5 my-4 w-10 text-center ${
							formik.touched[`otp[${index}]`] && formik.errors[`otp[${index}]`]
								? 'border-red-500'
								: ''
						}`}
					/>
				))}
				{formik.touched.otp &&
					formik.errors.otp &&
					formik.errors.otp.map((error: any, index: any) => (
						<div key={index} className='text-red-500'>
							{error}
						</div>
					))}
				<br />
				<button
					type='submit'
					className={`w-full bg-[${primary_bg}] text-white p-2 rounded-md hover:opacity: 12,`}
				>
					Confirm
				</button>

				<div className='text-center m-3 text-[#1E2325] text-sm tetx-'>
					<p>
						Didn't receive a code ?{' '}
						<NavLink
							className={`border-bottom border-gray-300 text-[${primary_bg}]`}
							to={'/auth/forget-password'}
						>
							Resend
						</NavLink>{' '}
					</p>
				</div>
			</form>
		</div>
	)
}

export default OTPPage
