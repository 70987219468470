import axios from 'axios'
import { Response, queryParam } from './_model'
import { LocationModel } from './location'

// api.js
const BASE_URL = process.env.REACT_APP_BASE_URL

export const invoice_api = async (
	data: queryParam
): Promise<Response<InvoiceModel[]>> => {
	return await axios.get(`${BASE_URL}/invoice?${data.queryKey[1] || ''}`)
}
export const one_invoice_api = async (
	params: queryParam
): Promise<InvoiceModel> => {
	return await axios.get(`${BASE_URL}/invoice/${params.queryKey[1]}`)
}

export const create_invoice_api = async (
	data: InvoiceModel
): Promise<InvoiceModel> => {
	return await axios.post(`${BASE_URL}/invoice`, data)
}

export const update_invoice_api = async (
	data: Partial<InvoiceModel>
): Promise<InvoiceModel> => {
	return await axios.put(`${BASE_URL}/invoice/${data.id}`, data)
}

export const delete_invoice_api = async (data: string) => {
	return await axios.delete(`${BASE_URL}/invoice/${data}`)
}
export const delete_invoiceitem_api = async (data: string) => {
	return await axios.delete(`${BASE_URL}/invoice/invoice_item/${data}`)
}

export type InvoiceModel = {
	id: string
	start_date: string
	end_date: string
	due_date: string
	invoice_date: string
	location_id: string
	currency: string
	note: string
	status: boolean
	payment_status: boolean
	payment_method: string
	payment_note: string
	discount: number
	tax_rate: number
	location: LocationModel
	invoice_items: InvoiceItemModel[]
	preview?: boolean
}
export type InvoiceItemModel = {
	service: string
	staff: string
	duration_min: number
	charge: number
}
