import React, { useState } from 'react'
import { secondary_bg } from '../../../themes/themes'
import { useQuery } from '@tanstack/react-query'
import { settings_api, update_setting_api } from '../../../service/settings'

export default function Payroll() {
	const { data: settings } = useQuery({
		queryKey: ['settings'],
		queryFn: settings_api,
	})
	const [select, setSelect] = useState(Number(settings?.payroll_template))
	const changeTemplate = (value: number) => {
		setSelect(value)
		update_setting_api({ payroll_template: value.toString() })
	}
	const links = [
		'https://res.cloudinary.com/tobilasinde/image/upload/v1720585935/payroll2_of1ese.png',
		'https://res.cloudinary.com/tobilasinde/image/upload/v1720585935/payroll1_yfrsqx.png',
		'https://res.cloudinary.com/tobilasinde/image/upload/v1720585935/payroll3_wpq0gi.png',
	]
	return (
		<div>
			<p className='text-[16px] m-2 text-left'>Payroll</p>
			<div className='grid grid-cols-3 w-full gap-4  p-2 text-left '>
				{links.map((link, index) => (
					<button
						key={index}
						className={`h-80   rounded p-3 text-left ${
							select === index + 1 ? `bg-[${secondary_bg}]` : ' bg-gray-100'
						} `}
						onClick={() => changeTemplate(index + 1)}
					>
						<p className='my-2'>Payroll {index + 1}</p>
						<img
							alt={`Payroll ${index + 1}}`}
							className='h-60 w-full'
							src={link}
						/>
					</button>
				))}
			</div>
		</div>
	)
}
