import React, { useMemo, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import SearchInput from '../../../../src/component/SearchInput'
import Pagination from '../../../../src/component/Pagination'
import Loading from '../../../../src/component/loading'
import Table from '../../../../src/component/table'
import { useDebounce } from '@uidotdev/usehooks'
import { useMutation, useQuery } from '@tanstack/react-query'
import { delete_users_api, users_api } from '../../../../src/service/user'
import {
	CreateButton,
	DeleteButton,
	EditButton,
} from '../../../component/Buttons'
import FilterForm, { initialParams } from '../../../component/FilterForm'
import CsvExportButton from '../../../component/CsvExportButton'

export default function TeamMemberlist() {
	const navigate = useNavigate()
	const [pageParams, setPageParams] = useState(initialParams)
	const [search, setSearch] = useState('')
	const searchInput = useDebounce(search, 500)
	const queryParams = useMemo(() => {
		let res = `page_number=${pageParams.page_number}&page_size=${pageParams.page_size}&include=Role`
		if (searchInput) res += `&search=${searchInput}`
		if (pageParams.filter) res += `&filter=${pageParams.filter}`
		return res
	}, [pageParams, searchInput])
	const updatePageParams = (key: string, value: any) => {
		setPageParams((prev) => ({ ...prev, [key]: value }))
	}
	const { data, isLoading, refetch } = useQuery({
		queryKey: ['users', queryParams],
		queryFn: users_api,
	})
	const deleteMutation = useMutation({
		mutationFn: delete_users_api,
		onSuccess: () => {
			refetch()
		},
	})
	const columns = [
		{ key: 'id', title: 'User ID', excludeSort: true },
		{
			key: 'firstname',
			title: 'Name',
			render: (value: any, row: any) => value + ' ' + row.lastname,
		},
		{ key: 'phone', title: 'phone' },
		{ key: 'email', title: 'email' },
		{
			key: 'status',
			title: 'status',
			render: (value: any, row: any) => (
				<p
					className={`${
						value && row.onboard_status ? 'text-green-500' : 'text-red-500'
					}`}
				>
					{value && row.onboard_status ? 'Active' : 'Inactive'}
				</p>
			),
		},

		{
			key: 'role',
			title: 'Role',
			render: (value: any, row: any) => value?.name,
		},
		{
			key: 'id',
			title: 'Action',
			render: (value: any, row: any) => (
				<div className={`flex`}>
					<EditButton
						onClick={() => navigate(`/users/edit/${value}`)}
						id='users'
					/>
					<DeleteButton
						onClick={() => deleteMutation.mutate(value)}
						id='users'
					/>
				</div>
			),
		},
	]

	return (
		<div className=' bg-white rounded-lg'>
			<div className=' sm:flex p-4 justify-between'>
				<p className='text-[24px] font-medium'>Team member list</p>
				<div className='flex'>
					<CreateButton
						onClick={() => navigate('/users/create')}
						title='Add User'
						id='users'
					/>
					<CsvExportButton
						data={data?.rows}
						headers={undefined}
						filename={'location'}
						buttonText={'Export as CSV'}
					/>
				</div>
			</div>
			<div className='sm:grid sm:grid-cols-8 gap-4 border-dashed border-t  p-4'>
				<FilterForm
					fields={['status', 'role_id']}
					values={JSON.parse(pageParams.filter || '{}')}
					action={(val) => updatePageParams('filter', val)}
				/>
				<div className='col-span-7 '>
					<SearchInput
						placeholder='Search ...'
						label=''
						id='search'
						name='search'
						value={search}
						onChange={(e: any) => setSearch(e.target.value)}
					/>
				</div>
			</div>
			{isLoading ? (
				<Loading />
			) : (
				<div className=''>
					<Table
						columns={columns}
						data={data?.rows}
						showHeaderIcon={undefined}
						hasCheckBox={true}
					/>
				</div>
			)}

			<div
				className='flex overflow-x-auto sm:justify-end'
				id='pagination-globle-style'
			>
				<Pagination
					setPageSize={(val: number) => updatePageParams('page_size', val)}
					setPageNumber={(val: number) => updatePageParams('page_number', val)}
					currentPage={pageParams.page_number}
					pageSize={pageParams.page_size}
					totalCount={data?.count || 0}
				/>
			</div>

			{/* <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${editeOrCreate ? 'Create' : 'Edit'} location`} 
                size={'lg'}> */}

			{/* </Modal> */}
		</div>
	)
}
