import React from 'react'
import { CheckBox } from './CheckBox'

export default function Notifications() {
  return (
    <div>
        	<div className=' bg-white rounded-lg'>
				{/* <button onClick={() => setShowModal(true)}>Open Modal</button> */}
				<div className=' sm:flex p-3 justify-between'>
				<p className='text-[16px]  font-medium'>Settings</p>
				</div>
				<div className='p-3'>
					<p>Email Preferences</p>
				</div>
				<div className='flex-1 gap-2 flex pl-4 pb-3'>
					<CheckBox label='' />
					<div className='text-[14px] font-[400] text-[#7C7F80]'>
						<p>Successful Payments</p>
						<p>Receive a notification for every successful payment.</p>
					</div>
				</div>
				<div className='flex-1 gap-2 flex pl-4 pb-3'>
					<CheckBox label='' />
					<div className='text-[14px] font-[400] text-[#7C7F80]'>
						<p>Payouts</p>
						<p>Receive a notification for every initiated payout.</p>
					</div>
				</div>
				<div className='flex-1 gap-2 flex pl-4 pb-3'>
					<CheckBox label='' />
					<div className='text-[14px] font-[400] text-[#7C7F80]'>
						<p>Fee Collection</p>
						<p>Receive a notification each time you collect a fee from sales</p>
					</div>
				</div>
				<div className='flex-1 gap-2 flex pl-4 pb-3'>
					<CheckBox label='' />
					<div className='text-[14px] font-[400] text-[#7C7F80]'>
						<p>Invoice Payments</p>
						<p>
							Receive a notification if a customer sends an incorrect amount to
							pay their invoice.
						</p>
					</div>
				</div>

				<div className='grid grid-cols-3 gap-4 bg-white font-[400] text-[14px] text-[#7C7F80]'>
					<div className='col-span-3'>
						<div className='flex flex-col mb-4'>
							<div className='flex flex-row bg-white border'>
								<div className='flex-1 p-3 pl-6 text-black font-[500]'>
									Notifications
								</div>

								<div className=' p-3'>Phone </div>
								<div className='p-3'>Email </div>
							</div>

							<div className='flex flex-row bg-white  '>
								<div className='flex-1 p-2 px-6'>Notifications</div>
								<div className=' p-2 flex justify-center pr-8 '>
									<CheckBox label='' />
								</div>
								<div className='p-2 pr-8'>
									<CheckBox label='' />{' '}
								</div>
							</div>

							<div className='flex flex-row bg-white  '>
								<div className='flex-1 p-2 pl-6'>Billing Updates</div>
								<div className=' p-2 pr-8'>
									<CheckBox label='' />
								</div>
								<div className='p-2 pr-8'>
									<CheckBox label='' />{' '}
								</div>
							</div>

							<div className='flex flex-row bg-white  '>
								<div className='flex-1 p-2 pl-6'>New Team Members</div>
								<div className=' p-2 pr-8'>
									<CheckBox label='' />
								</div>
								<div className='p-2 pr-8'>
									<CheckBox label='' />{' '}
								</div>
							</div>

							<div className='flex flex-row bg-white  '>
								<div className='flex-1 p-2 pl-6'>Completed Projects</div>
								<div className=' p-2 pr-8'>
									<CheckBox label='' />
								</div>
								<div className='p-2 pr-8'>
									<CheckBox label='' />{' '}
								</div>
							</div>

							<div className='flex flex-row bg-white  '>
								<div className='flex-1 p-2 pl-6'>Newsletters</div>
								<div className=' p-2 pr-8'>
									<CheckBox label='' />
								</div>
								<div className='p-2 pr-8'>
									<CheckBox label='' />{' '}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>
  )
}
