// api.js

import axios from 'axios'
import { Response, queryParam } from './_model'
import { UserModel } from './user'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const log_api = async (
	data: queryParam
): Promise<Response<LogModel[]>> => {
	return await axios.get(`${BASE_URL}/log?${data.queryKey[1] || ''}`)
}

export const create_log_api = async (data: LogModel) => {
	return await axios.post(`${BASE_URL}/log`, data)
}

export const update_log_api = async (data: LogModel) => {
	return await axios.put(`${BASE_URL}/log/${data.id}`, data)
}

export const delete_log_api = async (data: string) => {
	return await axios.delete(`${BASE_URL}/log/${data}`)
}

export type LogModel = {
	id?: string
	url: string
	method: number
	statusCode: number
	email?: number
	user?: UserModel
}
