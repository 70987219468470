import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from './Button'
import {
	faEye,
	faPenToSquare,
	faPlus,
	faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../context/AuthContext'
import { checkPermission } from './permissions'
import { primary_bg } from '../themes/themes'
import { Loading2 } from './loading'

type Props = {
	onClick: () => void
	id: string
	title?: string
	className?: string
}
type Props2 = {
	onClick: () => void
	id: string
	action: 'read' | 'update' | 'create' | 'delete'
	title?: string
	className?: string
	rightIcon?: any
}
export const ActionButton = ({ onClick, id, action, title }: Props2) => {
	const { currentUser } = useAuth()
	return checkPermission(currentUser, id, action) ? (
		action === 'update' ? (
			<FontAwesomeIcon onClick={onClick} icon={faPenToSquare} />
		) : action === 'create' ? (
			<Button
				btnStyle='btn-primary'
				rightIcon={<FontAwesomeIcon icon={faPlus} />}
				title={title || 'Create'}
				onClick={onClick}
				type='button'
				className='mr-2'
			/>
		) : action === 'delete' ? (
			<FontAwesomeIcon
				onClick={onClick}
				className='text-[#F44336] ml-1'
				icon={faTrash}
			/>
		) : action === 'read' ? (
			<FontAwesomeIcon onClick={onClick} className='ml-1' icon={faEye} />
		) : null
	) : null
}
export const CreateButton = ({ onClick, title, id }: Props) => {
	const { currentUser } = useAuth()
	return checkPermission(currentUser, id, 'create') ? (
		<Button
			btnStyle='btn-primary'
			rightIcon={<FontAwesomeIcon icon={faPlus} />}
			title={title || 'Create'}
			onClick={onClick}
			type='button'
			className='mr-2'
		/>
	) : null
}
export const EditButton = ({ onClick, id }: Props) => {
	const { currentUser } = useAuth()
	return checkPermission(currentUser, id, 'update') ? (
		<FontAwesomeIcon onClick={onClick} icon={faPenToSquare} />
	) : null
}
export const DeleteButton = ({ onClick, id }: Props) => {
	const { currentUser } = useAuth()
	return checkPermission(currentUser, id, 'delete') ? (
		<FontAwesomeIcon
			onClick={onClick}
			className='text-[#F44336] ml-1'
			icon={faTrash}
		/>
	) : null
}
export const OtherButton = ({
	onClick,
	id,
	title,
	className,
	action,
	...options
}: Props2) => {
	const { currentUser } = useAuth()
	return checkPermission(currentUser, id, action) ? (
		<Button
			btnStyle='btn-primary'
			title={title}
			onClick={onClick}
			type='button'
			{...options}
		/>
	) : null
}
type SubmitProps = {
	loading?: boolean
	text?: string
	onClick?: () => void
}
export const SubmitButton = ({ loading, text, ...options }: SubmitProps) => {
	return (
		<button
			type={options.onClick ? 'button' : 'submit'}
			className={`w-full bg-[${primary_bg}] text-white p-2 rounded-md hover:opacity: 12,`}
			{...options}
		>
			{loading ? <Loading2 /> : text || 'Submit'}
		</button>
	)
}
