import React from 'react'
import BarChartComponent from './compenent/BarChartComponent';
import DashBoardCard from './compenent/dashBoardCard';
import DoughnutChartComponent from './compenent/DoughnutChartComponent';




export default function Dashboard() {
  return (
    <div>

      <DashBoardCard />

      <div className='grid grid-cols-4 gap-4'>
        <div className='col-span-3  bg-white   transition-all duration-300  rounded h-fit p-4'>
          <p className='text-[20px] font-bold'>User Engagement metrics </p>
          <BarChartComponent />
        </div>
        <div className=' bg-white   transition-all duration-300  rounded  p-4'>
          <p className='text-[20px] font-bold'>Invoicing </p>
         
          <DoughnutChartComponent />
          {[
            { color: '#99FFA3', title: 'Invoice Amount ($ 21,000,000)', id: 1 },
            { color:  '#FF9364',title: 'Paid Invoice ($ 10,000,000)', id: 2 },
            { color: '#B09FFF', title: 'Overdue Invoices ($ 11,000,000)', id: 3 }

          ].map((data) => (
            <div className='flex gap-3 m-3' key={data.id}>
              <div className={`size-5 bg-[${data.color}]`} style={{background:data.color}}></div>
              <p className='text-[14px]'>{data.title}</p>
            </div>
          ))}

        </div>
      </div>

    </div>
  )
}
