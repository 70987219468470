import axios from 'axios'
import { Response, queryParam } from './_model'
import { UserModel } from './user'

// api.js
const BASE_URL = process.env.REACT_APP_BASE_URL

export const payroll_api = async (
	data: queryParam
): Promise<Response<PayrollModel[]>> => {
	return await axios.get(`${BASE_URL}/payroll?${data.queryKey[1] || ''}`)
}
export const one_payroll_api = async (
	params: queryParam
): Promise<PayrollModel> => {
	return await axios.get(`${BASE_URL}/payroll/${params.queryKey[1]}`)
}

export const create_payroll_api = async (
	data: PayrollModel
): Promise<PayrollModel> => {
	return await axios.post(`${BASE_URL}/payroll`, data)
}

export const delete_payroll_api = async (data: string) => {
	return await axios.delete(`${BASE_URL}/payroll/${data}`)
}

export type PayrollModel = {
	id?: string
	start_date: string
	end_date: string
	user_id: string
	status?: boolean
	payment_status?: boolean
	user?: UserModel
	net_pay?: number
	currency?: string
	rate?: number
	hours_worked?: number
	gross_pay?: number
	ytd_hours_worked?: number
	ytd_pay?: number
}
