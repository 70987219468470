import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import Input, { Input2 } from '../../component/Input'
import SelectInput, {
	MultiSelect,
	SelectInput2,
} from '../../component/SelectInput'
import * as Yup from 'yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
	createSchedule,
	fetchOneSchedule,
	updateSchedule,
} from '../../service/schedule'
import { location_api } from '../../service/location'
import { service_api } from '../../service/service'
import { users_api } from '../../service/user'
import Button from '../../component/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import Textarea from '../../component/textarea'
import dayjs from 'dayjs'
import { ColorSelect } from '../../component/ColorSelect'
const times = [...Array(48)].map(
	(data, x) =>
		Math.floor(x / 2)
			.toString()
			.padStart(2, '0') + (x % 2 !== 0 ? ':30' : ':00')
)
const weekdays = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]
const validationSchema = Yup.object().shape({
	date: Yup.date().required('Date is required'),
	start_time: Yup.string().required('Start time is required'),
	duration: Yup.number().required('Duration is required'),
	service_id: Yup.string().required('Service ID is required'),
	location_id: Yup.string().required('Location ID is required'),
	user_schedules: Yup.array().required('At least one staff member is required'),
	repeat: Yup.boolean(),
	repeats: Yup.object().when('repeat', (repeat) => {
		return repeat[0] === true
			? Yup.object({
					type: Yup.string().required('Repeat type is required'),
					interval: Yup.number().required('Interval is required'),
					end_date: Yup.date().required('End date is required'),
			  })
			: Yup.object()
	}),
})

const SchedulesCreate = ({ setShowModal, id, defaultData }: any) => {
	const { data } = useQuery({
		queryKey: ['schedule', `${id}`],
		queryFn: fetchOneSchedule,
		enabled: !!id,
	})
	const [showOptions, setShowOptions] = useState(false)
	const queryClient = useQueryClient()
	const { data: locationData, isFetching: locationFetching } = useQuery({
		queryKey: ['locations', 'filter={"status":true}'],
		queryFn: location_api,
	})
	const { data: serviceData, isFetching: serviceFetching } = useQuery({
		queryKey: ['services', 'filter={"status":true}'],
		queryFn: service_api,
	})
	const { data: usersData, isFetching: userFetching } = useQuery({
		queryKey: [
			'users',
			'sort_field=firstname&sort_order=asc&filter={"status":true}',
		],
		queryFn: users_api,
	})

	const mutation = useMutation({
		mutationFn: id ? updateSchedule : createSchedule,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['user_schedules'] })
			queryClient.invalidateQueries({ queryKey: ['empty_user_schedules'] })
			setShowModal(false)
		},
	})
	const initialValues = useMemo(() => {
		const init: any = {
			date: '',
			start_time: '08:00',
			end_time: '12:00',
			duration: '',
			note: '',
			color: '',
			service_id: '',
			location_id: '',
			user_schedules: [],
			status: 'published',
			time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			repeat: false,
			repeats: {
				type: 'day',
				interval: 1,
				end_date: dayjs().add(1, 'month').format('YYYY-MM-DD'),
				days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
			},
		}
		return { ...init, ...defaultData }
	}, [defaultData])
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: data
			? {
					...data,
					start_time: dayjs.utc(data.start_time).format('HH:mm'),
					end_time: dayjs.utc(data.end_time).format('HH:mm'),
					user_schedules: data.user_schedules.map((d: any) => {
						return {
							value: d.staff_id,
							label: d.user.firstname + ' ' + d.user.lastname,
						}
					}),
			  }
			: initialValues,
		validationSchema,
		onSubmit: (values) => {
			let formData: any = { ...values }
			delete formData['end_time']
			delete formData['location']
			delete formData['service']
			formData.user_schedules = formData.user_schedules.map(
				(data: any) => data.value
			)
			mutation.mutate(formData)
		},
	})
	const handleItemClick = (item: string) => {
		if (formik.values.repeats.days.includes(item))
			formik.setFieldValue(
				'repeats.days',
				formik.values.repeats.days.filter((d: string) => d !== item)
			)
		else
			formik.setFieldValue('repeats.days', [
				...formik.values.repeats.days,
				item,
			])
	}
	useEffect(() => {
		if (formik.values.start_time && formik.values.end_time) {
			let start_time = formik.values.start_time.split(':')
			start_time = Number(start_time[0]) * 60 + Number(start_time[1])
			let end_time = formik.values.end_time.split(':')
			end_time = Number(end_time[0]) * 60 + Number(end_time[1])
			let duration = end_time - start_time
			if (start_time >= end_time) duration += 24 * 60
			formik.setFieldValue('duration', duration)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.start_time, formik.values.end_time])
	return (
		<form onSubmit={formik.handleSubmit} className='grid grid-cols-2 gap-4'>
			<MultiSelect
				meta={formik.getFieldMeta('service_id')}
				onChange={(v: any) => formik.setFieldValue('service_id', v?.value)}
				label='Service'
				name='service_id'
				isLoading={serviceFetching}
				options={serviceData?.rows.map((data) => {
					return {
						value: data.id,
						label: data.name,
					}
				})}
			/>
			<MultiSelect
				meta={formik.getFieldMeta('location_id')}
				onChange={(v: any) => formik.setFieldValue('location_id', v?.value)}
				label='Location'
				name='location_id'
				isLoading={locationFetching}
				options={locationData?.rows.map((data) => {
					return {
						value: data.id,
						label: data.name,
					}
				})}
			/>
			<div>
				<label
					htmlFor='user_schedules'
					className='block flex text-sm mb-1 text-[#7C7F80]'
				>
					Color
				</label>
				<ColorSelect
					name='color'
					value={formik.values.color}
					onChange={(val) => formik.setFieldValue('color', val)}
				/>
			</div>
			<Input
				required={true}
				label={'Start date'}
				type='date'
				id='date'
				name='date'
				onChange={formik.handleChange}
				value={formik.values.date}
				className='mt-1 p-2 border rounded-md w-full flex-1'
			/>
			<div className='grid grid-cols-4 gap-2 col-span-2'>
				<MultiSelect
					meta={formik.getFieldMeta('start_time')}
					onChange={(v: any) => formik.setFieldValue('start_time', v?.value)}
					label='Start time'
					name='start_time'
					options={times.map((data) => {
						return {
							value: data,
							label: data,
						}
					})}
					allowCreate={true}
					validator={(v: string) =>
						v.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)
					}
				/>
				<MultiSelect
					meta={formik.getFieldMeta('end_time')}
					onChange={(v: any) => formik.setFieldValue('end_time', v?.value)}
					label='End time'
					name='end_time'
					options={times.map((data) => {
						return {
							value: data,
							label: data,
						}
					})}
					allowCreate={true}
					validator={(v: string) =>
						v.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)
					}
				/>
				<div>
					<label className='block text-sm mb-1 flex text-[#7C7F80]'>
						Duration
					</label>
					{formik.values.duration && (
						<div className='text-sm'>
							{Math.floor(formik.values.duration / 60)}h{' '}
							{formik.values.duration % 60}m
						</div>
					)}
				</div>
			</div>
			<MultiSelect
				meta={formik.getFieldMeta('user_schedules')}
				onChange={(v: any) => formik.setFieldValue('user_schedules', v)}
				label='Staffs'
				name='user_schedules'
				isLoading={userFetching}
				options={usersData?.rows.map((data: any) => ({
					value: data.id,
					label: data.firstname + ' ' + data.lastname,
				}))}
				isMulti={true}
			/>
			<Textarea
				label='Note'
				name='note'
				onChange={formik.handleChange}
				value={formik.values.note}
				id='discription'
			/>

			<div className='col-span-2'>
				<button
					type='button'
					className='sm:text-[20px] sm:mb-4 text-left flex items-center gap-2 font-[600]'
					onClick={() => setShowOptions(!showOptions)}
				>
					Options
					{showOptions ? (
						<FontAwesomeIcon icon={faCaretUp} />
					) : (
						<FontAwesomeIcon icon={faCaretDown} />
					)}
				</button>
				{showOptions && (
					<div className='grid gap-2 col-span-2 text-left'>
						<div className='grid grid-cols-2  col-span-2  gap-2 '>
							<SelectInput
								formik={formik}
								label={'Time zone'}
								id='time_zone'
								name='time_zone'
								onChange={formik.handleChange}
								value={formik.values.time_zone}
								options={Intl.supportedValuesOf('timeZone').map((data) => ({
									id: data,
									name: data,
								}))}
								option={{ value: 'id', label: 'name' }}
								required={false}
							/>

							<Input
								label={'Hourly Custom charges'}
								type='number'
								id='custom_charge'
								name='custom_charge'
								onChange={formik.handleChange}
								value={formik.values.custom_charge}
							/>
						</div>
						{!formik.values.id && (
							<>
								<div className='grid grid-cols-2  col-span-2  gap-2 my-2'>
									<div className='flex'>
										<input
											type='checkbox'
											className='shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none '
											id='hs-checkbox-group-1'
											onChange={(e) =>
												formik.setFieldValue('repeat', e.target.checked)
											}
											name='repeat'
											checked={formik.values.repeat}
										/>
										<label
											htmlFor='hs-checkbox-group-1'
											className='text-sm text-gray-500 ms-3 dark:text-gray-400'
										>
											Repeat
										</label>
									</div>
								</div>
								{formik.values.repeat && (
									<>
										<div className='grid grid-cols-2  col-span-2  gap-2 '>
											<SelectInput2
												{...formik.getFieldProps('repeats[type]')}
												meta={formik.getFieldMeta('repeats[type]')}
												label={'Type'}
												options={[
													{ value: 'day', label: 'Daily' },
													{ value: 'week', label: 'Weekly' },
													{ value: 'month', label: 'Monthly' },
												]}
											/>
											<Input2
												{...formik.getFieldProps('repeats[interval]')}
												meta={formik.getFieldMeta('repeats[interval]')}
												label='Interval'
												type='number'
											/>
											{formik.values.repeats?.type === 'week' && (
												<>
													<label
														className={`block flex text-sm text-[#7C7F80]`}
													>
														Days
													</label>
													<div className='flex bg-[#FFF4EC]  col-span-2 '>
														{weekdays.map((day, index) => (
															<button
																type='button'
																key={index}
																onClick={() => handleItemClick(day)}
																className={`flex-1  p-3 border-l-2 border-gray-200 border-r-gray-100  ${
																	formik.values.repeats.days.includes(day)
																		? 'bg-[#EC7211]  text-white'
																		: ''
																} `}
															>
																{day}
															</button>
														))}
													</div>
												</>
											)}
											<Input2
												{...formik.getFieldProps('repeats[end_date]')}
												meta={formik.getFieldMeta('repeats[end_date]')}
												label='Repeat Ends On'
												type='date'
											/>
										</div>
										<div className='grid gap-2 col-span-2  col-span-2  text-[#EC7211] text-[14px]'>
											<p>
												REPEAT SUMMARY
												<br />
												Every{' '}
												{formik.values.repeats.interval > 1
													? formik.values.repeats.interval
													: ''}{' '}
												{formik.values.repeats.type}
												{formik.values.repeats.interval > 1 ? 's' : ''},{' '}
												{formik.values.repeats.type === 'week' &&
													`on ${formik.values.repeats.days.map(
														(d: string) => d + ' '
													)}`}{' '}
												until {formik.values.repeats.end_date}
											</p>
										</div>
									</>
								)}
							</>
						)}
					</div>
				)}
			</div>

			<div className='flex gap-3 col-span-2   flex-row-reverse '>
				<button
					type='button'
					onClick={() => setShowModal()}
					className={`border border-[#EC7211] px-6  rounded-md text-[#EC7211] text-center `}
				>
					Cancel
				</button>

				<Button
					type='submit'
					disabled={!formik.isValid}
					loading={mutation.isPending}
					className={`bg-[#2196F3] text-white  rounded-md  px-6`}
					title='Draft'
					onClick={() => {
						formik.setFieldValue('status', 'draft')
					}}
				/>
				<Button
					type='submit'
					disabled={!formik.isValid}
					loading={mutation.isPending}
					className={`bg-[#EC7211] text-white p-2 rounded-md  `}
					title='Publish'
					onClick={() => {
						formik.setFieldValue('status', 'published')
					}}
				/>
			</div>

			{/* </div> */}
		</form>
	)
}

export default SchedulesCreate
