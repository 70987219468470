import {
	faDownload,
	faHandHoldingDollar,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import Button from '../../../component/Button'
import Table from '../../../component/table'
import { getColorWithOpacity } from '../../../utility/generateColorWithOpacity'
import { InvoiceModel } from '../../../service/Invoice'
import { SettingModel } from '../../../service/settings'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { fileSrc } from '../../../component/FileLoader'
import { OtherButton } from '../../../component/Buttons'
import { print } from '../../../component/print'
dayjs.extend(relativeTime)
dayjs.extend(utc)

export default function InvoicePreviewTwo({
	data,
	settings,
	setShowModal,
}: {
	data?: InvoiceModel
	settings?: SettingModel
	setShowModal: (a: boolean) => void
}) {
	const columns = [
		// { key: 'id', title: 'ID' },
		{ key: 'service', title: 'Service' },
		{ key: 'staff', title: 'Staff' },
		{ key: 'duration', title: 'Duration' },
		{ key: 'charge', title: 'Total' },
	]

	const currency = useMemo(() => {
		return data?.currency || settings?.currency || 'USD'
	}, [data?.currency, settings?.currency])
	const invoice_items = useMemo(() => {
		return (
			data?.invoice_items?.map((item) => {
				return {
					service: item.service,
					staff: item.staff,
					duration: item.duration_min
						? `${Math.floor(item?.duration_min / 60)}h ${
								item?.duration_min % 60
						  }m`
						: '',
					charge: item.charge?.toLocaleString('en-US', {
						style: 'currency',
						currency,
					}),
				}
			}) || [{ service: '', staff: '', duration: '', charge: '' }]
		)
	}, [currency, data?.invoice_items])
	const totals = useMemo(() => {
		const result = {
			subtotal: 0,
			tax: data?.tax_rate || 0,
			discount: data?.discount || 0,
			total: 0,
		}
		result['subtotal'] =
			data?.invoice_items?.reduce(
				(acc: any, item: any) => acc + Number(item.charge),
				0
			) || 0
		result['tax'] = result.subtotal * (result.tax / 100)
		result.total = result.subtotal + result.tax - result.discount
		return result
	}, [data])
	return (
		<div className='  rounded-xl  bg-white'>
			<div className='w-full  p-6'>
				<div className='flex justify-between mb-3'>
					<div className='flex-none text-xl font-semibold flex'>
						{settings?.business_logo && (
							<img
								className=' h-[30px] w-[30px] object-contain'
								src={fileSrc({
									id: settings?.business_logo,
									provider: 'cloudinary',
									format: '',
								})}
								alt=''
							/>
						)}
						<p className='pl-2 text-[14px]'>{settings?.business_name}</p>
					</div>
					<div className='flex items-center gap-2'>
						<p className='text-[#1E2325] text-[14px]'>
							Invoice Number: #{data?.id}
						</p>
						<button
							style={{
								backgroundColor: getColorWithOpacity('#4CAF50', 0.2),
							}}
							className='p-2 text-[#4CAF50] px-4 rounded-lg text-[12px]'
						>
							{data?.status ? 'Active' : 'Inactive'}
						</button>
					</div>
				</div>
				<hr />

				<div className='font-[400] flex flex-col gap-1 mb-3 text-[14px]'>
					<div className='flex justify-between '>
						<p className='text-[14px] text-[#7C7F80] pt-2'>Bill to</p>
						<p className='text-[14px] text-[#7C7F80] pt-4'>Date created</p>
					</div>
					<div className='flex justify-between '>
						<p>{data?.location.name}</p>
						<p>{dayjs.utc(data?.invoice_date).format('DD/MM/YYYY')}</p>
					</div>
					<p className='text-[#7C7F80] text-[14px]'>{data?.location.address}</p>
					<div className='flex justify-between '>
						<p className='text-[#7C7F80] text-[14px]'>{data?.location.email}</p>
						<p className='text-[#7C7F80] text-[14px]'>Date due</p>
					</div>
					<div className='flex justify-between '>
						<p className='text-[#7C7F80] text-[14px]'>{data?.location.phone}</p>
						<p>
							{dayjs.utc(data?.due_date).format('DD/MM/YYYY')}{' '}
							<span className='text-[#EC7211] '>
								{' '}
								Due in {dayjs.utc(data?.due_date).toNow(true)}
							</span>
						</p>
					</div>
				</div>
				<div className='bg-[#FAFAFA] p-3 my-6 '>
					<p className='text-[14px] text-[#7C7F80]'>Payment instruction</p>
					<p
						className='text-[16px] font-[400]'
						dangerouslySetInnerHTML={{
							__html: settings?.payment_instructions || '',
						}}
					></p>
				</div>

				<hr />

				<p className='font-[500] text-[14px] mt-3 m-1'>Item(s) Details</p>
				<Table
					tableStyle={'rounded-[10px] border border-gray-200'}
					columns={columns}
					data={invoice_items}
					showHeaderIcon={true}
					hasCheckBox={false}
				/>
				<div className='flex flex-row-reverse text-sm '>
					<div className=' w-2/4 px-7'>
						<div className='grid grid-cols-2 gap-4 text-[14px] font-[400] '>
							<div className='  p-3 flex flex-col gap-3 '>
								{[
									'Duration',
									'Currency',
									'Payment status',
									'Payment Method',
									'Payment Note',
									'Subtotal',
									settings?.tax_name
										? `${settings.tax_name}(${settings.tax_rate}%)`
										: 'Tax',
									'Discount',
									'Total',
								].map((item, i) => (
									<div key={i}>{item}</div>
								))}
							</div>
							<div className=' p-3 flex flex-col gap-3 '>
								{[
									`${dayjs.utc(data?.start_date).format('DD/MM/YYYY')} - ${dayjs
										.utc(data?.end_date)
										.format('DD/MM/YYYY')}`,
									currency,
									data?.payment_status ? 'Paid' : 'Unpaid',
									data?.payment_method,
									data?.payment_note,
									totals.subtotal?.toLocaleString('en-US', {
										style: 'currency',
										currency,
									}),
									totals?.tax.toLocaleString('en-US', {
										style: 'currency',
										currency,
									}),
									data?.discount.toLocaleString('en-US', {
										style: 'currency',
										currency,
									}),
									totals.total?.toLocaleString('en-US', {
										style: 'currency',
										currency,
									}),
								].map((item, i) => (
									<div key={i}>{item}</div>
								))}
							</div>
						</div>
					</div>
				</div>

				<div className='bg-[#FAFAFA] p-3 my-3 mr-5 w-full'>
					<p className='text-[14px] text-[#7C7F80]'>Note</p>
					<p className='text-[16px] font-[400]'>{data?.note}</p>
				</div>
				<div className='no-print flex gap-2 justify-end '>
					{data?.payment_status !== true && (
						<OtherButton
							onClick={() => setShowModal(true)}
							id='invoices'
							title='Mark As Paid'
							className='btn-primary'
							action='update'
							rightIcon={<FontAwesomeIcon icon={faHandHoldingDollar} />}
						/>
					)}
					<Button
						title='Download'
						className='h-fit'
						btnStyle='btn-primary'
						rightIcon={<FontAwesomeIcon icon={faDownload} />}
						onClick={() => print('portrait')}
					/>
					{/* <Button
								title='Print'
								className='h-fit'
								btnStyle='btn-outline'
								rightIcon={<FontAwesomeIcon icon={faPrint} />}
							/> */}
				</div>
			</div>
		</div>
	)
}
