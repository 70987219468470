export const checkPermission = (
	currentUser: any,
	model: string,
	action: string
) => {
	if (process.env.REACT_APP_ADMIN?.includes(currentUser.role?.name)) return true
	const permissionModel = currentUser?.permissions?.find(
		(x: any) => x.model_name === model
	)
	if (!permissionModel) return false
	return permissionModel[action]
}
