import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { secondary_bg } from '../../themes/themes'
import Emailtemplate from './component/Emailtemplate'
import GeneralSettings from './component/GeneralSettings'
import Invoicesettings from './component/Invoicesettings'
import Notifications from './component/Notifications'
import Payroll from './component/Payroll'
import Onboarding from './component/Onboarding'

export default function Settings() {
	const [selected, setSelected] = useState('Generalsettings')
	return (
		<div className='grid grid-cols-4 gap-4'>
			<div className='bg-white rounded p-2 flex flex-col  gap-2 h-fit'>
				<button
					onClick={() => setSelected('Generalsettings')}
					className={`flex w-full justify-between p-3  transition-all duration-300 
				 ${selected === 'Generalsettings' && `text-primary bg-[${secondary_bg}]`} 
				 rounded items-center`}
				>
					<p>General settings</p>
					<FontAwesomeIcon icon={faChevronRight} />
				</button>
				<button
					onClick={() => setSelected('Payroll')}
					className={`flex w-full justify-between p-3  transition-all duration-300 
				 ${selected === 'Payroll' && `text-primary bg-[${secondary_bg}]`} 
				 rounded items-center`}
				>
					<p>Payroll settings</p>
					<FontAwesomeIcon icon={faChevronRight} />
				</button>
				<button
					onClick={() => setSelected('Invoicesettings')}
					className={`flex w-full justify-between p-3  transition-all duration-300 
				 ${selected === 'Invoicesettings' && `text-primary bg-[${secondary_bg}]`} 
				 rounded items-center`}
				>
					<p>Invoice settings</p>
					<FontAwesomeIcon icon={faChevronRight} />
				</button>
				<button
					onClick={() => setSelected('Emailtemplate')}
					className={`flex w-full justify-between p-3  transition-all duration-300 
				 ${selected === 'Emailtemplate' && `text-primary bg-[${secondary_bg}]`} 
				 rounded items-center`}
				>
					<p>Email template</p>
					<FontAwesomeIcon icon={faChevronRight} />
				</button>
				{/* <button
					onClick={() => setSelected('Notifications')}
					className={`flex w-full justify-between p-3 rounded   transition-all duration-300 
					${selected === 'Notifications' && `text-primary bg-[${secondary_bg}]`} 
					items-center`}
				>
					<p>Notifications</p>
					<FontAwesomeIcon icon={faChevronRight} />
				</button> */}
				<button
					onClick={() => setSelected('Onboarding')}
					className={`flex w-full justify-between p-3 rounded   transition-all duration-300 
					${selected === 'Onboarding' && `text-primary bg-[${secondary_bg}]`} 
					items-center`}
				>
					<p>Onboarding</p>
					<FontAwesomeIcon icon={faChevronRight} />
				</button>
			</div>
			<div className='col-span-3  bg-white   transition-all duration-300  rounded h-fit p-2'>
				<div
					className={`w-full ${
						selected === 'Notifications'
							? 'animate__fadeIn animate__animated'
							: 'hidden'
					}`}
				>
					{selected === 'Notifications' && <Notifications />}
				</div>
				<div
					className={`w-full ${
						selected === 'Generalsettings'
							? 'animate__fadeIn animate__animated'
							: 'hidden'
					}`}
				>
					{selected === 'Generalsettings' && <GeneralSettings />}
				</div>
				<div
					className={`w-full ${
						selected === 'Invoicesettings'
							? 'animate__fadeIn animate__animated'
							: 'hidden'
					}`}
				>
					{selected === 'Invoicesettings' && <Invoicesettings />}
				</div>
				<div
					className={`w-full ${
						selected === 'Emailtemplate'
							? 'animate__fadeIn animate__animated'
							: 'hidden'
					}`}
				>
					{selected === 'Emailtemplate' && <Emailtemplate />}
				</div>
				<div
					className={`w-full ${
						selected === 'Payroll'
							? 'animate__fadeIn animate__animated'
							: 'hidden'
					}`}
				>
					{selected === 'Payroll' && <Payroll />}
				</div>
				<div
					className={`w-full ${
						selected === 'Onboarding'
							? 'animate__fadeIn animate__animated'
							: 'hidden'
					}`}
				>
					{selected === 'Onboarding' && <Onboarding />}
				</div>
			</div>
		</div>
	)
}
