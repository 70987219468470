
export default function CardSchedule({ color, title, subTitle }:
    {
        subTitle: any,
        title: any,
        color: 'red' | 'green' | 'blue'
    }) {
    return (<>
        <div className='flex justify-between p-4 rounded-lg bg-white mb-3 items-center'>
            <div>
                <p className='text-[16px] font-[400] text-[#1E2325]'>{title}</p>
                <p className='sm:text-[24px] font-[500]'>{subTitle}</p>
            </div>
            {color == 'blue' && <img className='h-14 w-15 object-cover' src={`${process.env.PUBLIC_URL}/icon/card-icon-clock-blue.png`} />}
            {color == 'red' && <img className='h-14 w-14 object-contain' src={`${process.env.PUBLIC_URL}/icon/card-icon-clock-orange.png`} />}
            {color == 'green' && <img className='h-14 w-14 object-contain' src={`${process.env.PUBLIC_URL}/icon/card-icon-clock-green.png`} />}

        </div>
    </>)
}