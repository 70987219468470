import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import Forget from '../layout/auth/forget'
import Login from '../layout/auth/login'
import OTPPage from '../layout/auth/otpPage'
import MainApp from '../layout/main-app'
import ErrorPage from '../pages/error/error'
// import SchedulesCreate from '../pages/Schedules/create'
// import Profile from '../../test/Profile/Profile'
// import Report from '../../test/Reports/Report'
import Schedules from '../pages/Schedules/Schedules'
import Location from '../pages/Location/Location'
import Permissions from '../pages/UserManagements/Permissions/Permissions'
import TeamMemberlist from '../pages/UserManagements/ TeamMember/ TeamMemberlist'
import AddTeamMember from '../pages/UserManagements/ TeamMember/AddTeamMember'
import ChangePassword from '../layout/auth/changePassword'
import AssignRolePermissions from '../pages/UserManagements/Permissions/assignRolePermissions'
import { useAuth } from '../context/AuthContext'
import Invoice from '../pages/Finance/Invoice/Invoice'
import InvoiceForm from '../pages/Finance/Invoice/InvoiceForm'
import InvoicePreview from '../pages/Finance/Invoice/InvoicePreview'
import Payroll from '../pages/Finance/Payroll/Payroll'
import PayrollDetails from '../pages/Finance/Payroll/PayrollPreview'
import PayrollForm from '../pages/Finance/Payroll/PayrollForm'
import TimeOffCategory from '../pages/Timeoff/category/TimeOffCategory'
import Service from '../pages/Service/Service'
import Audit from '../pages/Audit/Audit'
import UnderDevelopment from '../pages/error/underDevelopment'
import Profile from '../pages/Profile/Profile'
// import Report from '../pages/Reports/Report'
import ReportSchedule from '../pages/Reports/Payroll/PayrollReport'
import TimeSheetList from '../pages/Reports/TimeSheet/TimeSheetList'
import Settings from '../pages/Settings/Settings'
import TimeOffRequest from '../pages/Timeoff/request/timeOffRequest'
import Dashboard from '../pages/dashboard/dashboard'
import InvoiceSchedule from '../pages/Reports/Report'
import AuthBackGround from '../layout/auth/helper/authBackGround'
import AcceptInvite from '../layout/auth/AcceptInvite'
import Chat from '../pages/Communications/Chat'

const router = (currentUser: string | null) =>
	createBrowserRouter([
		{
			path: '/',
			element: currentUser ? <MainApp /> : <Navigate to='/auth' />,
			errorElement: <ErrorPage />,
			children: [
				{ index: true, element: <Navigate to='/schedules' replace /> },
				{
					path: 'dashboard',
					element: <Dashboard />,
				},
				{
					path: 'users',
					children: [
						{
							path: '',
							element: <TeamMemberlist />,
						},
						{
							path: 'edit/:id',
							element: <AddTeamMember />,
						},
						{
							path: 'create',
							element: <AddTeamMember />,
						},
						{
							path: 'roles',
							children: [
								{
									path: '',
									element: <Permissions />,
								},
								{
									path: 'permissions/:role_id',
									element: <AssignRolePermissions />,
								},
							],
						},
					],
				},
				{
					path: 'locations',
					element: <Location />,
				},
				{
					path: 'services',
					element: <Service />,
				},
				{
					path: 'schedules',
					element: <Schedules />,
				},
				{
					path: 'timeoff',
					children: [
						{
							path: '',
							element: <TimeOffRequest />,
						},
						{
							path: 'timeoff-categories',
							element: <TimeOffCategory />,
						},
						{
							path: 'timeoff-requests',
							element: <TimeOffRequest />,
						},
					],
				},
				{
					path: 'finances',
					children: [
						{
							path: '',
							element: <Invoice />,
						},
						{
							path: 'invoices',
							children: [
								{
									path: 'create',
									element: <InvoiceForm />,
								},
								{
									path: 'edit/:id',
									element: <InvoiceForm />,
								},
								{
									path: 'preview/:id',
									element: <InvoicePreview />,
								},
								{
									path: '',
									element: <Invoice />,
								},
							],
						},
						{
							path: 'payrolls',
							children: [
								{
									path: 'create',
									element: <PayrollForm />,
								},
								{
									path: 'preview/:id',
									element: <PayrollDetails />,
								},
								{
									path: '',
									element: <Payroll />,
								},
							],
						},
					],
				},
				// {
				// 	path: 'Report',
				// 	children: [
				// 		{
				// 			path: 'report-schedule',
				// 			element: <ReportSchedule />,
				// 		},
				// 	],
				// },
				{
					path: 'settings',
					element: <Settings />,
				},

				{
					path: 'communications',
					// element: <UnderDevelopment />,
					children: [
						{
							path: 'chat',
							element: <Chat />,
						},
						{
							path: 'FAQ’s',
							element: <UnderDevelopment />,
						},
						{
							path: 'Announcement',
							element: <UnderDevelopment />,
						},
					],
				},
				{
					path: 'audit-logs',
					element: <Audit />,
				},

				{
					path: 'report',
					children: [
						{
							path: '',
							element: <TimeSheetList />,
						},
						{
							path: 'invoice',
							element: <InvoiceSchedule />,
						},
						{
							path: 'report',
							element: <ReportSchedule />,
						},
						{
							path: 'timesheets',
							element: <TimeSheetList />,
						},
					],
				},
				{
					path: 'profile',
					element: <Profile />,
				},
			],
		},
		{
			path: '/auth',
			element: currentUser ? <Navigate to='/' /> : <AuthBackGround />,
			children: [
				{
					path: 'forget-password',
					element: <Forget />,
				},
				{
					path: 'reset-password/:credentials',
					element: <ChangePassword />,
				},
				{
					path: 'accept/:invite_credentials',
					element: <AcceptInvite />,
				},
				{
					path: 'otp/:email',
					element: <OTPPage />,
				},
				{
					path: 'login',
					element: <Login />,
				},
				{
					path: '',
					element: <Login />,
				},
			],
		},
	])

export default function Router() {
	const { currentUser } = useAuth()
	return <RouterProvider router={router(currentUser)} />
}
