import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Button from '../../../component/Button'
import { PayrollModel } from '../../../service/payroll'
import { SettingModel } from '../../../service/settings'
import dayjs from 'dayjs'
import { print } from '../../../component/print'

export default function PayrollDetailsTwo({
	data,
	settings,
}: {
	data?: PayrollModel
	settings?: SettingModel
}) {
	return (
		<div className='rounded-lg bg-white p-8'>
			<div className='flex justify-between'>
				<div className='flex-none text-xl font-semibold flex'>
					<img
						className=' h-[30px] w-[30px] object-contain'
						src='/logo.png'
						alt=''
					/>
					<p className='px-2 text-[14px]'>{settings?.business_name} </p>
				</div>
				<p className='text-[#1E2325]'>PAYSLIP #{data?.id}</p>
			</div>
			<div
				className='font-[500] text-[14px] my-5'
				dangerouslySetInnerHTML={{
					__html: settings?.business_address || '',
				}}
			></div>
			<div className='font-[400] text-[14px] text-[#7C7F80]'>
				<p className='my-2'>
					{data?.user?.firstname} {data?.user?.lastname}
				</p>
				<p>{data?.user?.role?.name}</p>
				<p>User ID: {data?.user_id}</p>
				{/* <p>Joining Date: 1 Jan 2023</p> */}
			</div>

			<p className='font-[700] text-center m-6 text-[20px]'>
				{dayjs.utc(data?.start_date).format('Do MMMM YYYY')} -{' '}
				{dayjs.utc(data?.end_date).format('Do MMMM YYYY')}
			</p>

			<div className=' grid grid-cols-2 gap-4'>
				<div>
					<p className='font-[500] text-[14px] mb-2 ml-1'>EARNINGS</p>
					<div className='grid grid-cols-2 gap-4 text-[14px] font-[400] rounded-lg border border-2'>
						<div className='bg-[#FFF4EC]  p-3 flex flex-col gap-3 '>
							{[
								'EARNINGS',
								'Rate',
								'Duration',
								'Gross Pay',
								'YEAR TO DATE',
							].map((item) => (
								<div>{item}</div>
							))}
						</div>
						<div className=' p-3 flex flex-col gap-3 '>
							{[
								'REGULAR',
								data?.rate?.toLocaleString('en-US', {
									style: 'currency',
									currency: data.currency || 'USD',
								}),
								data?.hours_worked,
								data?.gross_pay?.toLocaleString('en-US', {
									style: 'currency',
									currency: data.currency || 'USD',
								}),
								data?.ytd_pay?.toLocaleString('en-US', {
									style: 'currency',
									currency: data.currency || 'USD',
								}),
							].map((item) => (
								<div>{item}</div>
							))}
						</div>
					</div>
				</div>

				<div>
					<p className='font-[500] text-[14px] mb-2 ml-1'>DEDUCTIONS</p>

					<div className='grid grid-cols-2 gap-4 text-[14px] font-[400] rounded-lg border border-2'>
						<div className='bg-[#FFF4EC]  p-3 flex flex-col gap-3 '>
							{[
								'FEDERAL TAX',
								'PROVINCIAL TAX',
								'CPP',
								'EI',
								'OTHER',
								'TOTAL DEDUCTIONS',
							].map((item) => (
								<div>{item}</div>
							))}
						</div>
						<div className=' p-3 flex flex-col gap-3 '>
							{[
								'REGULAR',
								'$28/HR',
								'3 hours ',
								'$4000',
								'$28000',
								'$28000',
							].map((item) => (
								<div>{item}</div>
							))}
						</div>
					</div>

					<div className='     mt-6'>
						<div>
							<p className='text-[14px] font-[400] text-[#7C7F80]'>
								REGULAR PAY (PLUS GST/HST)
							</p>
							<p className='text-[16px] mt-1 font-[500]'>
								{data?.net_pay?.toLocaleString('en-US', {
									style: 'currency',
									currency: data.currency || 'USD',
								})}
							</p>
						</div>

						<div className=' mt-3 '>
							<div className='no-print flex gap-2'>
								<Button
									title='Download'
									className='h-fit'
									btnStyle='btn-primary'
									rightIcon={<FontAwesomeIcon icon={faDownload} />}
									onClick={() => print('landscape')}
								/>
								<Button
									title='Cancel'
									className='h-fit'
									btnStyle='btn-outline'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
