import React from 'react'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { change_password_api, otp_api } from '../../service/auth'
import { ChangePasswordValidation } from './helper/formValidation'
import PasswordInput from '../../component/PasswordInput'
import { SubmitButton } from '../../component/Buttons'

const ChangePassword = () => {
	const navigate = useNavigate()
	let { credentials } = useParams()
	const { data, isLoading, isError, isSuccess } = useQuery({
		queryKey: ['check_reset_token', { credentials }],
		queryFn: otp_api,
		enabled: !!credentials,
	})

	const mutation = useMutation({
		mutationFn: change_password_api,
		onSuccess: () => {
			navigate('/auth')
		},
	})

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			password: '',
			confirm_password: '',
			email: data?.email,
			credentials: data?.credentials,
			business_id: data?.business_id,
		},
		validationSchema: ChangePasswordValidation,
		onSubmit: (values: any) => mutation.mutate(values),
	})
	return (
		<div className='relative z-10 bg-white p-8 rounded-lg sm:w-96'>
			<h2 className='text-2xl  mb-1 text-center'>Change Password!</h2>
			<p className='text-gray-400 text-sm text-center mb-4'>
				{isLoading && <p>Checking Credentials...</p>}
				{isError && <p>Invalid OTP</p>}
				{isSuccess && <p>Enter your new password</p>}
			</p>
			{/* Form */}
			{isSuccess && (
				<form onSubmit={formik.handleSubmit}>
					<PasswordInput
						required={true}
						label='Password'
						id='password'
						name='password'
						type='password'
						value={formik.values.password}
						formik={formik}
						onChange={formik.handleChange}
					/>
					<PasswordInput
						required={true}
						label='Confirm Password'
						id='confirm_password'
						name='confirm_password'
						type='password'
						value={formik.values.confirm_password}
						formik={formik}
						onChange={formik.handleChange}
					/>
					<SubmitButton />
				</form>
			)}
		</div>
	)
}

export default ChangePassword
