import { useDebounce } from '@uidotdev/usehooks'
import React, { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import Loading from '../../../component/loading'
import Pagination from '../../../component/Pagination'
import SearchInput from '../../../component/SearchInput'
import Table from '../../../component/table'
import {
	ScheduleModel,
	fetchAllUserSchedules,
	fetchEmptySchedules,
} from '../../../service/schedule'
import { UserModel } from '../../../service/user'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from '../../../component/Modal'
import ScheduleDetails from '../../Schedules/scheduleDetails'
import dayjs from 'dayjs'
import FilterForm, { initialParams } from '../../../component/FilterForm'

export default function TimeSheetList() {
	const [id, setId] = useState()
	const [pageParams, setPageParams] = useState(initialParams)
	const [search, setSearch] = useState('')
	const searchInput = useDebounce(search, 500)
	const queryParams = useMemo(() => {
		let res = `page_number=${pageParams.page_number}&page_size=${pageParams.page_size}`
		if (searchInput) res += `&search=${searchInput}`
		if (pageParams.filter) res += `&filter=${pageParams.filter}`
		return res
	}, [pageParams, searchInput])
	const updatePageParams = (key: string, value: any) => {
		setPageParams((prev) => ({ ...prev, [key]: value }))
	}
	const { data: scheduleData, isLoading } = useQuery({
		queryKey: ['user_schedules', queryParams],
		queryFn: fetchAllUserSchedules,
		enabled: !pageParams.filter.includes('unassigned'),
	})
	const { data: emptyScheduleData } = useQuery({
		queryKey: ['empty_user_schedules', queryParams],
		queryFn: fetchEmptySchedules,
		enabled: pageParams.filter.includes('unassigned'),
	})
	const schedules = useMemo(() => {
		let result
		if (scheduleData?.rows && !pageParams.filter.includes('unassigned'))
			result = scheduleData
		if (emptyScheduleData?.rows && pageParams.filter.includes('unassigned'))
			result = emptyScheduleData
		return result
	}, [scheduleData, emptyScheduleData, pageParams.filter])
	const columns = [
		// { key: 'id', title: 'ID' },
		// { key: 'id', title: 'User ID' },
		{
			key: 'user',
			title: ' User',
			render: (value: UserModel) =>
				value ? value.firstname + ' ' + value.lastname : 'N/A',
		},
		{
			key: 'schedule',
			title: 'Date',
			render: (value: ScheduleModel) => value.date,
		},
		{
			key: 'clock_in',
			title: 'Time In',
			render: (value: any) => (value ? dayjs(value).format('MM:mm:ss') : 'N/A'),
		},
		{
			key: 'clock_out',
			title: 'Time Out',
			render: (value: any) => (value ? dayjs(value).format('MM:mm:ss') : 'N/A'),
		},
		{
			key: 'status',
			title: 'Status',
			render: (value: any, row: any) => value || row?.schedule.status,
		},
		{
			key: 'schedule',
			title: 'Service',
			render: (value: ScheduleModel) => value.service?.name,
		},
		{
			key: 'schedule',
			title: 'Location',
			render: (value: ScheduleModel) => value.location?.name,
		},
		{
			key: 'schedule_id',
			title: 'Action',
			render: (value: any) => (
				<div className={`flex gap-2`}>
					<FontAwesomeIcon onClick={() => setId(value)} icon={faEye} />
				</div>
			),
		},
	]
	return (
		<>
			<div className=' bg-white rounded-lg'>
				{/* <button onClick={() => setShowModal(true)}>Open Modal</button> */}
				<div className=' sm:flex p-4 justify-between'>
					<p className='text-[24px] font-medium'>Time Sheet</p>
					<div className='flex'>
						<button className='text-[#EC7211] px-4'>Select Display</button>
					</div>
				</div>
				<div className='sm:grid sm:grid-cols-8 gap-4 border-dashed border-t  p-4'>
					<FilterForm
						fields={['timesheet_status', 'type', 'country', 'assignment']}
						values={JSON.parse(pageParams.filter || '{}')}
						action={(val) => updatePageParams('filter', val)}
					/>
					<div className='col-span-7 '>
						<SearchInput
							placeholder='Search ...'
							value={search}
							onChange={(e: any) => setSearch(e.target.value)}
						/>
					</div>
				</div>
				{isLoading ? (
					<Loading />
				) : (
					<Table
						columns={columns}
						data={schedules?.rows}
						showHeaderIcon={undefined}
						hasCheckBox={true}
					/>
				)}

				<div
					className='flex overflow-x-auto sm:justify-end'
					id='pagination-globle-style'
				>
					<Pagination
						setPageSize={(val: number) => updatePageParams('page_size', val)}
						setPageNumber={(val: number) =>
							updatePageParams('page_number', val)
						}
						currentPage={pageParams.page_number}
						pageSize={pageParams.page_size}
						totalCount={schedules?.count || 0}
					/>
				</div>
			</div>
			<Modal
				showModal={!!id}
				setShowModal={setId}
				title='View Schedules'
				size={'lg'}
			>
				<ScheduleDetails id={id} setShow={setId} />
			</Modal>
		</>
	)
}
