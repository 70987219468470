import { useFormik } from 'formik'
import React from 'react'
import Button from '../../../component/Button'
import CardSchedule from '../../../component/CardSchedule'
import Input from '../../../component/Input'
import SelectInput from '../../../component/SelectInput'
import ReportTable from './ReportTable'

export default function PayrollReport() {
	const formik = useFormik({
		initialValues: {
			Generate: '',
			type: '',
			phone: '',
			email: '',
		},
		onSubmit: (values) => {},

		validationSchema: {},
	})

	return (
		<div>
			<div className='sm:grid grid-cols-3 gap-4  '>
				<div className=' bg-white rounded-lg p-5 col-span-2'>
					<p className='text-[14px] font-[400]'>Payroll Report</p>

					<form>
						<div className='sm:grid grid-cols-2 gap-4 mt-3 '>
							<div className=' col-span-2 '>
								<SelectInput
									formik={formik}
									label={'Team member'}
									id={'Generate'}
									name='Generate'
									onChange={() => {}}
									value={{}}
									options={[]}
									option={{ value: 'id', label: 'name' }}
									required={true}
								/>
							</div>

							{/* <Input
								required={true}
								label='Generate for'
								formik={formik}
								type='text'
								id='Generate'
								name='Generate'
								onChange={{}}
								value={''}
							/> */}

							<Input
								required={true}
								label='Date from'
								formik={formik}
								type='date'
								id='Generate'
								name='Generate'
								onChange={{}}
								value={{}}
							/>
							<Input
								required={true}
								formik={formik}
								label='Date to'
								type='date'
								id='Generate'
								name='Generate'
								onChange={{}}
								value={{}}
							/>
						</div>
						<div className='flex my-5'>
							<input
								type='checkbox'
								className='shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 '
								id='hs-default-checkbox'
							/>
							<label
								htmlFor='hs-default-checkbox'
								className='text-sm text-gray-500 ms-3 dark:text-gray-400'
							>
								hide team member with 0 hours
							</label>
						</div>
						<Button title='Filter' className={'px-20'} btnStyle='btn-primary' />
					</form>
				</div>
				<div className='flex flex-col gap-4'>
					<CardSchedule
						subTitle={'89 hours'}
						title={'Total billable hours'}
						color={'red'}
					/>
					<CardSchedule
						subTitle={'89 hours'}
						title={'Total billed hours '}
						color={'green'}
					/>
					<CardSchedule
						subTitle={'89 hours'}
						title={'Over time date'}
						color={'blue'}
					/>
				</div>
			</div>

			<ReportTable />
		</div>
	)
}
