import { useFormik } from 'formik'
import React from 'react'
import { useMutation } from '@tanstack/react-query'
import Button from '../../../component/Button'
import Input from '../../../component/Input'
import { create_permissions_url } from '../../../service/permissions'
import { update_role_api } from '../../../service/role'
import * as Yup from 'yup'

export default function CreateRole({
	showModal,
	setShowModal,
	initialValues,
	refetch,
}: any) {
	const mutation = useMutation({
		mutationFn: create_permissions_url,
		onSuccess: (res) => {
			setShowModal(!showModal)
			refetch()
		},
	})

	const updateService = useMutation({
		mutationFn: update_role_api,
		onSuccess: () => {
			setShowModal(false)
			refetch()
		},
	})

	const formik = useFormik({
		initialValues,
		validationSchema: Yup.object({
			name: Yup.string().required().label('Name'),
		}),
		onSubmit: (values) => {
			if (initialValues.id) {
				updateService.mutate(values)
			} else {
				mutation.mutate(values)
			}
		},
	})
	return (
		<div>
			<form onSubmit={formik.handleSubmit} className=''>
				<Input
					label='Role:'
					id='name'
					name='name'
					type='text'
					value={formik.values.name}
					onChange={formik.handleChange}
					formik={formik}
				/>

				{/* Add other Input components as needed */}
				<br />
				<div className='flex  gap-2 ...'>
					<Button
						title={'Cancel'}
						type='submit'
						onClick={() => setShowModal(false)}
						className={'flex-1'}
						btnStyle='btn-outline'
					/>
					<Button
						title={'Save'}
						type='submit'
						className={'flex-1'}
						btnStyle='btn-primary'
					/>
					<br />
				</div>
			</form>
		</div>
	)
}
