import React, { useMemo, useState } from 'react'
import Modal from '../../../component/Modal'

import Pagination from '../../../component/Pagination'
import { useMutation, useQuery } from '@tanstack/react-query'
import SearchInput from '../../../component/SearchInput'
import CreateRole from './createRole'
import { delete_role_api, role_api } from '../../../service/role'
import Loading from '../../../component/loading'
import Table from '../../../component/table'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from '@uidotdev/usehooks'
import {
	CreateButton,
	DeleteButton,
	EditButton,
	OtherButton,
} from '../../../component/Buttons'
import FilterForm, { initialParams } from '../../../component/FilterForm'
import CsvExportButton from '../../../component/CsvExportButton'

const init = { id: '', name: '' }

export default function Permissions() {
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)
	const [initialValues, setInitialValues] = useState(init)
	const [pageParams, setPageParams] = useState(initialParams)
	const [search, setSearch] = useState('')
	const searchInput = useDebounce(search, 500)
	const queryParams = useMemo(() => {
		let res = `page_number=${pageParams.page_number}&page_size=${pageParams.page_size}`
		if (searchInput) res += `&search=${searchInput}`
		if (pageParams.filter) res += `&filter=${pageParams.filter}`
		return res
	}, [pageParams, searchInput])
	const updatePageParams = (key: string, value: any) => {
		setPageParams((prev) => ({ ...prev, [key]: value }))
	}
	const { data, isLoading, refetch } = useQuery({
		queryKey: ['roles', queryParams],
		queryFn: role_api,
	})

	const deleteMutation = useMutation({
		mutationFn: delete_role_api,
		onSuccess: () => {
			refetch()
		},
	})

	const columns = [
		{ key: 'id', title: 'Role ID' },
		{ key: 'name', title: 'Name' },
		{
			key: 'status',
			title: 'Status',
			render: (value: any, row: any) => (
				<>
					<div
						className={`${
							value ? ' text-green-600 bg-green-100' : 'text-red-600 bg-red-100'
						} 
              text-[12px] text-center p-1 px-2 w-fit   rounded-full`}
					>
						{value ? 'Active' : 'Inactive'}
					</div>
				</>
			),
		},
		{
			key: 'id',
			title: 'Permissions',
			render: (value: any, row: any) =>
				process.env.REACT_APP_ADMIN?.includes(row.name) ? null : (
					<OtherButton
						title='Assign permission'
						id='permissions'
						onClick={() => navigate('/users/roles/permissions/' + value)}
						className='text-sm'
						action='read'
					/>
				),
		},
		{
			key: 'id',
			title: 'Action',
			render: (value: any, row: any) =>
				process.env.REACT_APP_ADMIN?.includes(row.name) ? null : (
					<>
						<EditButton
							onClick={() => {
								setInitialValues({ ...row, ...row.status.toString() })
								setShowModal(true)
							}}
							id='roles'
						/>
						<DeleteButton
							onClick={() => deleteMutation.mutate(value)}
							id='roles'
						/>
					</>
				),
		},
	]
	return (
		<div className='p-3 bg-white rounded-lg'>
			{/* <button onClick={() => setShowModal(true)}>Open Modal</button> */}
			<div className=' sm:flex sm:flex p-3 justify-between'>
				<p className='text-[24px] font-medium'>Role</p>
				<div className='flex'>
					<CreateButton
						title='Add Role'
						onClick={() => {
							setInitialValues(init)
							setShowModal(true)
						}}
						id='roles'
					/>
					<CsvExportButton
						data={data?.rows}
						headers={undefined}
						filename={'location'}
						buttonText={'Export as CSV'}
					/>
				</div>
			</div>
			<div className='sm:grid sm:grid-cols-8 gap-4 border-dashed border-t  p-3'>
				<FilterForm
					fields={['status']}
					values={JSON.parse(pageParams.filter || '{}')}
					action={(val) => updatePageParams('filter', val)}
				/>
				<div className='col-span-7 '>
					<SearchInput
						placeholder='Search ...'
						value={search}
						onChange={(e: any) => setSearch(e.target.value)}
					/>
				</div>
			</div>
			{isLoading ? (
				<Loading />
			) : (
				<div className=''>
					<Table
						columns={columns}
						data={data?.rows}
						showHeaderIcon={undefined}
						hasCheckBox={true}
					/>
				</div>
			)}

			<div
				className='flex overflow-x-auto sm:justify-end'
				id='pagination-globle-style'
			>
				<Pagination
					setPageSize={(val: number) => updatePageParams('page_size', val)}
					setPageNumber={(val: number) => updatePageParams('page_number', val)}
					currentPage={pageParams.page_number}
					pageSize={pageParams.page_size}
					totalCount={data?.count || 0}
				/>
			</div>

			<Modal
				showModal={showModal}
				setShowModal={setShowModal}
				title={`${initialValues.id ? 'Edit' : 'Add'} Role`}
				size={'md'}
			>
				<CreateRole
					showModal={showModal}
					refetch={refetch}
					initialValues={initialValues}
					setShowModal={setShowModal}
				/>
			</Modal>
		</div>
	)
}
