import React, { useState } from 'react'
import Button from '../../component/Button';
import { primary_bg, secondary_bg } from '../../themes/themes';

export default function Chat() {


    return (
        <>
            <div className='md:grid grid-cols-4 gap-4 bg-white md:h-5/6 x-di rounded'>

                <div className='col-span-1 divide-x divide-light-red-400 border-solid border-2 border-light-blue-100 '>
                    <div className='flex flex-1 gap-2 p-5'>
                        <Button className='flex-1' title='Chat' btnStyle='btn-primary' />
                        <Button className='flex-1' title='Contact' btnStyle='btn-outline' />
                    </div>
                    <div className='pt-2'>

                        <AvarterItem
                            timeStamp='12 mins ago'
                            subtitleActive={false} 
                            active={true}
                            title=' Mark Wanner is online'
                            subtitle='Reference site about Lore...' />

                        <AvarterItem
                            timeStamp='12 mins ago'
                            subtitleActive={false} 
                            active={false}
                            title=' Mark Wanner is online'
                            subtitle='Reference site about Lore...' />

                        <AvarterItem
                            timeStamp='12 mins ago'
                            subtitleActive={false} 
                            active={false}
                            title=' Mark Wanner is online'
                            subtitle='Reference site about Lore...' />
                    </div>
                </div>
                <div className='col-span-3 '>
                    <div className=''>

                        <AvarterItem
                            subtitleActive={true} 
                            title=' Mark Wanner is online'
                            subtitle='Reference site about Lore...' />
                    </div>

                    <ul className="m-3 space-y-5">

                        <MessageItem
                            timeStamp='21 min ago'
                            message='Hey Victor! Could you please give me the transaction'
                            userName='Davila Parnell'
                            sentBy='sender' />

                        <MessageItem
                            timeStamp='21 min ago'
                            message='Hey Victor! Could you please give me the transaction'
                            userName='Davila Parnell'
                            sentBy='me' />

                        <MessageItem
                            timeStamp='21 min ago'
                            message='Hey Victor! Could you please give me '
                            userName='Davila Parnell'
                            sentBy='me' />
                    </ul>

                    
                </div>
            </div>
        </>
    );
}


function AvarterItem({ timeStamp, title, subtitle, subtitleActive , active}: {
    timeStamp?: any,
    subtitleActive: any,
    title: any,
    active? : boolean;
    subtitle: any
}) {
    return (
        <div className={`ps-6 lg:ps-3 xl:ps-0  ${active && `bg-[${secondary_bg}]`} p-3`}>
            <div className="flex items-center gap-x-3">
                <div className="hs-tooltip inline-block md:ml-3">
                    <a className="hs-tooltip-toggle relative inline-block" href="#">
                        <img className="inline-block size-[46px] rounded-full" src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" alt="Image Description" />
                        <span className="absolute bottom-0 end-0 block size-3 rounded-full ring-2 ring-white bg-teal-400 dark:ring-neutral-900"></span>
                    </a>
                </div>

                <div className="grow">
                    <div className='flex justify-between content-center'>

                        <span className="block f text-gray-800 font-inter text-xs font-normal leading-4 tracking-tight text-left">
                            {title}
                        </span>

                        <span className='text-[10px]'>{timeStamp}</span>
                    </div>
                    <span className={`block font-inter text-xs ${subtitleActive && 'text-green-700'} font-medium leading-4 tracking-wider  text-left`}>
                        {subtitle}
                    </span>
                </div>
            </div>
        </div>
    )
}


function MessageItem({ timeStamp, userName,  sentBy, message }: {
    timeStamp: any,
    message: string,
    userName: string,
    sentBy: 'me' | 'sender'
}) {
    return (
        <>
            <li className={`flex gap-x-2 sm:gap-x-4 ${sentBy == 'me' && 'flex-row-reverse'} `}>
                <div className="hs-tooltip inline-block">
                    <a className="hs-tooltip-toggle relative inline-block" href="#">
                        <img className="inline-block size-[46px] rounded-full" src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" alt="Image Description" />

                    </a>
                </div>

                <div>
                    <div className='flex justify-between content-center'>

                        <span className="block font-semibold text-gray-800 font-inter text-s font-normal leading-4 tracking-tight text-left">
                           {userName}
                        </span>

                        <span className='text-[10px]'>{timeStamp}</span>
                    </div>
                    <div className={`${sentBy == 'sender' && `bg-[${secondary_bg}]`} ${sentBy == 'me' && `bg-[${primary_bg}] `}  
                              rounded-lg p-4 space-y-3 mt-2`}> 

                        <div className="space-y-1.5">
                            <p className={`mb-1.5 text-sm text-gray-800  ${sentBy == 'me' && `text-white `}   `}>
                                {message}
                            </p>

                        </div>
                    </div>
                </div>
            </li>

        </>
    )
}
