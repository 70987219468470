import React from 'react'

export default function Loading() {
	return (
		<div>
			<div className='flex items-center justify-center h-full'>
				<div
					className='animate-spin inline-block w-10 h-10 border-[3px] border-current border-t-transparent text-red-600 rounded-full '
					role='status'
					aria-label='loading'
				>
					<span className='sr-only'>Loading...</span>
				</div>
			</div>
		</div>
	)
}
export const Loading2 = () => {
	return (
		<svg
			className='animate-spin h-5 w-5 mx-auto text-white'
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			viewBox='0 0 24 24'
		>
			<circle
				className='opacity-25'
				cx='12'
				cy='12'
				r='10'
				stroke='currentColor'
				strokeWidth='4'
			></circle>
			<path
				className='opacity-75'
				fill='currentColor'
				d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM12 24c6.627 0 12-5.373 12-12h-4a8 8 0 01-8 8v4z'
			></path>
		</svg>
	)
}
