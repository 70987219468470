import React, { useEffect, useMemo, useState } from 'react'

import dayGridPlugin from '@fullcalendar/daygrid'

import timeGridPlugin from '@fullcalendar/timegrid'
import { useQuery, useMutation } from '@tanstack/react-query'
import {
	createUserSchedule,
	deleteUserSchedule,
	fetchAllUserSchedules,
	fetchEmptySchedules,
	updateSchedule,
	updateUserSchedule,
} from '../../service/schedule'
import { users_api } from '../../service/user'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { location_api } from '../../service/location'
import FullCalendar from '@fullcalendar/react'
import { EventDropArg, EventInput } from '@fullcalendar/core'
import { convertDateFormat } from '../../utility/timeConvertor'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'

import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'

import listPlugin from '@fullcalendar/list'
import dayjs from 'dayjs'
import { getColorWithOpacity } from '../../utility/generateColorWithOpacity'
import { primary_bg } from '../../themes/themes'
import { checkPermission } from '../../component/permissions'
import { useAuth } from '../../context/AuthContext'

export default function ScheduleCalender({
	setId,
	setShow,
	setDefaultData,
	setData,
}: any) {
	const { currentUser } = useAuth()
	const [filter, setFilter] = useState('')
	const isAdmin =
		process.env.REACT_APP_ADMIN?.includes(currentUser.role?.name) ||
		currentUser.permissions.some(
			(p: any) => p.model_name === 'schedules' && p.read_all === true
		)
	const [resourceView, setResourceView] = useState('locationResource')
	const [viewState, setViewState] = useState<string>()
	const { data: userData } = useQuery({
		queryKey: ['users_api_call', 'filter={"status":true}'],
		queryFn: users_api,
	})
	const { data: locationData } = useQuery({
		queryKey: ['location_api', 'filter={"status":true}'],
		queryFn: location_api,
	})
	const { data: scheduleData } = useQuery({
		queryKey: ['user_schedules', filter],
		queryFn: fetchAllUserSchedules,
		enabled: !!filter,
	})

	const { data: emptyScheduleData } = useQuery({
		queryKey: ['empty_user_schedules', filter],
		queryFn: fetchEmptySchedules,
		enabled: !!filter,
	})

	const schedules = useMemo(() => {
		let result: any = []
		if (scheduleData?.rows) result.push(...scheduleData?.rows)
		if (emptyScheduleData?.rows) result.push(...emptyScheduleData?.rows)
		return result
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scheduleData, emptyScheduleData, setData])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setData(schedules), [schedules])

	let locationResource = useMemo(
		() =>
			locationData?.rows?.map((loc: any) => {
				return {
					id: loc.id,
					title: loc.name,
					view: 'location',
				}
			}),
		[locationData]
	)
	let staffResource = useMemo(() => {
		let result: any = [
			{
				id: null,
				title: 'Unassigned',
				view: 'staff_name',
				firstname: 'Unassigned',
				lastname: '',
				resource_type: 'staff',
			},
		]
		userData?.rows?.forEach((staff: any) => {
			result.push({
				id: staff.id,
				title: staff.firstname + ' ' + staff.lastname,
				view: 'staff_name',
				firstname: staff.firstname,
				lastname: staff.lastname,
				resource_type: 'staff',
				// eventColor: getRandomColor(),
			})
		})
		return result
	}, [userData])

	const events: EventInput[] = useMemo(
		() =>
			schedules.map((scheduleRes: any, i: number) => {
				return {
					resourceId:
						resourceView === 'locationResource'
							? scheduleRes.schedule.location_id
							: scheduleRes.staff_id || null,
					title: scheduleRes?.schedule?.service?.name,
					location: scheduleRes.schedule.location.name,
					service: scheduleRes.schedule.service.name,
					id: i,
					scheduleID: scheduleRes?.schedule?.id,
					staff: scheduleRes.user
						? scheduleRes.user?.firstname + ' ' + scheduleRes.user?.lastname
						: 'Unassigned',
					start: convertDateFormat(scheduleRes?.schedule?.start_time),
					end: convertDateFormat(scheduleRes?.schedule?.end_time),
					scheduleEnd: scheduleRes.schedule.end_time,
					editable: true,
					allDay: false,
					resColor: scheduleRes?.schedule?.color,
					duration: scheduleRes.schedule.duration,
					backgroundColor: 'white',
					borderColor: 'white',
					description: scheduleRes.schedule.description,
					aspectRatio: 1.5,
					textColor: 'black',
					extendedProps: {
						description: scheduleRes?.schedule?.service?.name,
						staff_id: scheduleRes.staff_id,
						locationId: scheduleRes.schedule.location_id,
					},
					classNames: ['important-event', 'event', 'h-fit'],
				}
			}),
		[resourceView, schedules]
	)

	function renderEventContent(eventInfo: any) {
		return viewState === 'dayGridMonth' ? (
			<div
				className='flex rounded text-white truncate'
				style={{
					backgroundColor:
						eventInfo?.event?.extendedProps?.resColor || primary_bg,
				}}
			>
				<b>
					{dayjs(eventInfo?.event?.start).format('HH:mm')}
					{' - '}
					{dayjs(eventInfo?.event?.end).format('HH:mm')}
				</b>{' '}
				&nbsp;
				{eventInfo?.event?.extendedProps?.staff},{' '}
				{eventInfo?.event?.extendedProps?.location}
			</div>
		) : (
			<div
				style={{
					borderRight: 'solid',
					borderColor: eventInfo?.event?.extendedProps?.resColor || primary_bg,
					backgroundColor: getColorWithOpacity(
						eventInfo?.event?.extendedProps?.resColor || primary_bg,
						0.3
					),
				}}
				className={` border-l-4 border-[${eventInfo?.event?.extendedProps?.resColor}] 
      flex-wrap
      flex justify-between border-  rounded`}
			>
				{/* &nbsp; */}
				<div
					className={`text-[${eventInfo?.event?.extendedProps?.resColor}] truncate`}
				>
					<div className='truncate'>
						{dayjs(eventInfo?.event?.start).format('HH:mm')}
						{' - '}
						{dayjs(eventInfo?.event?.end).format('HH:mm')}{' '}
						<span className='font-bold pt-0 first:pt-0 '>
							{eventInfo?.event?.title}
						</span>
					</div>
					{resourceView !== 'locationResource'
						? eventInfo?.event?.extendedProps?.location
						: eventInfo?.event?.extendedProps?.staff}
				</div>
			</div>
		)
	}

	const handleEventClick = (info: any) => {
		setId(info.event.extendedProps.scheduleID)
		setShow('view')
	}

	const handleDateClick = (selectInfo: DateClickArg) => {
		if (checkPermission(currentUser, 'schedules', 'create')) {
			const data: any = {}
			data.date = dayjs(selectInfo.date).format('YYYY-MM-DD')
			data.start_time = dayjs(selectInfo.date).format('HH:mm')
			if (selectInfo.resource?.id) {
				if (resourceView === 'staffResource') {
					if (selectInfo.resource?.id !== 'null')
						data.user_schedules = [
							{
								value: selectInfo.resource?.id,
								label: selectInfo.resource?.title,
							},
						]
				}
				if (resourceView === 'locationResource')
					data.location_id = selectInfo.resource?.id
			}
			setId(null)
			setDefaultData(data)
			setShow('create')
		}
	}

	const mutationUpdateUserSchedule = useMutation({
		mutationFn: updateUserSchedule,
	})
	const mutationUpdateSchedule = useMutation({
		mutationFn: updateSchedule,
	})

	const handleEventDrop = async (info: EventDropArg) => {
		const { event, newResource, oldEvent, oldResource } = info
		const id = event.extendedProps.scheduleID
		const updatedItem: any = {
			id,
		}
		if (oldEvent?.startStr !== event.startStr) {
			updatedItem.date = dayjs(event.start).format('YYYY-MM-DD')
			updatedItem.start_time = dayjs(event.start).format('HH:mm')
		}
		if (newResource) {
			if (newResource.extendedProps.view === 'location')
				updatedItem.location_id = newResource.id
			else if (newResource.extendedProps.view === 'staff_name') {
				if (newResource.id === 'null') deleteUserSchedule(id, oldResource!.id)
				else if (oldResource?.id === 'null')
					createUserSchedule({ schedule_id: id, staff_id: newResource.id })
				else
					mutationUpdateUserSchedule.mutate({
						id: id,
						staff_id: newResource.id,
						old_staff_id: oldResource?.id,
					})
			}
		}
		if (updatedItem.location_id || updatedItem.date)
			mutationUpdateSchedule.mutate(updatedItem)
	}
	return (
		<div className='flex'>
			<div className='flex-1 bg-white p-5  shadow overscroll-auto md:overscroll-contain  rounded-lg'>
				<FullCalendar
					schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
					plugins={[
						dayGridPlugin,
						interactionPlugin,
						resourceTimelinePlugin,
						resourceTimeGridPlugin,
						listPlugin,
						timeGridPlugin,
					]}
					initialView={isAdmin ? 'resourceTimelineWeek' : 'dayGridWeek'}
					headerToolbar={{
						left: 'prev,next,today',
						center: 'title',
						right: isAdmin
							? 'resourceTimelineDay,resourceTimelineWeek,dayGridMonth'
							: 'timeGridDay,dayGridWeek,dayGridMonth',
					}}
					dayHeaderFormat={
						['dayGridWeek', 'resourceTimelineWeek'].includes(viewState!)
							? { weekday: 'short', day: 'numeric' }
							: { weekday: 'long' }
					}
					events={events}
					eventClick={handleEventClick}
					dateClick={handleDateClick}
					eventContent={renderEventContent}
					selectMirror={true}
					dayMaxEvents={true}
					firstDay={1}
					longPressDelay={1}
					nowIndicator={true}
					editable={true}
					droppable={true}
					eventDrop={handleEventDrop}
					datesSet={(dateInfo) => {
						setViewState(dateInfo.view.type)
						setFilter(
							`filter={"start_date":"${dayjs(dateInfo.startStr).format(
								'YYYY-MM-DD'
							)}","end_date":"${dayjs(dateInfo.endStr).format('YYYY-MM-DD')}"}`
						)
					}}
					//resource
					resourceAreaHeaderContent={({ resource }: any) => (
						<div>
							<p className='text-left font-[400] my-1 text-[12px]'>
								View by{' '}
								{resourceView === 'locationResource' ? 'location' : 'staff'}
							</p>
							<select
								onChange={(e) => setResourceView(e.target.value)}
								className=' text-[13px] block sm:w-[180px] border-gray-400 rounded text-black font-light
                 focus:border-blue-500
                focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none '
							>
								<option disabled></option>
								<option value='locationResource'>Location</option>
								<option value='staffResource'>Staffs</option>
							</select>
						</div>
					)}
					resourceAreaWidth='15%'
					resources={
						resourceView === 'locationResource'
							? locationResource
							: staffResource
					}
					resourceOrder={['title']}
					slotLabelContent={(arg) => <span className=''>{arg.text}</span>}
					resourceLabelContent={(info: any) => (
						<div className='flex items-center'>
							{info?.resource?.extendedProps.resource_type && (
								<span
									className='inline-flex items-center justify-center size-[36px] 
               rounded-full bg-[#EC7211] mr-2 font-semibold text-white leading-none '
								>
									{info?.resource?.extendedProps?.firstname[0]}
									{info?.resource?.extendedProps?.lastname[0]}
								</span>
							)}

							<p className={'text-[15px] font-[400] truncate'}>
								{info.resource.title}
							</p>
							<p className='text-[10px] texxt-[#7C7F80] flex-1 text-right '>
								12:00am
							</p>
						</div>
					)}
					slotLabelFormat={
						['resourceTimelineDay', 'timeGridDay'].includes(viewState!)
							? {
									hour: 'numeric',
									minute: '2-digit',
									omitZeroMinute: true,
									meridiem: 'short',
							  }
							: [
									{ weekday: 'short', day: 'numeric' }, // lower level of text
							  ]
					}
					slotDuration={
						['resourceTimelineDay', 'timeGridDay'].includes(viewState!)
							? { minutes: 30 }
							: { days: 1 }
					}
				/>
			</div>
		</div>
	)
}
