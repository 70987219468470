import * as Yup from 'yup'

export const locationValidation = Yup.object({
	email: Yup.string()
		.email('Invalid email address')
		.required('Email is required'),
	name: Yup.string().required('Name is required'),
	type: Yup.string().required('type is required'),
	country: Yup.string().required('country is required'),
	city: Yup.string().required('City is required'),
	state: Yup.string().required('State is required'),
	address: Yup.string().required('Address is required'),
	phone: Yup.string(),
})
