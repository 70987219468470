import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDebounce } from '@uidotdev/usehooks'
import React, { useMemo, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import Modal from '../../component/Modal'
import Pagination from '../../component/Pagination'
import SearchInput from '../../component/SearchInput'
import Table from '../../component/table'
import { delete_location_api, location_api } from '../../service/location'
import CreateLocation from './form'
import ViewLocation from './ViewLocation'
import { LocationModel } from '../../service/location'
import { CreateButton, DeleteButton, EditButton } from '../../component/Buttons'
import FilterForm, { initialParams } from '../../component/FilterForm'
import CsvExportButton from '../../component/CsvExportButton'

export default function Location() {
	const [showModal, setShowModal] = useState<'create' | 'edit' | 'view'>()
	const [selectedData, setSelectedData] = useState<LocationModel>()
	const [pageParams, setPageParams] = useState(initialParams)
	const [search, setSearch] = useState('')
	const searchInput = useDebounce(search, 500)
	const queryParams = useMemo(() => {
		let res = `page_number=${pageParams.page_number}&page_size=${pageParams.page_size}&include=File`
		if (searchInput) res += `&search=${searchInput}`
		if (pageParams.filter) res += `&filter=${pageParams.filter}`
		return res
	}, [pageParams, searchInput])
	const updatePageParams = (key: string, value: any) => {
		setPageParams((prev) => ({ ...prev, [key]: value }))
	}

	const { data, isLoading, refetch } = useQuery({
		queryKey: ['locations', queryParams],
		queryFn: location_api,
	})

	const deleteMutation = useMutation({
		mutationFn: delete_location_api,
		onSuccess: () => {
			refetch()
		},
	})

	const columns = [
		{ key: 'name', title: 'name' },
		{ key: 'phone', title: 'phone' },
		{ key: 'email', title: 'email' },
		{ key: 'country', title: 'country' },
		{ key: 'state', title: 'province/state' },
		{ key: 'city', title: 'city' },
		{ key: 'zip_code', title: 'zip code' },
		{
			key: 'status',
			title: 'status',
			render: (value: any, row: any) => (
				<div
					className={`${
						value ? ' text-green-600 bg-green-100' : 'text-red-600 bg-red-100'
					} 
                      text-[12px] text-center px-2 py-1 w-fit   rounded-full`}
				>
					{value ? 'Active' : 'Inactive'}
				</div>
			),
		},
		{
			key: 'id',
			title: 'Action',
			render: (value: any, row: LocationModel) => (
				<div className={`flex gap-2`} key={row.id}>
					<FontAwesomeIcon
						onClick={() => {
							setSelectedData(row)
							setShowModal('view')
						}}
						icon={faEye}
					/>
					<EditButton
						onClick={() => {
							setSelectedData(row)
							setShowModal('edit')
						}}
						id='locations'
					/>
					<DeleteButton
						onClick={() => deleteMutation.mutate(value)}
						id='locations'
					/>
				</div>
			),
		},
	]
	return (
		<div className=' bg-white rounded-lg'>
			{/* <button onClick={() => setShowModal(true)}>Open Modal</button> */}
			<div className=' sm:flex p-4 justify-between'>
				<p className='text-[24px] font-medium'>Location</p>

				<div className='flex'>
					<CreateButton
						title='Add Location'
						onClick={() => {
							setSelectedData(undefined)
							setShowModal('create')
						}}
						id='locations'
					/>
					<CsvExportButton
						data={data?.rows}
						headers={undefined}
						filename={'location'}
						buttonText={'Export as CSV'}
					/>
				</div>
			</div>
			<div className='sm:grid sm:grid-cols-8 gap-4 border-dashed border-t  p-3'>
				<FilterForm
					fields={['status', 'type', 'country']}
					values={JSON.parse(pageParams.filter || '{}')}
					action={(val) => updatePageParams('filter', val)}
				/>
				<div className='col-span-7 '>
					<SearchInput
						placeholder='Search ...'
						value={search}
						onChange={(e: any) => setSearch(e.target.value)}
					/>
				</div>
			</div>
			<div>
				<Table
					columns={columns}
					data={data?.rows}
					showHeaderIcon={undefined}
					hasCheckBox={true}
					isLoading={isLoading}
				/>
			</div>
			<div
				className='flex overflow-x-auto sm:justify-end'
				id='pagination-globle-style'
			>
				<Pagination
					setPageSize={(val: number) => updatePageParams('page_size', val)}
					setPageNumber={(val: number) => updatePageParams('page_number', val)}
					currentPage={pageParams.page_number}
					pageSize={pageParams.page_size}
					totalCount={data?.count || 0}
				/>
			</div>

			<Modal
				showModal={showModal === 'view'}
				setShowModal={setShowModal}
				title='View Location'
				size={'md'}
			>
				<ViewLocation
					selectedData={selectedData!}
					setShowModal={setShowModal}
				/>
			</Modal>
			<Modal
				showModal={['create', 'edit'].includes(showModal || '')}
				setShowModal={setShowModal}
				title={`${selectedData?.id ? 'Edit' : 'Create'} location`}
				size={'lg'}
			>
				<CreateLocation
					refetch={refetch}
					selectedData={selectedData}
					setShowModal={setShowModal}
				/>
			</Modal>
		</div>
	)
}
