import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import React, { useEffect, useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../../../component/Breadcrumb'
import Button from '../../../component/Button'
import Input from '../../../component/Input'
import SelectInput from '../../../component/SelectInput'
import Textarea from '../../../component/textarea'
import {
	create_invoice_api,
	delete_invoiceitem_api,
	InvoiceItemModel,
	one_invoice_api,
	update_invoice_api,
} from '../../../service/Invoice'
import { formValidation } from './formValidation'
import SwitchToggleInput from '../../../component/SwitchToggleInput'
// import RadioInput from '../../../component/RadioInput'
import { location_api } from '../../../service/location'
import {
	UserScheduleModel,
	fetchAllUserSchedules,
} from '../../../service/schedule'
import { settings_api } from '../../../service/settings'
import dayjs from 'dayjs'
import { compareObject } from '../../../component/functions'

export default function InvoiceForm() {
	const { id } = useParams()
	const navigate = useNavigate()
	const { data: locationData } = useQuery({
		queryKey: ['locations', 'filter={"status":true}'],
		queryFn: location_api,
	})
	const { data: settings } = useQuery({
		queryKey: ['settings'],
		queryFn: settings_api,
	})
	const { data } = useQuery({
		queryKey: ['invoice', id!],
		queryFn: one_invoice_api,
		enabled: !!id,
	})
	const initialValues = useMemo(() => {
		const res = data
			? {
					...data,
					invoice_items: data.invoice_items?.map((item: InvoiceItemModel) => ({
						...item,
						duration_hr: item.duration_min / 60,
						// ? `${Math.floor(item.duration_min / 60)}h ${
						// 		item.duration_min % 60
						//   }m`
						// : '',
					})),
					somes: [{ charge: 10 }],
			  }
			: {
					id: '',
					location_id: '',
					start_date: '',
					end_date: '',
					invoice_date: dayjs().format('YYYY-MM-DD'),
					due_date: settings?.invoice_default_due_days
						? dayjs()
								.add(Number(settings?.invoice_default_due_days), 'day')
								.format('YYYY-MM-DD')
						: '',
					currency: settings?.currency || 'USD',
					discount: 0,
					tax: 0,
					tax_rate: settings?.tax_rate || 0,
					note: '',
					status: true,
					payment_status: false,
					invoice_items: [].map((item: InvoiceItemModel) => ({
						...item,
						duration_hr: item.duration_min / 60,
						// ? `${Math.floor(item.duration_min / 60)}h ${
						// 		item.duration_min % 60
						//   }m`
						// : '',
					})),
			  }
		return res
	}, [data, settings])
	const create = useMutation({
		mutationFn: id ? update_invoice_api : create_invoice_api,
		onSuccess: (a, b) => {
			if (b.preview) navigate(`/finances/invoices/preview/${id || a.id}`)
			else navigate(-1)
		},
	})
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit: (values: any) => {
			let v = { ...values }
			if (values.id) {
				v = compareObject(values, initialValues)
				v.id = values.id
			}
			create.mutate(v)
		},
		validationSchema: formValidation,
	})
	const { data: scheduleData, isFetched } = useQuery({
		queryKey: [
			'schedules',
			`filter={"status":"completed","invoice_id":null,"location_id":"${formik.values.location_id}","start_date":"${formik.values.start_date}","end_date":"${formik.values.end_date}"}`,
		],
		queryFn: fetchAllUserSchedules,
		enabled:
			!!formik.values.start_date &&
			!!formik.values.end_date &&
			!!formik.values.location_id &&
			!id,
	})
	useEffect(() => {
		if (!id && isFetched)
			formik.setFieldValue(
				'invoice_items',
				scheduleData?.rows.map((item: UserScheduleModel) => ({
					schedule_id: item.schedule_id,
					staff_id: item.staff_id,
					duration_min: item.schedule?.duration,
					duration_hr: item.schedule?.duration / 60,
					// ? `${Math.floor(item.schedule?.duration / 60)}h ${
					// 		item.schedule?.duration % 60
					//   }m`
					// : '',
					charge:
						item.schedule?.service?.charge * (item.schedule?.duration / 60),
					service: item.schedule?.service.name,
					staff: item.user?.firstname + ' ' + item.user?.lastname,
				}))
			)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scheduleData?.rows])

	const breadcrumbItems = [
		{ label: 'Finance', url: '/Finance' },
		{ label: 'Add Invoice', url: '' },
	]

	const handleAddUser = () => {
		formik.setFieldValue('invoice_items', [...formik.values.invoice_items, {}])
	}
	const handleRemoveUser = (index: number, invoice_item_id?: string) => {
		const updatedUsers = [...formik.values.invoice_items]
		updatedUsers.splice(index, 1)
		formik.setFieldValue('invoice_items', updatedUsers)
		if (invoice_item_id) delete_invoiceitem_api(invoice_item_id)
	}
	const charges = useMemo(() => {
		const result = {
			subtotal: 0,
			tax: formik.values.tax_rate || 0,
			discount: formik.values.discount || 0,
			total: 0,
		}
		result['subtotal'] =
			formik.values.invoice_items?.reduce(
				(acc: any, item: any) => acc + Number(item.charge),
				0
			) || 0
		result['tax'] = result.subtotal * (result.tax / 100)
		result.total = result.subtotal + result.tax - result.discount
		return result
	}, [formik.values])
	return (
		<div>
			<div className='ml-3 sm:mb-6'>
				<Breadcrumb items={breadcrumbItems} />
			</div>
			<div className=''>
				<form
					className='sm:grid grid-cols-3 gap-4  '
					onSubmit={formik.handleSubmit}
				>
					<div className='w-full col-span-2'>
						<div className='w-full mb-3 px-5 py-2 rounded-xl  bg-white'>
							<p className='my-3'>Invoice Number: #{formik.values.id}</p>
							<div className='grid grid-cols-1 md:grid-cols-2 my-3 gap-4 my-3 '>
								<Input
									required={true}
									label='Date created'
									formik={formik}
									type='date'
									id='invoice_date'
									name='invoice_date'
									onChange={formik.handleChange}
									value={formik.values.invoice_date}
								/>
								<Input
									required={true}
									formik={formik}
									label='Date due'
									type='date'
									id='due_date'
									name='due_date'
									onChange={formik.handleChange}
									value={formik.values.due_date}
								/>
							</div>
						</div>

						<div className='w-full mb-3 p-5 rounded-xl  bg-white'>
							<SelectInput
								formik={formik}
								label={'Bill to'}
								id='location_id'
								name='location_id'
								onChange={formik.handleChange}
								value={formik?.values?.location_id}
								options={locationData?.rows}
								option={{ value: 'id', label: 'name' }}
								required={true}
								disabled={!!formik.values.id}
							/>
						</div>
						<div className='w-full mb-3  rounded-xl  bg-white pb-2'>
							<div className='relative overflow-x-auto'>
								<table className='w-full text-sm text-left rtl:text-right text-gray-500 '>
									<thead className='text-xs text-gray-700 uppercase bg-[#FFF4EC]'>
										<tr>
											<th scope='col' className=' py-3 px-2 pl-5 '>
												Service
											</th>
											<th scope='col' className=' py-3 px-2'>
												Staff
											</th>
											<th scope='col' className='py-3 px-2'>
												Duration/hr
											</th>
											<th scope='col' className=' py-3 px-2'>
												Total Charge
											</th>
											<th scope='col' className='py-3 px-3 pr-5 '>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{formik.values.invoice_items?.map(
											(invoice_item: any, index: any) => (
												<tr className='bg-white border-b  ' key={index}>
													<td
														// scope='row'
														className='py-4 px-2 pl-5 whitespace-nowrap '
													>
														<Input
															required={true}
															formik={formik}
															label='Service'
															id={`invoice_items[${index}].service`}
															name={`invoice_items[${index}].service`}
															onChange={formik.handleChange}
															value={invoice_item.service}
														/>
													</td>
													<td className=' py-4 px-2'>
														<Input
															type='text'
															label='Staff'
															required={true}
															formik={formik}
															id={`invoice_items[${index}].staff`}
															name={`invoice_items[${index}].staff`}
															onChange={formik.handleChange}
															value={invoice_item.staff}
														/>
													</td>
													<td className=' py-4 px-2'>
														<Input
															type='number'
															label='Duration/hr'
															required={true}
															formik={formik}
															id={`invoice_items[${index}].duration_hr`}
															name={`invoice_items[${index}].duration_hr`}
															onChange={(e: any) =>
																formik.setFieldValue(
																	`invoice_items[${index}].duration_min`,
																	e.target.value * 60
																)
															}
															value={invoice_item.duration_hr}
														/>
													</td>
													<td className='py-4 px-2'>
														<Input
															type='number'
															label='Total Charge'
															required={true}
															formik={formik}
															id={`invoice_items[${index}].charge`}
															name={`invoice_items[${index}].charge`}
															onChange={formik.handleChange}
															value={invoice_item.charge}
														/>
													</td>
													<td className=' py-4 px-2  pl-5 '>
														<div>
															<button
																type='button'
																onClick={() =>
																	handleRemoveUser(index, invoice_item.id)
																}
															>
																<FontAwesomeIcon
																	className='text-[#F44336] m-1 p-3'
																	icon={faTrash}
																/>
															</button>
														</div>
													</td>
												</tr>
											)
										)}
									</tbody>
								</table>
								<button
									type='button'
									className='text-[#F44336] m-3 ml-5 p-1'
									onClick={handleAddUser}
								>
									Add item +
								</button>
							</div>
							{/* cc */}
							<div className='mx-5'>
								<Textarea
									label='Note'
									type='text'
									id='note'
									name='note'
									onChange={formik.handleChange}
									value={formik.values.note}
								/>
							</div>
							<div className='flex  flex-row-reverse gap-2 m-3 '>
								<Button
									onClick={() => navigate(-1)}
									type='reset'
									btnStyle='btn-outline'
									title='Cancel'
								/>
								<Button
									disabled={!formik.isValid}
									loading={create.isPending}
									onClick={() => {
										formik.setFieldValue('preview', true)
										formik.handleSubmit()
									}}
									type='button'
									btnStyle='btn-blue'
									title='Preview'
								/>
								<Button
									disabled={!formik.isValid}
									loading={create.isPending}
									type='submit'
									btnStyle='btn-primary'
									title='Save'
								/>
							</div>
						</div>

						{/* Add other Input components as needed */}
					</div>
					<div>
						<div className='w-full mb-3 p-5 rounded-xl flex flex-col gap-2 bg-white'>
							<SelectInput
								formik={formik}
								label={'Currency'}
								id='currency'
								name='currency'
								onChange={formik.handleChange}
								value={formik?.values?.currency}
								options={Intl.supportedValuesOf('currency').map((currency) => ({
									id: currency,
									name: currency,
								}))}
								option={{ value: 'id', label: 'name' }}
								required={true}
							/>
							<div className='grid grid-cols-1 md:grid-cols-2 my-3 gap-4 my-3 '>
								<Input
									required={true}
									label='Interval from'
									formik={formik}
									type='date'
									id='start_date'
									name='start_date'
									onChange={formik.handleChange}
									value={formik.values.start_date}
								/>
								<Input
									required={true}
									formik={formik}
									label='Interval to'
									type='date'
									id='end_date'
									name='end_date'
									onChange={formik.handleChange}
									value={formik.values.end_date}
								/>
							</div>

							<SwitchToggleInput
								label='Payment status'
								name='payment_status'
								value={formik.values.payment_status}
								onChange={formik.handleChange}
								disabled={!formik.values.payment_status}
							/>
							<SwitchToggleInput
								label='Status'
								name='status'
								value={formik.values.status}
								onChange={formik.handleChange}
							/>
							{/* <div>
								<label className={`block flex text-sm  text-[#7C7F80] mb-1`}>
									Status
								</label>
								<div
									className={
										'flex align-center align-items-center text-sm  text-[#7C7F80]  gap-2'
									}
								>
									<RadioInput
										onChange={formik.handleChange}
										value={formik.values.status}
									/>
									Active
									<RadioInput
										onChange={formik.handleChange}
										value={formik.values.status}
									/>
									Inactive
								</div>
							</div> */}
						</div>

						<div className='w-full mb-3 p-5 rounded-xl    text-[#7C7F80] bg-white'>
							<div className='flex items-center mt-2'>
								<p className='text-[14px] flex-1'>Subtotal</p>
								<div className='px-5 py-2 bg-[#F5F5F5] h-[38px] flex-1'>
									{charges.subtotal.toLocaleString('en-US', {
										style: 'currency',
										currency: formik.values.currency,
									})}
								</div>
							</div>

							<div className='flex items-center mt-2'>
								<p className='text-[14px] flex-1'>
									{settings?.tax_name || 'Tax'}({settings?.tax_rate || '0'})%
								</p>
								<div className='px-5 py-2 bg-[#F5F5F5] h-[38px] flex-1'>
									{charges.tax.toLocaleString('en-US', {
										style: 'currency',
										currency: formik.values.currency,
									})}
								</div>
							</div>

							<div className='flex items-center mt-2 bor'>
								<p className='text-[14px] flex-1'>Discount</p>
								<Input
									// required={true}
									formik={formik}
									// label='Interval to'
									type='number'
									id='discount'
									name='discount'
									onChange={formik.handleChange}
									value={formik.values.discount}
								/>
								{/* <p className='px-5 py-2 bg-[#F5F5F5] h-[38px] flex-1'>
									{charges.discount.toLocaleString('en-US', {
										style: 'currency',
										currency: formik.values.currency,
									})}
								</p> */}
							</div>

							<div className='  border-t  flex justify-between border-gray-200 pt-3 mt-3 text-sm font-medium text-gray-800 '>
								<p>Total</p>
								<p>
									{charges.total.toLocaleString('en-US', {
										style: 'currency',
										currency: formik.values.currency,
									})}
								</p>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}
