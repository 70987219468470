// SelectInput.jsx
import { FieldInputProps, FieldMetaProps } from 'formik'
import React from 'react'
import ReactSelect, { GroupBase, OptionsOrGroups } from 'react-select'
import CreatableSelect from 'react-select/creatable'

const SelectInput = ({
	label,
	id,
	name,
	value,
	onChange,
	onBlur,
	options,
	option,
	className,
	required,
	inputStyle,
	formik,
	optionListType,
	disabled,
}: {
	label: any
	id: any
	name: any
	value: any
	onChange: any
	onBlur?: any
	required: boolean
	options: any
	option?: { value: any; label: any } | any
	className?: any
	inputStyle?: any
	formik?: any
	optionListType?: any
	disabled?: any
}) => {
	const myStyles = {
		/* body 1 */
		// fontFamily: 'Inter',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '24px' /* 150% */,
		letterSpacing: '0.5px',
	}

	return (
		<div className={className}>
			{label && (
				<label htmlFor={id} className='block text-sm mb-1 flex text-[#7C7F80]'>
					{label} {required && <div className='text-[#F44336]'>*</div>}
				</label>
			)}
			<select
				disabled={disabled}
				style={myStyles}
				id={id}
				name={name}
				onChange={onChange}
				onBlur={onBlur}
				value={value}
				className={`${inputStyle} p-2 border border-gray-200 rounded-md w-full  
         ${disabled ? 'bg-[#EEEEEE]' : 'bg-transparent'}
          ${
						formik?.touched[name] && formik.errors[name]
							? 'border-red-500'
							: 'border-gray-300'
					} rounded-md`}
			>
				<option value=''></option>
				{/* <option selected disabled>Open this select item</option> */}
				{optionListType === 'arrary'
					? options?.map((dataOption: any) => (
							<option key={dataOption} value={dataOption}>
								{dataOption}
							</option>
					  ))
					: options?.map((dataOption: any) => (
							<option
								key={dataOption[option.value]}
								value={dataOption[option.value]}
							>
								{dataOption[option.label]}
							</option>
					  ))}
			</select>
			{formik?.touched[name] && formik.errors[name] && (
				<div className='text-red-500 text-sm'>{formik.errors[name]}</div>
			)}
		</div>
	)
}
export default SelectInput

type InputProps = FieldInputProps<string> & {
	meta?: FieldMetaProps<string>
	label: string
	required?: boolean
	disabled?: boolean
	options?: { value: any; label: any }[]
}
export const SelectInput2 = ({
	label,
	options,
	meta,
	...props
}: InputProps) => {
	console.log(meta)
	console.log(props)
	const myStyles = {
		/* body 1 */
		// fontFamily: 'Inter',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '24px' /* 150% */,
		letterSpacing: '0.5px',
	}
	return (
		<>
			<div>
				<label
					htmlFor={props.name}
					className='block text-sm mb-1 flex text-[#7C7F80]'
				>
					{label} {props.required && <div className='text-[#F44336]'>*</div>}
				</label>
				<select
					{...props}
					style={myStyles}
					className={`p-2 border border-gray-200 rounded-md w-full  
         ${props.disabled ? 'bg-[#EEEEEE]' : 'bg-transparent'}
          ${
						meta?.touched && meta?.error ? 'border-red-500' : 'border-gray-300'
					} rounded-md`}
				>
					<option value=''>-- {label} --</option>
					{options?.map((option: any) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
				{meta?.touched && meta?.error && (
					<div className='text-red-500 text-sm'>{meta?.error}</div>
				)}
			</div>
		</>
	)
}

type InputProps2 = {
	meta: FieldMetaProps<string>
	label: string
	onChange: any
	required?: boolean
	disabled?: boolean
	isMulti?: boolean
	options?: OptionsOrGroups<any, GroupBase<any>>
	name?: string
	isLoading?: boolean
	allowCreate?: boolean
	validator?: any
}
export const MultiSelect = ({
	label,
	meta,
	options,
	onChange,
	allowCreate,
	validator,
	...props
}: InputProps2) => {
	return (
		<div>
			<label
				htmlFor={props.name || label}
				className='block flex text-sm mb-1 text-[#7C7F80]'
			>
				{label}
			</label>
			{allowCreate ? (
				<CreatableSelect
					{...props}
					value={
						meta?.value?.length
							? props.isMulti
								? meta.value
								: {
										value: meta.value,
										label:
											options?.find((o) => o.value === meta.value)?.label ||
											meta.value,
								  }
							: null
					}
					options={options}
					className='basic-multi-select'
					classNamePrefix='select'
					onChange={(selectedOption: any) => {
						onChange(selectedOption)
					}}
					isValidNewOption={(inputValue: any) => {
						return validator ? validator(inputValue) : true
					}}
				/>
			) : (
				<ReactSelect
					{...props}
					value={
						meta?.value?.length
							? props.isMulti
								? meta.value
								: {
										value: meta.value,
										label:
											options?.find((o) => o.value === meta.value)?.label ||
											meta.value,
								  }
							: null
					}
					options={options}
					className='basic-multi-select'
					classNamePrefix='select'
					onChange={(selectedOption: any) => {
						onChange(selectedOption)
					}}
				/>
			)}
			{meta.touched && meta.error && (
				<div className='text-red-500 text-sm'>{meta.error}</div>
			)}
		</div>
	)
}
