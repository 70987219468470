import React, { useMemo, useState } from 'react'
import Modal from '../../component/Modal'
import Pagination from '../../component/Pagination'
import SearchInput from '../../component/SearchInput'
import Table from '../../component/table'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import { LogModel, log_api } from '../../service/log'
import FilterForm, { initialParams } from '../../component/FilterForm'

export default function Audit() {
	const [showModal, setShowModal] = useState(false)
	const [pageParams, setPageParams] = useState(initialParams)
	const [search, setSearch] = useState('')
	const searchInput = useDebounce(search, 500)
	const queryParams = useMemo(() => {
		let res = `page_number=${pageParams.page_number}&page_size=${pageParams.page_size}`
		if (searchInput) res += `&search=${searchInput}`
		if (pageParams.filter) res += `&filter=${pageParams.filter}`
		return res
	}, [pageParams, searchInput])
	const updatePageParams = (key: string, value: any) => {
		setPageParams((prev) => ({ ...prev, [key]: value }))
	}
	const { data, isLoading } = useQuery({
		queryKey: ['logs', queryParams],
		queryFn: log_api,
	})

	const columns = [
		{ key: 'ip', title: 'IP Address' },
		{
			key: 'user_id',
			title: 'User',
			render: (value: string, row: LogModel) =>
				row.user ? row.user?.firstname + ' ' + row.user?.lastname : row.email,
		},
		{
			key: 'url',
			title: 'Modules',
			render: (value: any, row: LogModel) => (
				<div className={`text-[#2196F3] capitalize`}>
					{value.replace('/api/v1/', '')}
				</div>
			),
		},
		{ key: 'method', title: 'Method' },
		{ key: 'status_code', title: 'Status Code' },
		{
			key: 'created_at',
			title: 'Timestamp',
			render: (value: string) => new Date(value).toLocaleString(),
		},
	]
	return (
		<div className='p-3 bg-white rounded-lg'>
			{/* <button onClick={() => setShowModal(true)}>Open Modal</button> */}
			<div className=' sm:flex p-3 justify-between'>
				<p className='text-[24px] font-medium'>Audit Trail</p>
				<div className='flex'></div>
			</div>
			<div className='sm:grid sm:grid-cols-8 gap-4 border-dashed border-t  p-3'>
				<FilterForm
					fields={[
						'email',
						'method',
						'status_code',
						{ name: 'created_at', label: 'Timestamp', type: 'date' },
					]}
					values={JSON.parse(pageParams.filter || '{}')}
					action={(val) => updatePageParams('filter', val)}
				/>
				<div className='col-span-7 '>
					<SearchInput
						placeholder='Search ...'
						value={search}
						onChange={(e: any) => setSearch(e.target.value)}
					/>
				</div>
			</div>
			<Table columns={columns} data={data?.rows} isLoading={isLoading} />

			<div
				className='flex overflow-x-auto sm:justify-end'
				id='pagination-globle-style'
			>
				<Pagination
					setPageSize={(val: number) => updatePageParams('page_size', val)}
					setPageNumber={(val: number) => updatePageParams('page_number', val)}
					currentPage={pageParams.page_number}
					pageSize={pageParams.page_size}
					totalCount={data?.count || 0}
				/>
			</div>

			<Modal
				showModal={showModal}
				setShowModal={setShowModal}
				title='My Modal'
				size={'md'}
			>
				{/* Modal content goes here */}
				<p>This is the content of the modal.</p>
			</Modal>
		</div>
	)
}
