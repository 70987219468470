export const print = (active: string) => {
	if (document.getElementById('c-printing')) {
		document
			.getElementById('c-printing')
			?.classList.remove(
				`c-${active === 'portrait' ? 'landscape' : 'portrait'}`
			)
		document.getElementById('c-printing')?.classList.add(`c-${active}`)
	}
	let state = ''
	window.onbeforeprint = () => {
		if (document.getElementById('c-close')?.classList.contains('lg:ps-72'))
			state = 'lg:ps-72'
		document.getElementById('c-close')?.classList.remove('lg:ps-72')
		if (document.getElementById('c-close')?.classList.contains('lg:ps-32'))
			state = 'lg:ps-32'
		document.getElementById('c-close')?.classList.remove('lg:ps-32')
	}
	window.onafterprint = () =>
		document.getElementById('c-close')?.classList.add(state)
	window.print()
}
