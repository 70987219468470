import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import CsvExportButton from '../../../component/CsvExportButton'
import { getColorWithOpacity } from '../../../utility/generateColorWithOpacity'

export default function ReportTable() {
	const [showOptions, setShowOptions] = useState(false)

	return (
		<div>
			<div className='w-fit py-4'>
				{/* <CsvExportButton
					data={[]}
					headers={[]}
					filename='exported_data.csv'
					buttonText='Export as CSV'
				/> */}
			</div>

			<div className=' bg-white '>
				<div className='flex justify-between p-3'>
					<button
						type='button'
						className='sm:text-[16px] sm:mb-4 text-left flex items-center gap-2 font-[400]'
						onClick={() => setShowOptions(!showOptions)}
					>
						{showOptions ? (
							<FontAwesomeIcon icon={faCaretUp} />
						) : (
							<FontAwesomeIcon icon={faCaretDown} />
						)}
						Canada
					</button>
					<div className='flex gap-3'>
						<p
							style={{ background: getColorWithOpacity('#4CAF50', 0.08) }}
							className='text-[#4CAF50] w-fit h-fit p-1 rounded px-2'
						>
							80.1
						</p>
						<p
							style={{ background: getColorWithOpacity('#FF9800', 0.08) }}
							className='text-[#FF9800] w-fit h-fit  p-1 rounded px-2'
						>
							80.1
						</p>
					</div>
				</div>
				{showOptions && (
					<div className='grid grid-cols-3 gap-4 bg-white font-[400] text-[14px]'>
						<div className='col-span-3'>
							<div className='flex flex-col mb-4'>
								<div className='flex flex-row bg-[#FFF4EC]'>
									<div className='flex-1 p-3 pl-6 '>Date</div>
									<div className='flex-1 p-3'>Day </div>
									<div className='flex-1 p-3'>In</div>
									<div className='flex-1 p-3'>Out </div>
									<div className='flex-1 p-3'>Hour</div>
									<div className='flex-1 p-3'>Service </div>
								</div>

								<div className='flex flex-row '>
									<div className='flex-1 p-2 bg-[#F5F5F5] text-[#EC7211] text-center'>
										Week 1
									</div>
								</div>
								<div className='flex flex-row bg-white  '>
									<div className='flex-1 p-2 pl-6'>1/2/2024</div>
									<div className='flex-1 p-2'>Monday</div>
									<div className='flex-1 p-2'>8:00AM</div>
									<div className='flex-1 p-2'>12:00PM</div>
									<div className='flex-1 p-2'>4 hour</div>
									<div className='flex-1 p-2'>Nursing </div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
