import React, { useRef } from 'react'
import Input from '../../../component/Input'
import { useQuery } from '@tanstack/react-query'
import { settings_api, update_setting_api } from '../../../service/settings'
import { useFormik } from 'formik'
import SelectInput from '../../../component/SelectInput'
import Textarea from '../../../component/textarea'
import { OtherButton } from '../../../component/Buttons'
import { compareObject } from '../../../component/functions'
import * as Yup from 'yup'
import { uploadFile } from '../../../component/uploadFile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons'
import { fileSrc } from '../../../component/FileLoader'
import { primary_bg } from '../../../themes/themes'
// import FileSelectInput from '../../../component/FileSelectInput'

export default function GeneralSettings() {
	const { data: settings } = useQuery({
		queryKey: ['settings'],
		queryFn: settings_api,
	})
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: settings || {},
		onSubmit: async (values) => {
			let v = { ...values }
			v = compareObject(values, settings!)
			if (v.media) {
				const res = await uploadFile(values.media, 'logo')
				if (res.status) v.business_logo = res.data?.id
				delete v.media
			}
			update_setting_api(v)
		},
		validationSchema: Yup.object({
			currency: Yup.string().required().label('Currency'),
			time_zone: Yup.string().required().label('Timezone'),
			default_rate_per_hour: Yup.number()
				.required()
				.label('Default rate per hour'),
			invoice_default_due_days: Yup.number()
				.required()
				.label('Default invoice due days'),
			business_name: Yup.string().required().label('Business name'),
			business_email: Yup.string().email().required().label('Business email'),
			business_phone: Yup.string().label('Business phone'),
			business_address: Yup.string(),
			payment_instructions: Yup.string(),
			use_clocking_time: Yup.string(),
			time_off_duration: Yup.string(),
			tax_rate: Yup.string(),
			tax_value: Yup.number(),
		}),
	})
	return (
		<div>
			<form onSubmit={formik.handleSubmit} className='sm:p-3'>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
					<SelectInput
						formik={formik}
						label='Currency'
						id='currency'
						name='currency'
						onChange={formik.handleChange}
						value={formik.values.currency}
						options={Intl.supportedValuesOf('currency').map((currency) => ({
							id: currency,
							name: currency,
						}))}
						option={{ value: 'id', label: 'name' }}
						required={true}
					/>
					<SelectInput
						formik={formik}
						label='Timezone'
						id='time_zone'
						name='time_zone'
						onChange={formik.handleChange}
						value={formik.values.time_zone}
						options={Intl.supportedValuesOf('timeZone').map(
							(time_zone: string) => ({
								id: time_zone,
								name: time_zone,
							})
						)}
						option={{ value: 'id', label: 'name' }}
						required={true}
					/>
					<Input
						required={true}
						label='Default Rate Per Hour'
						id='default_rate_per_hour'
						name='default_rate_per_hour'
						type='number'
						value={formik.values.default_rate_per_hour}
						onChange={formik.handleChange}
					/>
					<Input
						required={true}
						label='Default Invoice Due Days'
						id='invoice_default_due_days'
						name='invoice_default_due_days'
						type='number'
						value={formik.values.invoice_default_due_days}
						onChange={formik.handleChange}
					/>
					<Input
						required={true}
						label='Business Name'
						id='business_name'
						name='business_name'
						type='text'
						value={formik.values.business_name}
						onChange={formik.handleChange}
					/>
					<Input
						required={true}
						label='Business Email'
						id='business_email'
						name='business_email'
						type='email'
						value={formik.values.business_email}
						onChange={formik.handleChange}
					/>
					<Input
						required={false}
						label='Business Phone'
						id='business_phone'
						name='business_phone'
						type='phone'
						value={formik.values.business_phone}
						onChange={formik.handleChange}
					/>
					<SelectInput
						formik={formik}
						label='Time off Duration'
						id='time_off_duration'
						name='time_off_duration'
						onChange={formik.handleChange}
						value={formik.values.time_off_duration}
						options={[
							{ id: 'dates', name: 'Dates' },
							{ id: 'interval', name: 'Interval' },
						]}
						option={{ value: 'id', label: 'name' }}
						required={false}
					/>
					<Input
						required={false}
						label='Tax Name'
						id='tax_name'
						name='tax_name'
						type='text'
						value={formik.values.tax_name}
						onChange={formik.handleChange}
					/>
					<Input
						required={false}
						label='Tax Rate'
						id='tax_rate'
						name='tax_rate'
						type='number'
						value={formik.values.tax_rate}
						onChange={formik.handleChange}
					/>
					<Textarea
						label='Business Address'
						id='business_address'
						name='business_address'
						type='address'
						value={formik.values.business_address}
						onChange={formik.handleChange}
					/>
					<Textarea
						label='Payment Instructions'
						id='payment_instructions'
						name='payment_instructions'
						type='text'
						value={formik.values.payment_instructions}
						onChange={formik.handleChange}
					/>
					<div className='flex items-center'>
						<input
							type='checkbox'
							id='use_clocking_time'
							name='use_clocking_time'
							checked={formik.values.use_clocking_time === 'true'}
							onChange={(e) =>
								formik.setFieldValue(
									'use_clocking_time',
									e.target.checked.toString()
								)
							}
						/>
						<label htmlFor='use_clocking_time' className='ml-2'>
							Use clocking time
						</label>
					</div>
					<div className='h-48 w-full text-center object-cover md:h-full  md:w-60'>
						<div className='relative inline-block m-3'>
							<div className='w-fit border border-[#7C7F80]'>
								<img
									className='inline-block h-[3rem] w-[8rem] m-1'
									src={
										formik.values.business_logo
											? fileSrc({
													id: formik.values.business_logo,
													provider: 'cloudinary',
											  })
											: 'https://res.cloudinary.com/tobilasinde/image/upload/v1715547435/jd3x68ohm3szqdm0uodr.jpg'
									}
									alt='Imag Description'
								/>
							</div>
							<FileSelectInput
								id='image'
								name='media'
								label='Upload Image(JPG, PNG)'
								onChange={(file: File) => formik.setFieldValue('media', file)}
								accept='image/*'
							/>
							{/* <span
							className={`absolute bottom-0 end-0 block text-white p-1 h-8 w-8 rounded-full ring-2 ring-white bg-[${primary_bg}]`}
						>
							<FontAwesomeIcon icon={faCameraRetro} />
						</span> */}
						</div>
					</div>
					{/* <FileSelectInput
						meta={formik.getFieldMeta('media')}
						name='media'
						label='Business Logo'
						onChange={(file: File) => formik.setFieldValue('media', file)}
						accept='image/*'
					/> */}
				</div>
				<div className='flex flex-row-reverse m-3'>
					<OtherButton
						onClick={formik.handleSubmit}
						id='settings'
						title='Update'
						action='update'
					/>
				</div>
			</form>
		</div>
	)
}

const FileSelectInput = ({ onChange, ...rest }: any) => {
	const fileInputRef: any = useRef(null)

	const handleButtonClick = () => {
		fileInputRef.current.click()
	}

	const handleFileChange = (event: any) => {
		const selectedFile = event.target.files[0]

		onChange(selectedFile)
	}

	return (
		<div>
			<input
				type='file'
				id='file'
				name='file'
				onChange={handleFileChange}
				className='hidden' // Hide the default file input
				ref={fileInputRef}
				{...rest}
			/>
			<button
				type='button'
				onClick={handleButtonClick}
				className={`absolute bottom-0 end-0 block text-white p-1 h-8 w-8 rounded-full ring-2 ring-white bg-[${primary_bg}]`}
			>
				<FontAwesomeIcon icon={faCameraRetro} />
			</button>
		</div>
	)
}
