import axios from 'axios'
import { Response, queryParam } from './_model'
import { TimeoffCategoryModel } from './timeoffCategory'
const BASE_URL = process.env.REACT_APP_BASE_URL

export const timeoff_request_api = async (
	data: queryParam
): Promise<Response<TimeOffRequestModel[]>> => {
	return await axios.get(
		`${BASE_URL}/timeoff-request?${data.queryKey[1] || ''}`
	)
}

export const create_timeoff_request_api = async (data: TimeOffRequestModel) => {
	return await axios.post(`${BASE_URL}/timeoff-request`, data)
}

export const update_timeoff_request_api = async (
	data: Partial<TimeOffRequestModel>
) => {
	return await axios.put(`${BASE_URL}/timeoff-request/${data.id}`, data)
}
export const update_timeoff_decision_api = async (
	data: Partial<TimeOffRequestModel>
) => {
	return await axios.put(
		`${BASE_URL}/timeoff-request/decision/${data.id}`,
		data
	)
}

export const delete_timeoff_request_api = async (id: string) => {
	return await axios.delete(`${BASE_URL}/timeoff-request/${id}`)
}
export const delete_timeoff_request_date_api = async (id: string) => {
	return await axios.delete(`${BASE_URL}/timeoff-request/date/${id}`)
}
export type TimeOffRequestModel = {
	id?: number
	user_id: string
	time_off_category_id: string
	time_off_request_dates: { date: string; duration_in_hours: number }[]
	time_off_category?: TimeoffCategoryModel
	status?: string
}
export const initialValues: TimeOffRequestModel = {
	user_id: '',
	time_off_category_id: '',
	time_off_request_dates: [],
}
