import {
	faDownload,
	faHandHoldingDollar,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import Button from '../../../component/Button'
import Table from '../../../component/table'
import { InvoiceModel } from '../../../service/Invoice'
import { SettingModel } from '../../../service/settings'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import { fileSrc } from '../../../component/FileLoader'
import { OtherButton } from '../../../component/Buttons'
import { print } from '../../../component/print'
dayjs.extend(relativeTime)
dayjs.extend(utc)

export default function InvoicePreviewOne({
	data,
	settings,
	setShowModal,
}: {
	data?: InvoiceModel
	settings?: SettingModel
	setShowModal: (a: boolean) => void
}) {
	const columns = [
		{ key: 'service', title: 'Service' },
		{ key: 'staff', title: 'Staff' },
		{ key: 'duration', title: 'Duration' },
		{ key: 'charge', title: 'Total' },
	]
	const currency = useMemo(() => {
		return data?.currency || settings?.currency || 'USD'
	}, [data?.currency, settings?.currency])
	const invoice_items = useMemo(() => {
		return (
			data?.invoice_items?.map((item) => {
				return {
					service: item.service,
					staff: item.staff,
					duration: item.duration_min
						? `${Math.floor(item?.duration_min / 60)}h ${
								item?.duration_min % 60
						  }m`
						: '',
					charge: item.charge?.toLocaleString('en-US', {
						style: 'currency',
						currency,
					}),
				}
			}) || [{ service: '', staff: '', duration: '', charge: '' }]
		)
	}, [currency, data?.invoice_items])
	const totals = useMemo(() => {
		const result = {
			subtotal: 0,
			tax: data?.tax_rate || 0,
			discount: data?.discount || 0,
			total: 0,
		}
		result['subtotal'] =
			data?.invoice_items?.reduce(
				(acc: any, item: any) => acc + Number(item.charge),
				0
			) || 0
		result['tax'] = result.subtotal * (result.tax / 100)
		result.total = result.subtotal + result.tax - result.discount
		return result
	}, [data])
	return (
		<div className='sm:grid grid-cols-3 gap-4  rounded-xl  bg-white'>
			<div className='w-full col-span-2 p-6'>
				<div className='flex justify-between mb-3'>
					<p className='text-[#1E2325]'>Invoice Number: #{data?.id}</p>
					<div className='flex-none text-xl font-semibold flex'>
						{settings?.business_logo && (
							<img
								className=' h-[30px] w-[30px] object-contain'
								src={fileSrc({
									id: settings?.business_logo,
									provider: 'cloudinary',
									format: '',
								})}
								alt=''
							/>
						)}
						<p className='pl-2 text-[14px] text-[#1E2325]'>
							{settings?.business_name}
						</p>
					</div>
				</div>
				<div className='flex justify-between text-[14px] text-[#7C7F80] mt-3'>
					<p>Date created</p>
					<p>Date due</p>
				</div>

				<div className='flex justify-between text-[14px] '>
					<p>{dayjs.utc(data?.invoice_date).format('DD/MM/YYYY')}</p>
					<p>
						{dayjs.utc(data?.due_date).format('DD/MM/YYYY')}{' '}
						<span className='text-[#EC7211]'>
							Due in {dayjs.utc(data?.due_date).toNow(true)}
						</span>{' '}
					</p>
				</div>
				<div className='font-[400] flex flex-col gap-1 mb-3'>
					<p className='text-[14px] text-[#7C7F80] my-5'>Bill to</p>
					<p> {data?.location.name} </p>
					<p className='text-[#7C7F80] text-[14px]'>{data?.location.address}</p>
					<p className='text-[#7C7F80] text-[14px]'>{data?.location.email}</p>
					<p className='text-[#7C7F80] text-[14px]'>{data?.location.phone}</p>
				</div>
				<div className='bg-[#FAFAFA] p-4  mb-2'>
					<p className='text-[14px] text-[#7C7F80]'>Payment instruction</p>
					<p
						className='text-[16px] font-[400]'
						dangerouslySetInnerHTML={{
							__html: settings?.payment_instructions || '',
						}}
					></p>
				</div>

				<p className='font-[500] text-[14px] mt-4 m-1'>Item(s) Details</p>
				<Table
					tableStyle={'rounded-[10px] border border-gray-200'}
					columns={columns}
					data={invoice_items}
					showHeaderIcon={true}
					hasCheckBox={false}
				/>
				<div className='bg-[#FAFAFA] p-3 my-4 font-[400] my-4'>
					<p className='text-[14px] text-[#7C7F80]'>Note</p>
					<p className='text-[16px] text-[#1E2325]'>{data?.note}</p>
				</div>
			</div>
			<div className='w-full mb-3 p-5 '>
				<div className='border border-gray-200 p-3 flex flex-col gap-3 font-[400] text-[14px]'>
					<div>
						<p className='text-[#7C7F80]'>Currency</p>
						<p className='text-[#1E2325]'>{data?.currency}</p>
					</div>
					<div>
						<p className='text-[#7C7F80] text-[14px]'>Billing Duration</p>
						<p className='text-[#1E2325]'>
							{dayjs.utc(data?.start_date).format('DD/MM/YYYY')} -{' '}
							{dayjs.utc(data?.end_date).format('DD/MM/YYYY')}
						</p>
					</div>
					<div>
						<p className='text-[#7C7F80] text-[14px]'>Payment status</p>
						<p className='text-[#4CAF50]'>
							{data?.payment_status ? 'Paid' : 'Unpaid'}
						</p>
					</div>
					<div>
						<p className='text-[#7C7F80] text-[14px]'>Payment method</p>
						<p className='text-[#4CAF50]'>{data?.payment_method}</p>
					</div>
					<div>
						<p className='text-[#7C7F80] text-[14px]'>Payment note</p>
						<p className='text-[#4CAF50]'>{data?.payment_note}</p>
					</div>
					<p>Status</p>
					<p>{data?.status ? 'Active' : 'Inactive'}</p>
					<div className='flex justify-between'>
						<p className='text-[#7C7F80] text-[14px]'>Subtotal</p>
						<p>
							{totals.subtotal.toLocaleString('en-US', {
								style: 'currency',
								currency,
							})}
						</p>
					</div>
					<div className='flex justify-between'>
						<p className='text-[#7C7F80] text-[14px]'>
							{settings?.tax_name || 'Tax'}({settings?.tax_rate || '0'})%
						</p>
						<p>
							{totals?.tax.toLocaleString('en-US', {
								style: 'currency',
								currency,
							})}
						</p>
					</div>
					<div className='flex justify-between'>
						<p className='text-[#7C7F80] text-[14px]'>Discount</p>
						<p>
							{data?.discount.toLocaleString('en-US', {
								style: 'currency',
								currency,
							})}
						</p>
					</div>
					<div className='flex justify-between font-medium'>
						<p className='text-[#7C7F80] text-[14px]'>Total</p>
						<p>
							{totals.total.toLocaleString('en-US', {
								style: 'currency',
								currency,
							})}
						</p>
					</div>
				</div>
				<div className='no-print flex gap-2 justify-end mt-3'>
					{data?.payment_status !== true && (
						<OtherButton
							onClick={() => setShowModal(true)}
							id='invoices'
							title='Mark As Paid'
							className='btn-primary'
							action='update'
							rightIcon={<FontAwesomeIcon icon={faHandHoldingDollar} />}
						/>
					)}
					<Button
						title='Download'
						btnStyle='btn-primary'
						rightIcon={<FontAwesomeIcon icon={faDownload} />}
						onClick={() => print('landscape')}
					/>
					{/* <Button
							title='Print'
							btnStyle='btn-outline'
							rightIcon={<FontAwesomeIcon icon={faPrint} />}
						/> */}
				</div>
			</div>
		</div>
	)
}
