import React from 'react'
import './App.css'
import Router from './router/router'

// import 'preline'

import { AuthInit, AuthProvider } from './context/AuthContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Toaster } from 'react-hot-toast'
import { setupAxios } from './service/api-interseptor/axiosInstance'
import axios from 'axios'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			gcTime: Infinity,
		},
	},
})
setupAxios(axios)

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			{/* <ReactQueryDevtools initialIsOpen={false} /> */}
			<AuthProvider>
				<AuthInit>
					<Router />
				</AuthInit>
				<Toaster position='top-right' />
			</AuthProvider>
		</QueryClientProvider>
	)
}

export default App
