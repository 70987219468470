import React from 'react'
import { primary_bg } from '../themes/themes'

const Modal = ({
	showModal,
	setShowModal,
	title,
	children,
	size,
}: {
	showModal: any
	setShowModal: any
	title: any
	children: any
	size: 'md' | 'lg'
}) => {
	const closeModal = () => {
		setShowModal(false)
	}

	return (
		<>
			{showModal && (
				<div className='fixed inset-0   overflow-y-scroll z-50 flex p-20 items-center justify-center'>
					<div
						className='fixed inset-0 transition-opacity'
						onClick={closeModal}
					>
						<div className='absolute inset-0 bg-black opacity-50'></div>
					</div>

					<div
						className={`relative bg-white  mt-20  p-4 rounded-lg shadow-lg  overflow-scroll
          ${size === 'md' && 'max-w-md'} 
          ${
						size === 'lg' &&
						'sm:max-w-[700px] max-w-[300px] max-h-[400px] sm:max-h-fit overflow-scroll'
					} w-full`}
					>
						<div className='flex items-start justify-between mb-4'>
							<p></p>
							<h2 className={`text-[${primary_bg}] text-center `}>{title}</h2>
							<button
								className='text-gray-500 hover:text-gray-700  focus:outline-none'
								onClick={closeModal}
							>
								&times;
							</button>
						</div>
						<div>{children}</div>
					</div>
				</div>
			)}
		</>
	)
}

export default Modal
