// api.js

import axios from 'axios'
import { Response, queryParam } from './_model'
import { FileModel } from './misc'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const location_api = async (
	data: queryParam
): Promise<Response<LocationModel[]>> => {
	return await axios.get(`${BASE_URL}/location?${data.queryKey[1] || ''}`)
}

export const create_location_api = async (data: LocationModel) => {
	return await axios.post(`${BASE_URL}/location`, data)
}

export const update_location_api = async (data: LocationModel) => {
	return await axios.put(`${BASE_URL}/location/${data.id}`, data)
}

export const delete_location_api = async (data: string) => {
	return await axios.delete(`${BASE_URL}/location/${data}`)
}

export type LocationModel = {
	id?: string
	name: string
	type: 'physical' | 'virtual'
	address: string
	email: string
	phone: string
	zip_code: string
	city: string
	state: string
	country: string
	longitude: string
	latitude: string
	file: FileModel
	note: string
	status: boolean
}
