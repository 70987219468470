// api.js

import axios from 'axios'
import { Response, queryParam } from './_model'

const BASE_URL = process.env.REACT_APP_BASE_URL + '/settings/email-template'

export const email_template_api = async (
	data: queryParam
): Promise<Response<EmailTemplateModel[]>> => {
	return await axios.get(`${BASE_URL}?${data.queryKey[1] || ''}`)
}

export const create_email_template_api = async (data: EmailTemplateModel) => {
	return await axios.post(`${BASE_URL}`, data)
}

export const update_email_template_api = async (data: EmailTemplateModel) => {
	return await axios.put(`${BASE_URL}/${data.id}`, data)
}

export type EmailTemplateModel = {
	id?: string
	title: string
	subject: string
	html: string
	status?: boolean
	fields?: string[]
	available_field?: string
}
