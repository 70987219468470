import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../../../component/Breadcrumb'
import {
	get_permissions_api,
	update_permissions_url,
} from '../../../service/permissions'
import { OtherButton } from '../../../component/Buttons'

export default function AssignRolePermissions() {
	const navigate = useNavigate()
	let { role_id } = useParams()
	const { data: redData } = useQuery({
		queryKey: ['permissions', `role_id=${role_id}`],
		queryFn: get_permissions_api,
	})
	const breadcrumbItems = [
		{ label: 'Role', url: '/users/roles' },
		{ label: 'Edit Permissions', url: '' },
	]

	const [data, setData]: any = useState(redData)

	useEffect(() => setData(redData), [redData])

	const handleCheckboxChange = (
		id: string,
		property: string,
		value?: boolean
	) => {
		setData((prevData: any) =>
			prevData.map((item: any) =>
				item.id === id
					? {
							...item,
							[property]: value !== undefined ? value : !item[property],
					  }
					: item
			)
		)
	}

	const mutation = useMutation({
		mutationFn: update_permissions_url,
		onSuccess: () => {
			navigate('/users/roles')
		},
	})
	const submit = () => {
		mutation.mutate(data)
	}
	return (
		<div>
			<div className='flex justify-between'>
				<Breadcrumb items={breadcrumbItems} />
			</div>
			<div className='bg-white rounded-lg mt-3 p-1'>
				<div className='flex flex-col m-1'>
					<div className='-m-1.5 overflow-x-auto'>
						<div className='p-1.5 min-w-full inline-block align-middle'>
							<div className='  overflow-hidden '>
								<table className='min-w-full  '>
									<thead className={`text-xs text-gray-700 bg-[#F5F5F5]`}>
										<tr>
											<th
												scope='col'
												className='px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase'
											>
												Name
											</th>
											{['read', 'create', 'update', 'delete', 'read_all'].map(
												(item, i) => (
													<th
														key={i}
														scope='col'
														className='px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase'
													>
														<input
															type='checkbox'
															onChange={(e) =>
																data?.forEach(
																	(d: any) =>
																		d.hasOwnProperty(item) &&
																		handleCheckboxChange(
																			d.id,
																			item,
																			e.target.checked
																		)
																)
															}
															className='border-gray-900 rounded text-[primary] focus:ring-blue-500 '
														/>{' '}
														{item}
													</th>
												)
											)}
										</tr>
									</thead>
									<tbody className=' '>
										{data &&
											data?.map((item: any, i: number) => (
												<tr key={i}>
													<td className='px-6 py-4 whitespace-nowrap border-dotted border-b-2  text-sm font-medium text-gray-800 capitalize'>
														{item.model_name.replace(/-/g, ' ')}
													</td>
													{[
														'read',
														'create',
														'update',
														'delete',
														'read_all',
													].map((permission, i) => (
														<td
															key={i}
															className='px-6 py-4 whitespace-nowrap text-sm border-dotted border-b-2 font-medium text-gray-800 '
														>
															{item.hasOwnProperty(permission) && (
																<input
																	type='checkbox'
																	checked={item[permission]}
																	onChange={() =>
																		handleCheckboxChange(item.id, permission)
																	}
																	className='border-gray-900 rounded text-[primary] focus:ring-blue-500 '
																/>
															)}
														</td>
													))}
												</tr>
											))}
									</tbody>
								</table>
								<OtherButton
									onClick={submit}
									id='permissions'
									title='Submit'
									action='update'
									className='m-2'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<br />
			<br />
		</div>
	)
}
