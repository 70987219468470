import Tabs from '../../component/tabs'
import Authentication from './component/Authentication'
import PersonalInformation from './component/PersonalInformation'
import Location from './component/Location'
import ProfileCardAvater from '../../component/ProfileCardAvater'
import Breadcrumb from '../../component/Breadcrumb'
import { useQuery } from '@tanstack/react-query'
import { get_user_profile_api } from '../../service/user'
import ProfileUploadFiles from './component/ProfileUploadFiles'

export default function Profile() {
	const breadcrumbItems = [
		{ label: 'Profile', url: '/' },
		{ label: 'Edit profile', url: '/Profile' },
	]
	const { data, refetch } = useQuery({
		queryKey: ['profile'],
		queryFn: get_user_profile_api,
	})
	return (
		<div>
			{/* <Breadcrumb /> */}
			<div className='ml-3 sm:mb-6'>
				<Breadcrumb items={breadcrumbItems} />
			</div>
			<div className='md:flex '>
				<div className='px-3'>
					<ProfileCardAvater data={data} refetch={refetch} />
				</div>
				<Tabs
					elements={[
						{
							header: 'Personal Information',
							element: <PersonalInformation data={data} />,
						},
						{ header: 'Authentication', element: <Authentication /> },
						{ header: 'Location', element: <Location data={data} /> },
						{
							header: 'Upload files',
							element: data?.id ? (
								<ProfileUploadFiles id={data.id} />
							) : (
								<p>loading...</p>
							),
						},
					]}
				/>
			</div>
		</div>
	)
}
