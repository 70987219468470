import axios from 'axios'
import { Response, queryParam } from './_model'
const BASE_URL = process.env.REACT_APP_BASE_URL

export const role_api = async (
	data: queryParam
): Promise<Response<RoleModel[]>> => {
	return await axios.get(`${BASE_URL}/role?${data.queryKey[1] || ''}`)
}

export const create_role_api = async (data: RoleModel) => {
	return await axios.post(`${BASE_URL}/role`, data)
}

export const update_role_api = async (data: RoleModel) => {
	return await axios.put(`${BASE_URL}/role/${data.id}`, data)
}

export const delete_role_api = async (data: string) => {
	return await axios.delete(`${BASE_URL}/role/${data}`)
}
export type RoleModel = {
	id: string
	name: string
}
