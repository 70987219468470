import React, { useState } from 'react';

import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink, CSVDownload } from 'react-csv';
import { primary_bg } from '../themes/themes';

const CsvExportButton = ({ data, headers, filename, buttonText }: any) => {
    const header = headers
    const [show, setShow] = useState(false);
    return (
        <button
            onClick={() => setShow(!show)}
            className={` py-2 px-4 rounded-md border border-primary text-[${primary_bg}]`}>
            {/* <CSVLink data={data} headers={header} filename={filename}>  */}
            {buttonText}

            {show && <CSVDownload data={data} headers={header} filename={filename} />}
            <FontAwesomeIcon icon={faFileExport} />
            {/* </CSVLink>   */}
        </button>

    );
};

export default CsvExportButton;
