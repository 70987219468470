import ReactSelect from 'react-select'

export const MultiSelect = ({
	formik,
	options,
	disabled,
	multi,
	label,
	name,
	required,
}: {
	formik: any
	options: { label: string; value: string }[]
	name: string
	label?: string
	disabled?: boolean
	multi?: boolean
	required?: boolean
}) => {
	return (
		<div>
			<label
				htmlFor={formik.name}
				className='block flex text-sm mb-1 text-[#7C7F80]'
			>
				{label} {required && <div className='text-[#F44336]'>*</div>}
			</label>
			<ReactSelect
				defaultValue={options.find((o) => o.value === formik.values[name])}
				isMulti={multi}
				name={name}
				options={options}
				className='basic-multi-select'
				classNamePrefix='select'
				onChange={(selectedOption: any) => {
					formik.setFieldValue(
						name,
						multi
							? selectedOption?.map((item: any) => item.value)
							: selectedOption?.value
					)
				}}
				isDisabled={disabled}
			/>
			{formik?.touched[name] && formik.errors[name] && (
				<div className='text-red-500 text-sm'>{formik.errors[name]}</div>
			)}
		</div>
	)
}
