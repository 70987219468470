import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import Modal from '../../../component/Modal'
import Table from '../../../component/table'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Input2 } from '../../../component/Input'
import { SelectInput2 } from '../../../component/SelectInput'
import Button from '../../../component/Button'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
	EmailTemplateModel,
	create_email_template_api,
	email_template_api,
	update_email_template_api,
} from '../../../service/emailTemplate'
import { useFormik } from 'formik'
import { compareObject } from '../../../component/functions'
import * as Yup from 'yup'
import RadioInput from '../../../component/RadioInput'

const validationSchema = Yup.object({
	title: Yup.string().required().label('Title'),
	subject: Yup.string().required().label('Subject'),
	html: Yup.string().required().label('Body'),
	status: Yup.boolean().label('Status'),
})
export default function Emailtemplate() {
	const [showModal, setShowModal] = useState<'create' | 'edit' | 'view'>()
	const [selectedData, setSelectedData] = useState<EmailTemplateModel>()
	const { data, refetch } = useQuery({
		queryKey: ['email_template'],
		queryFn: email_template_api,
	})
	const columns = [
		{ key: 'title', title: 'Title' },
		{ key: 'subject', title: 'Subject' },
		{
			key: 'status',
			title: 'status',
			render: (value: any, row: any) => (
				<div
					className={`${
						value ? ' text-green-600 bg-green-100' : 'text-red-600 bg-red-100'
					} 
                      text-[12px] text-center px-2 py-1 w-fit   rounded-full`}
				>
					{value ? 'Active' : 'Inactive'}
				</div>
			),
		},
		{
			key: 'status',
			title: 'Actions',
			render: (value: any, row: any) => (
				<>
					<button
						onClick={() => {
							setShowModal('edit')
							setSelectedData(row)
						}}
						className={`pl-4`}
					>
						<FontAwesomeIcon icon={faPenToSquare} />
					</button>
				</>
			),
		},
	]

	const initialValues = useMemo(
		() => selectedData || { title: '', subject: '', html: '' },
		[selectedData]
	)
	const mutation = useMutation({
		mutationFn: initialValues.id
			? update_email_template_api
			: create_email_template_api,

		onSuccess: () => {
			refetch()
			setShowModal(undefined)
		},
	})
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit: async (values) => {
			let v = { ...values }
			delete v.available_field
			delete v.fields
			if (values.id && selectedData) {
				v = compareObject(values, selectedData)
				v.id = values.id
			}
			mutation.mutate(v)
		},
		validationSchema,
	})
	useEffect(() => {
		if (formik.values.available_field)
			formik.setFieldValue(
				'html',
				formik.values.html + `{{${formik.values.available_field}}}`
			)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.available_field])

	return (
		<div>
			<div>
				<p className='text-[16px] m-2 my-3 font-medium'>Email Template</p>
				<Table
					columns={columns}
					data={data}
					showHeaderIcon={undefined}
					hasCheckBox={true}
				/>
			</div>

			<Modal
				showModal={showModal === 'edit'}
				setShowModal={setShowModal}
				title=''
				size={'lg'}
			>
				<form
					className='gap-3 flex flex-col h-fit'
					onSubmit={formik.handleSubmit}
				>
					<Input2
						{...formik.getFieldProps('subject')}
						meta={formik.getFieldMeta('subject')}
						label='subject'
					/>
					<SelectInput2
						{...formik.getFieldProps('available_field')}
						meta={formik.getFieldMeta('available_field')}
						label='Available fields'
						options={formik.values.fields?.map((field) => ({
							value: field,
							label: field,
						}))}
						required={true}
					/>
					<div className=''>
						<label
							htmlFor='message'
							className='block text-sm mb-1 flex text-[#7C7F80]'
						>
							Message <div className='text-[#F44336]'>*</div>
						</label>

						<ReactQuill
							theme='snow'
							value={formik.values.html}
							onChange={(e) => formik.setFieldValue('html', e)}
							// style={{ height: '200px' }}
						/>
					</div>
					<div className='mb-2'>
						<label className='text-[#7C7F80]'>Status</label>
						<div className='flex gap-3 mt-2'>
							<RadioInput
								id='Status'
								name='status'
								value={true}
								label='Active'
								field={formik.getFieldProps('status')}
								onChange={() => formik.setFieldValue('status', true)}
							/>
							<RadioInput
								id='StatusNo'
								name='status'
								value={false}
								label='Inactive'
								field={formik.getFieldProps('status')}
								onChange={() => formik.setFieldValue('status', false)}
							/>
						</div>
					</div>
					<div className='flex flex-row-reverse gap-2'>
						<Button
							onClick={() => setShowModal(undefined)}
							title='Close'
							btnStyle='btn-outline'
							type='button'
						/>
						<Button
							type='submit'
							btnStyle='btn-primary'
							loading={mutation.isPending}
							title={'Update'}
						/>
					</div>
				</form>
			</Modal>
		</div>
	)
}
