import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import Button from '../../../component/Button'
import { Input2 } from '../../../component/Input'
import Modal from '../../../component/Modal'
import { SelectInput2 } from '../../../component/SelectInput'
import Table from '../../../component/table'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
	create_profile_onboarding,
	get_user_profile_onboarding_api,
	update_profile_onboarding,
} from '../../../service/user'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { compareObject } from '../../../component/functions'
import { uploadFile } from '../../../component/uploadFile'
import FileSelectInput from '../../../component/FileSelectInput'
import { fileSrc } from '../../../component/FileLoader'

const validationSchema = Yup.object({
	value: Yup.string().label('Value'),
	issue_date: Yup.date().label('Issue date'),
	expiry_date: Yup.date().when('issue_date', (issueDate, schema) => {
		return issueDate[0]
			? schema.min(issueDate, 'Expiry date must be after issue date')
			: schema.label('date')
	}),
	onboarding_setting_id: Yup.string().required().label('Document Name'),
	media: Yup.string().required().label('Media'),
})
export default function ProfileUploadFiles({ id }: { id: string }) {
	const [type, setType] = useState<'image' | 'pdf'>()
	const [showModal, setShowModal] = useState<'create' | 'edit' | 'view'>()
	const [selectedData, setSelectedData] = useState<any>()
	const { data: profile, refetch } = useQuery({
		queryKey: [
			'profile_onboarding',
			`filter={"user_id":"${id}"}&include=Files`,
		],
		queryFn: get_user_profile_onboarding_api,
	})
	const columns = [
		{
			key: 'name',
			title: 'Document name',
		},
		{
			key: 'user_onboardings',
			title: 'Issue date',
			render: (value: any) => <span>{value[0]?.issue_date || 'N/A'}</span>,
		},
		{
			key: 'user_onboardings',
			title: 'Expiry date',
			render: (value: any) => <span>{value[0]?.expiry_date || 'N/A'}</span>,
		},
		{
			key: 'user_onboardings',
			title: 'Status',
			render: (value: any) =>
				value[0] ? (
					<>
						{!value[0].expiry_date ? (
							<span className='rounded p-2 bg-green-50 text-green-600'>
								Active
							</span>
						) : (
							<span
								className={`rounded p-2 ${
									new Date(value[0].expiry_date) > new Date()
										? 'bg-green-50 text-green-600'
										: new Date(value[0].expiry_date) < new Date()
										? 'bg-red-50 text-red-600'
										: 'bg-yellow-50 text-yellow-600'
								} `}
							>
								{new Date(value[0].expiry_date) > new Date()
									? 'Active'
									: new Date(value[0].expiry_date) < new Date()
									? 'Expired'
									: 'N/A'}
							</span>
						)}
					</>
				) : (
					<span className='rounded p-2 bg-yellow-50 text-yellow-600'>N/A</span>
				),
		},

		{
			key: 'user_onboardings',
			title: 'Action',
			render: (value: any, row: any) => (
				<>
					{value[0]?.files?.length && (
						<a
							href={fileSrc(value[0].files[value[0].files.length - 1])}
							target='_blank'
							rel='noreferrer'
						>
							<FontAwesomeIcon onClick={() => {}} icon={faEye} />
						</a>
					)}
					<button
						onClick={() => {
							setSelectedData(
								row.user_onboardings[0] || { onboarding_setting_id: row.id }
							)
							setType(row.type)
							setShowModal('edit')
						}}
						className={`pl-4`}
					>
						<FontAwesomeIcon icon={faPenToSquare} />
					</button>
				</>
			),
		},
	]
	const mutation = useMutation({
		mutationFn: selectedData?.id
			? update_profile_onboarding
			: create_profile_onboarding,
		onSuccess: () => {
			refetch()
			setShowModal(undefined)
		},
	})
	const initialValues = {
		id: undefined,
		onboarding_setting_id: '',
		issue_date: '',
		expiry_date: '',
		media: '',
	}
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: { ...initialValues, ...selectedData },
		onSubmit: async (values) => {
			let v = { ...values }
			if (values.id) {
				v = compareObject(values, selectedData!)
				v.id = values.id
			}
			if (v.media) {
				const res = await uploadFile(values.media, 'profile')
				if (res.status) v.media = res.data
				else delete v.media
			}
			if (id) v.user_id = id
			mutation.mutate(v)
		},
		validationSchema,
	})
	return (
		<div>
			<Table columns={columns} data={profile?.rows} />

			{formik.values && (
				<Modal
					showModal={showModal === 'create' || showModal === 'edit'}
					setShowModal={setShowModal}
					title={`Upload File`}
					size={'md'}
				>
					<form
						className='gap-3 flex flex-col h-fit'
						onSubmit={formik.handleSubmit}
					>
						<SelectInput2
							{...formik.getFieldProps('onboarding_setting_id')}
							meta={formik.getFieldMeta('onboarding_setting_id')}
							label='Document name'
							options={profile?.rows.map((doc) => ({
								value: doc.id,
								label: doc.name,
							}))}
							required={true}
						/>
						<Input2
							{...formik.getFieldProps('issue_date')}
							meta={formik.getFieldMeta('issue_date')}
							label='Issue date'
							type='date'
						/>
						<Input2
							{...formik.getFieldProps('expiry_date')}
							meta={formik.getFieldMeta('expiry_date')}
							label='Expiry date'
							type='date'
						/>
						<FileSelectInput
							meta={formik.getFieldMeta('media')}
							name='media'
							label={`Upload File (${type})`}
							onChange={(file: File) => formik.setFieldValue('media', file)}
							accept='image/*'
						/>
						<div className=''>
							{/* <label htmlFor='file' className='block text-sm  text-gray-600'>
								Upload file
							</label>

							<div className=' border border-gray-300 pt-8 pb-10 mt-1 text-center h-fit  rounded-lg relative'>
								<button
									type='button'
									className={`  w-fit bg-[${secondary_bg}] gap-2 text-[12px] p-3 border-[${primary_bg}] border-solid border-2
							 text-[${primary_bg}] text-sm p-1  rounded-md`}
									onClick={() => fileInputRef.current.click()}
								>
									<span className='mr-2'>Upload image</span>
									<FontAwesomeIcon icon={faFile} />
								</button>
								<input
									type='file'
									id='file'
									name='file'
									onChange={() => {}}
									className='hidden'
									ref={fileInputRef}
								/>
							</div> */}
						</div>
					</form>

					<div className='flex items-center justify-center mt-3 gap-2 ...'>
						<Button
							btnStyle='btn-outline'
							title='Cancel'
							type='button'
							onClick={() => setShowModal(undefined)}
						/>
						<Button
							type='submit'
							btnStyle='btn-primary'
							loading={false}
							title='Create'
							onClick={formik.handleSubmit}
						/>
					</div>
				</Modal>
			)}
		</div>
	)
}
