import React from 'react'
// padding: 30px 18px;
function Card({ title, subTitle, color, background, icon }: any) {
	return (
		<div className='bg-white rounded-[10px] flex shadow justify-between p-5 sm:px-[18px] sm:py-[30px]'>
			<div>
				<p className='text-[16px] text-[#1E2325'>{title}</p>
				<h3 className='text-[24px]'>{subTitle}</h3>
			</div>
			<div>
				<button
					type='button'
					style={{ background, color }}
					className={` font-medium rounded-lg p-2.5 px-3 text-2xl text-center inline-flex items-center me-2 `}
				>
					{icon}
					{/* <FontAwesomeIcon icon={faBagShopping} /> */}
				</button>
			</div>
		</div>
	)
}
export default function DashBoardCard() {
  return (
    <div>

			<div className='grid grid-cols-1 my-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4'>
				{/* Replace the following divs with your actual components */}
				<Card
					title='Total Schedules'
					subTitle='2024'
					color='#F44336'
					icon={
						<svg
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<g id='vuesax/outline/brifecase-timer'>
								<g id='brifecase-timer'>
									<path
										id='Vector'
										d='M16.0001 25.2663C12.5067 25.2663 9.66675 22.4263 9.66675 18.9329C9.66675 15.4396 12.5067 12.5996 16.0001 12.5996C19.4934 12.5996 22.3334 15.4396 22.3334 18.9329C22.3334 22.4263 19.4934 25.2663 16.0001 25.2663ZM16.0001 14.5996C13.6134 14.5996 11.6667 16.5463 11.6667 18.9329C11.6667 21.3196 13.6134 23.2663 16.0001 23.2663C18.3867 23.2663 20.3334 21.3196 20.3334 18.9329C20.3334 16.5463 18.3867 14.5996 16.0001 14.5996Z'
										fill='#F44336'
									/>
									<path
										id='Vector_2'
										d='M14.6668 21.2669C14.3334 21.2669 14.0001 21.0936 13.8134 20.7869C13.5334 20.3069 13.6801 19.6936 14.1601 19.4136L15.1734 18.8003C15.2801 18.7469 15.3334 18.6269 15.3334 18.5203V17.2803C15.3334 16.7336 15.7868 16.2803 16.3334 16.2803C16.8801 16.2803 17.3334 16.7203 17.3334 17.2669V18.5069C17.3334 19.3203 16.8934 20.0936 16.2001 20.5069L15.1868 21.1203C15.0268 21.2269 14.8401 21.2669 14.6668 21.2669Z'
										fill='#F44336'
									/>
									<path
										id='Vector_3'
										d='M21.3333 30.3337H10.6666C4.50664 30.3337 3.35998 27.467 3.06664 24.6803L2.06664 14.0003C1.91998 12.6003 1.87998 10.5337 3.26664 8.987C4.46664 7.65367 6.45331 7.01367 9.33331 7.01367H22.6666C25.56 7.01367 27.5466 7.667 28.7333 8.987C30.12 10.5337 30.08 12.6003 29.9333 14.0137L28.9333 24.667C28.64 27.467 27.4933 30.3337 21.3333 30.3337ZM9.33331 9.00034C7.07998 9.00034 5.53331 9.44034 4.74664 10.3203C4.09331 11.0403 3.87998 12.147 4.05331 13.8003L5.05331 24.4803C5.27998 26.587 5.85331 28.3337 10.6666 28.3337H21.3333C26.1333 28.3337 26.72 26.587 26.9466 24.467L27.9466 13.8137C28.12 12.147 27.9066 11.0403 27.2533 10.3203C26.4666 9.44034 24.92 9.00034 22.6666 9.00034H9.33331Z'
										fill='#F44336'
									/>
									<path
										id='Vector_4'
										d='M21.3334 9.00033C20.7867 9.00033 20.3334 8.54699 20.3334 8.00033V6.93366C20.3334 4.56033 20.3334 3.66699 17.0667 3.66699H14.9334C11.6667 3.66699 11.6667 4.56033 11.6667 6.93366V8.00033C11.6667 8.54699 11.2134 9.00033 10.6667 9.00033C10.1201 9.00033 9.66675 8.54699 9.66675 8.00033V6.93366C9.66675 4.58699 9.66675 1.66699 14.9334 1.66699H17.0667C22.3334 1.66699 22.3334 4.58699 22.3334 6.93366V8.00033C22.3334 8.54699 21.8801 9.00033 21.3334 9.00033Z'
										fill='#F44336'
									/>
									<path
										id='Vector_5'
										d='M21.3466 19.1864C20.8932 19.1864 20.4932 18.8798 20.3732 18.4264C20.2399 17.8931 20.5599 17.3464 21.0932 17.2131C23.6932 16.5598 26.1066 15.4264 28.2666 13.8531C28.7066 13.5331 29.3332 13.6264 29.6666 14.0798C29.9866 14.5198 29.8932 15.1464 29.4399 15.4798C27.0666 17.1998 24.4266 18.4398 21.5732 19.1598C21.5066 19.1731 21.4266 19.1864 21.3466 19.1864Z'
										fill='#F44336'
									/>
									<path
										id='Vector_6'
										d='M10.6667 19.2265C10.5867 19.2265 10.5067 19.2132 10.4267 19.1999C7.74665 18.5465 5.22665 17.4132 2.91999 15.8399C2.46665 15.5332 2.34665 14.9065 2.65332 14.4532C2.95999 13.9999 3.58665 13.8799 4.03999 14.1865C6.14665 15.6265 8.43999 16.6532 10.8933 17.2532C11.4267 17.3865 11.76 17.9199 11.6267 18.4665C11.5333 18.9199 11.12 19.2265 10.6667 19.2265Z'
										fill='#F44336'
									/>
								</g>
							</g>
						</svg>
					}
					background='rgb(254 226 226)'
				/>
				<Card
					title='Ongoing '
					subTitle='42'
					color='#2196F3'
					background='rgb(219 234 254)'
					icon={
						<svg
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M16.0001 25.2663C12.5067 25.2663 9.66675 22.4263 9.66675 18.9329C9.66675 15.4396 12.5067 12.5996 16.0001 12.5996C19.4934 12.5996 22.3334 15.4396 22.3334 18.9329C22.3334 22.4263 19.4934 25.2663 16.0001 25.2663ZM16.0001 14.5996C13.6134 14.5996 11.6667 16.5463 11.6667 18.9329C11.6667 21.3196 13.6134 23.2663 16.0001 23.2663C18.3867 23.2663 20.3334 21.3196 20.3334 18.9329C20.3334 16.5463 18.3867 14.5996 16.0001 14.5996Z'
								fill='#2196F3'
							/>
							<path
								d='M14.6668 21.2669C14.3334 21.2669 14.0001 21.0936 13.8134 20.7869C13.5334 20.3069 13.6801 19.6936 14.1601 19.4136L15.1734 18.8003C15.2801 18.7469 15.3334 18.6269 15.3334 18.5203V17.2803C15.3334 16.7336 15.7868 16.2803 16.3334 16.2803C16.8801 16.2803 17.3334 16.7203 17.3334 17.2669V18.5069C17.3334 19.3203 16.8934 20.0936 16.2001 20.5069L15.1868 21.1203C15.0268 21.2269 14.8401 21.2669 14.6668 21.2669Z'
								fill='#2196F3'
							/>
							<path
								d='M21.3333 30.3337H10.6666C4.50664 30.3337 3.35998 27.467 3.06664 24.6803L2.06664 14.0003C1.91998 12.6003 1.87998 10.5337 3.26664 8.987C4.46664 7.65367 6.45331 7.01367 9.33331 7.01367H22.6666C25.56 7.01367 27.5466 7.667 28.7333 8.987C30.12 10.5337 30.08 12.6003 29.9333 14.0137L28.9333 24.667C28.64 27.467 27.4933 30.3337 21.3333 30.3337ZM9.33331 9.00034C7.07998 9.00034 5.53331 9.44034 4.74664 10.3203C4.09331 11.0403 3.87998 12.147 4.05331 13.8003L5.05331 24.4803C5.27998 26.587 5.85331 28.3337 10.6666 28.3337H21.3333C26.1333 28.3337 26.72 26.587 26.9466 24.467L27.9466 13.8137C28.12 12.147 27.9066 11.0403 27.2533 10.3203C26.4666 9.44034 24.92 9.00034 22.6666 9.00034H9.33331Z'
								fill='#2196F3'
							/>
							<path
								d='M21.3334 9.00033C20.7867 9.00033 20.3334 8.54699 20.3334 8.00033V6.93366C20.3334 4.56033 20.3334 3.66699 17.0667 3.66699H14.9334C11.6667 3.66699 11.6667 4.56033 11.6667 6.93366V8.00033C11.6667 8.54699 11.2134 9.00033 10.6667 9.00033C10.1201 9.00033 9.66675 8.54699 9.66675 8.00033V6.93366C9.66675 4.58699 9.66675 1.66699 14.9334 1.66699H17.0667C22.3334 1.66699 22.3334 4.58699 22.3334 6.93366V8.00033C22.3334 8.54699 21.8801 9.00033 21.3334 9.00033Z'
								fill='#2196F3'
							/>
							<path
								d='M21.3466 19.1864C20.8932 19.1864 20.4932 18.8798 20.3732 18.4264C20.2399 17.8931 20.5599 17.3464 21.0932 17.2131C23.6932 16.5598 26.1066 15.4264 28.2666 13.8531C28.7066 13.5331 29.3332 13.6264 29.6666 14.0798C29.9866 14.5198 29.8932 15.1464 29.4399 15.4798C27.0666 17.1998 24.4266 18.4398 21.5732 19.1598C21.5066 19.1731 21.4266 19.1864 21.3466 19.1864Z'
								fill='#2196F3'
							/>
							<path
								d='M10.6667 19.2265C10.5867 19.2265 10.5067 19.2132 10.4267 19.1999C7.74665 18.5465 5.22665 17.4132 2.91999 15.8399C2.46665 15.5332 2.34665 14.9065 2.65332 14.4532C2.95999 13.9999 3.58665 13.8799 4.03999 14.1865C6.14665 15.6265 8.43999 16.6532 10.8933 17.2532C11.4267 17.3865 11.76 17.9199 11.6267 18.4665C11.5333 18.9199 11.12 19.2265 10.6667 19.2265Z'
								fill='#2196F3'
							/>
						</svg>
					}
				/>
				<Card
					title='On Hold'
					subTitle='11'
					color='#FF9800'
					background='rgb(255 237 213)'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
						>
							<path
								d='M15.9998 25.2663C12.5065 25.2663 9.6665 22.4263 9.6665 18.9329C9.6665 15.4396 12.5065 12.5996 15.9998 12.5996C19.4932 12.5996 22.3332 15.4396 22.3332 18.9329C22.3332 22.4263 19.4932 25.2663 15.9998 25.2663ZM15.9998 14.5996C13.6132 14.5996 11.6665 16.5463 11.6665 18.9329C11.6665 21.3196 13.6132 23.2663 15.9998 23.2663C18.3865 23.2663 20.3332 21.3196 20.3332 18.9329C20.3332 16.5463 18.3865 14.5996 15.9998 14.5996Z'
								fill='#FF9800'
							/>
							<path
								d='M14.6665 21.2669C14.3332 21.2669 13.9998 21.0936 13.8132 20.7869C13.5332 20.3069 13.6798 19.6936 14.1598 19.4136L15.1732 18.8003C15.2798 18.7469 15.3332 18.6269 15.3332 18.5203V17.2803C15.3332 16.7336 15.7865 16.2803 16.3332 16.2803C16.8798 16.2803 17.3332 16.7203 17.3332 17.2669V18.5069C17.3332 19.3203 16.8932 20.0936 16.1998 20.5069L15.1865 21.1203C15.0265 21.2269 14.8398 21.2669 14.6665 21.2669Z'
								fill='#FF9800'
							/>
							<path
								d='M21.3333 30.3337H10.6666C4.50664 30.3337 3.35998 27.467 3.06664 24.6803L2.06664 14.0003C1.91998 12.6003 1.87998 10.5337 3.26664 8.987C4.46664 7.65367 6.45331 7.01367 9.33331 7.01367H22.6666C25.56 7.01367 27.5466 7.667 28.7333 8.987C30.12 10.5337 30.08 12.6003 29.9333 14.0137L28.9333 24.667C28.64 27.467 27.4933 30.3337 21.3333 30.3337ZM9.33331 9.00034C7.07998 9.00034 5.53331 9.44034 4.74664 10.3203C4.09331 11.0403 3.87998 12.147 4.05331 13.8003L5.05331 24.4803C5.27998 26.587 5.85331 28.3337 10.6666 28.3337H21.3333C26.1333 28.3337 26.72 26.587 26.9466 24.467L27.9466 13.8137C28.12 12.147 27.9066 11.0403 27.2533 10.3203C26.4666 9.44034 24.92 9.00034 22.6666 9.00034H9.33331Z'
								fill='#FF9800'
							/>
							<path
								d='M21.3332 9.00033C20.7865 9.00033 20.3332 8.54699 20.3332 8.00033V6.93366C20.3332 4.56033 20.3332 3.66699 17.0665 3.66699H14.9332C11.6665 3.66699 11.6665 4.56033 11.6665 6.93366V8.00033C11.6665 8.54699 11.2132 9.00033 10.6665 9.00033C10.1198 9.00033 9.6665 8.54699 9.6665 8.00033V6.93366C9.6665 4.58699 9.6665 1.66699 14.9332 1.66699H17.0665C22.3332 1.66699 22.3332 4.58699 22.3332 6.93366V8.00033C22.3332 8.54699 21.8798 9.00033 21.3332 9.00033Z'
								fill='#FF9800'
							/>
							<path
								d='M21.3468 19.1864C20.8935 19.1864 20.4935 18.8798 20.3735 18.4264C20.2402 17.8931 20.5602 17.3464 21.0935 17.2131C23.6935 16.5598 26.1068 15.4264 28.2668 13.8531C28.7068 13.5331 29.3335 13.6264 29.6668 14.0798C29.9868 14.5198 29.8935 15.1464 29.4402 15.4798C27.0668 17.1998 24.4268 18.4398 21.5735 19.1598C21.5068 19.1731 21.4268 19.1864 21.3468 19.1864Z'
								fill='#FF9800'
							/>{' '}
							<path
								d='M10.6667 19.2265C10.5867 19.2265 10.5067 19.2132 10.4267 19.1999C7.74665 18.5465 5.22665 17.4132 2.91999 15.8399C2.46665 15.5332 2.34665 14.9065 2.65332 14.4532C2.95999 13.9999 3.58665 13.8799 4.03999 14.1865C6.14665 15.6265 8.43999 16.6532 10.8933 17.2532C11.4267 17.3865 11.76 17.9199 11.6267 18.4665C11.5333 18.9199 11.12 19.2265 10.6667 19.2265Z'
								fill='#FF9800'
							/>
						</svg>
					}
				/>
				<Card
					title='Completed'
					subTitle='38'
					color='#4CAF50'
					background='rgb(220 252 231)'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
						>
							<path
								d='M15.9998 25.2663C12.5065 25.2663 9.6665 22.4263 9.6665 18.9329C9.6665 15.4396 12.5065 12.5996 15.9998 12.5996C19.4932 12.5996 22.3332 15.4396 22.3332 18.9329C22.3332 22.4263 19.4932 25.2663 15.9998 25.2663ZM15.9998 14.5996C13.6132 14.5996 11.6665 16.5463 11.6665 18.9329C11.6665 21.3196 13.6132 23.2663 15.9998 23.2663C18.3865 23.2663 20.3332 21.3196 20.3332 18.9329C20.3332 16.5463 18.3865 14.5996 15.9998 14.5996Z'
								fill='#4CAF50'
							/>
							<path
								d='M14.6665 21.2669C14.3332 21.2669 13.9998 21.0936 13.8132 20.7869C13.5332 20.3069 13.6798 19.6936 14.1598 19.4136L15.1732 18.8003C15.2798 18.7469 15.3332 18.6269 15.3332 18.5203V17.2803C15.3332 16.7336 15.7865 16.2803 16.3332 16.2803C16.8798 16.2803 17.3332 16.7203 17.3332 17.2669V18.5069C17.3332 19.3203 16.8932 20.0936 16.1998 20.5069L15.1865 21.1203C15.0265 21.2269 14.8398 21.2669 14.6665 21.2669Z'
								fill='#4CAF50'
							/>
							<path
								d='M21.3333 30.3337H10.6666C4.50664 30.3337 3.35998 27.467 3.06664 24.6803L2.06664 14.0003C1.91998 12.6003 1.87998 10.5337 3.26664 8.987C4.46664 7.65367 6.45331 7.01367 9.33331 7.01367H22.6666C25.56 7.01367 27.5466 7.667 28.7333 8.987C30.12 10.5337 30.08 12.6003 29.9333 14.0137L28.9333 24.667C28.64 27.467 27.4933 30.3337 21.3333 30.3337ZM9.33331 9.00034C7.07998 9.00034 5.53331 9.44034 4.74664 10.3203C4.09331 11.0403 3.87998 12.147 4.05331 13.8003L5.05331 24.4803C5.27998 26.587 5.85331 28.3337 10.6666 28.3337H21.3333C26.1333 28.3337 26.72 26.587 26.9466 24.467L27.9466 13.8137C28.12 12.147 27.9066 11.0403 27.2533 10.3203C26.4666 9.44034 24.92 9.00034 22.6666 9.00034H9.33331Z'
								fill='#4CAF50'
							/>
							<path
								d='M21.3332 9.00033C20.7865 9.00033 20.3332 8.54699 20.3332 8.00033V6.93366C20.3332 4.56033 20.3332 3.66699 17.0665 3.66699H14.9332C11.6665 3.66699 11.6665 4.56033 11.6665 6.93366V8.00033C11.6665 8.54699 11.2132 9.00033 10.6665 9.00033C10.1198 9.00033 9.6665 8.54699 9.6665 8.00033V6.93366C9.6665 4.58699 9.6665 1.66699 14.9332 1.66699H17.0665C22.3332 1.66699 22.3332 4.58699 22.3332 6.93366V8.00033C22.3332 8.54699 21.8798 9.00033 21.3332 9.00033Z'
								fill='#4CAF50'
							/>
							<path
								d='M21.3468 19.1864C20.8935 19.1864 20.4935 18.8798 20.3735 18.4264C20.2402 17.8931 20.5602 17.3464 21.0935 17.2131C23.6935 16.5598 26.1068 15.4264 28.2668 13.8531C28.7068 13.5331 29.3335 13.6264 29.6668 14.0798C29.9868 14.5198 29.8935 15.1464 29.4402 15.4798C27.0668 17.1998 24.4268 18.4398 21.5735 19.1598C21.5068 19.1731 21.4268 19.1864 21.3468 19.1864Z'
								fill='#4CAF50'
							/>
							<path
								d='M10.6667 19.2265C10.5867 19.2265 10.5067 19.2132 10.4267 19.1999C7.74665 18.5465 5.22665 17.4132 2.91999 15.8399C2.46665 15.5332 2.34665 14.9065 2.65332 14.4532C2.95999 13.9999 3.58665 13.8799 4.03999 14.1865C6.14665 15.6265 8.43999 16.6532 10.8933 17.2532C11.4267 17.3865 11.76 17.9199 11.6267 18.4665C11.5333 18.9199 11.12 19.2265 10.6667 19.2265Z'
								fill='#4CAF50'
							/>
						</svg>
					}
				/>
				{/* Add more items as needed */}
			</div>
    </div>
  )
}
