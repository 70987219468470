export const compareObject = (
	obj1: Record<string, any>,
	obj2: Record<string, any>
) => {
	const valuesCopy = Object.keys(obj1).reduce((acc: any, key) => {
		if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
			acc[key] = obj1[key]
		}
		return acc
	}, {})
	return valuesCopy
}
