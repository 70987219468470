import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDebounce } from '@uidotdev/usehooks'
import React, { useMemo, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import Loading from '../../../component/loading'
import Modal from '../../../component/Modal'
import Pagination from '../../../component/Pagination'
import Table from '../../../component/table'
import {
	TimeOffRequestModel,
	delete_timeoff_request_api,
	timeoff_request_api,
} from '../../../service/timeOffRequest'
import CreateTimeOff from './createTimeOffRequest'
import {
	CreateButton,
	DeleteButton,
	EditButton,
} from '../../../component/Buttons'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import FilterForm, { initialParams } from '../../../component/FilterForm'
import ViewTimeOffRequest from './viewTimeOff'
import CsvExportButton from '../../../component/CsvExportButton'
import TimeOffDecision from './TimeOffDecision'

export default function TimeOffRequest() {
	const [showModal, setShowModal] = useState<
		'create' | 'edit' | 'view' | 'decision'
	>()
	const [selectedData, setSelectedData] = useState<TimeOffRequestModel>()
	const [pageParams, setPageParams] = useState(initialParams)
	const [search] = useState('')
	const searchInput = useDebounce(search, 500)
	const queryParams = useMemo(() => {
		let res = `page_number=${pageParams.page_number}&page_size=${pageParams.page_size}`
		if (searchInput) res += `&search=${searchInput}`
		if (pageParams.filter) res += `&filter=${pageParams.filter}`
		return res
	}, [pageParams, searchInput])
	const updatePageParams = (key: string, value: any) => {
		setPageParams((prev) => ({ ...prev, [key]: value }))
	}

	const { data, isLoading, refetch } = useQuery({
		queryKey: ['timeoff_requests', queryParams],
		queryFn: timeoff_request_api,
	})

	const deleteMutation = useMutation({
		mutationFn: delete_timeoff_request_api,
		onSuccess: () => {
			refetch()
		},
	})

	const columns = [
		{
			key: 'user',
			title: 'User',
			render: (value: any) => value?.firstname + ' ' + value?.lastname,
		},
		{
			key: 'time_off_category',
			title: 'Category',
			render: (value: any) => value?.name,
		},
		{
			key: 'time_off_request_dates',
			title: 'Duration',
			render: (value: any) => {
				return value?.length + ' days'
			},
		},
		{
			key: 'time_off_request_dates',
			title: 'Start date',
			render: (value: any) => {
				const date =
					value?.length &&
					value?.reduce((prev: any, curr: any) =>
						prev.date < curr.date ? prev : curr
					)?.date
				if (date) return dayjs(date).format('DD/MM/YYYY')
			},
		},
		{
			key: 'status',
			title: 'Leave status',
		},
		{
			key: 'id',
			title: 'Action',
			render: (value: any, row: TimeOffRequestModel) => (
				<div className={`flex gap-2`} key={row.id}>
					<FontAwesomeIcon
						onClick={() => {
							setSelectedData(row)
							setShowModal('view')
						}}
						icon={faEye}
					/>
					<EditButton
						onClick={() => {
							setSelectedData(row)
							setShowModal('edit')
						}}
						id='timeoff-requests'
					/>
					<DeleteButton
						onClick={() => deleteMutation.mutate(value)}
						id='timeoff-requests'
					/>
				</div>
			),
		},
	]
	return (
		<div className=' bg-white rounded-lg'>
			{/* <button onClick={() => setShowModal(true)}>Open Modal</button> */}
			<div className=' sm:flex p-4 justify-between'>
				<p className='text-[24px] font-medium'>Time off request</p>
				<div className='flex'>
					<CreateButton
						title='Add Timeoff Request'
						onClick={() => {
							setSelectedData(undefined)
							setShowModal('create')
						}}
						id='timeoff-requests'
					/>
					<CsvExportButton
						data={data?.rows}
						headers={undefined}
						filename={'TimeoffRequest'}
						buttonText={'Export as CSV'}
					/>
				</div>
			</div>
			<div className='sm:grid sm:grid-cols-8 gap-4 border-dashed border-t  p-4'>
				<FilterForm
					fields={['status1', 'user_id', 'time_off_category_id']}
					values={JSON.parse(pageParams.filter || '{}')}
					action={(val) => updatePageParams('filter', val)}
				/>
			</div>
			{isLoading ? (
				<Loading />
			) : (
				<Table
					columns={columns}
					data={data?.rows}
					showHeaderIcon={undefined}
					hasCheckBox={true}
				/>
			)}

			<div
				className='flex overflow-x-auto sm:justify-end'
				id='pagination-globle-style'
			>
				<Pagination
					setPageSize={(val: number) => updatePageParams('page_size', val)}
					setPageNumber={(val: number) => updatePageParams('page_number', val)}
					currentPage={pageParams.page_number}
					pageSize={pageParams.page_size}
					totalCount={data?.count || 0}
				/>
			</div>

			<Modal
				showModal={showModal === 'view'}
				setShowModal={setShowModal}
				title='View Timeoff'
				size={'md'}
			>
				<ViewTimeOffRequest
					selectedData={selectedData!}
					setShowModal={setShowModal}
				/>
			</Modal>

			<Modal
				showModal={['create', 'edit'].includes(showModal || '')}
				setShowModal={setShowModal}
				title={`${selectedData?.id ? 'Edit' : 'Create'} Time off request`}
				size={'lg'}
			>
				<CreateTimeOff
					refetch={refetch}
					selectedData={selectedData}
					setShowModal={setShowModal}
				/>
			</Modal>
			<Modal
				showModal={showModal === 'decision'}
				setShowModal={setShowModal}
				title={`Make a decision`}
				size={'md'}
			>
				<TimeOffDecision
					refetch={refetch}
					selectedData={selectedData}
					setShowModal={setShowModal}
				/>
			</Modal>
		</div>
	)
}
