import React, { useState } from 'react'

const Tabs = ({ elements }: any) => {
	const [active, setActive] = useState(0)
	const handleTabClick = (tabIndex: any) => {
		setActive(tabIndex)
	}

	return (
		<div className='w-full'>
			<nav className='sm:flex justify' aria-label='Tabs'>
				{elements.map((element: any, index: number) => (
					<button
						key={index}
						type='button'
						className={` p-3 sm:w-fit w-full sm:mr-3  bg-white sm:mb-0 my-1 mb-2 rounded-t-xl rounded-b-xl sm:rounded-b-none   sm:rounded-t-xl ${
							active === index ? 'text-primary' : ''
						}`}
						id={`basic-tabs-item-${index}`}
						data-hs-tab={`#basic-tabs-${index}`}
						aria-controls={`basic-tabs-${index}`}
						role='tab'
						onClick={() => handleTabClick(index)}
					>
						{element.header}
					</button>
				))}
			</nav>

			<div className='bg-white sm:rounded-tl-none   rounded-xl w-full p-4'>
				{elements.map((element: any, index: number) => (
					<div
						key={index}
						id={`basic-tabs-${index}`}
						className={`${active === index ? '' : 'hidden'}`}
						role='tabpanel'
						aria-labelledby={`basic-tabs-item-${index}`}
					>
						<div className='text-gray-500 dark:text-gray-400'>
							{element.element}
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default Tabs
