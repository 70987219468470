import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { accept_invitation } from '../../service/user'
import { SubmitButton } from '../../component/Buttons'

const AcceptInvite = () => {
	const navigate = useNavigate()
	let { invite_credentials } = useParams()
	const { data, isLoading, isSuccess, isError } = useQuery({
		queryKey: ['accept_invitation', { credentials: invite_credentials }],
		queryFn: accept_invitation,
		enabled: !!invite_credentials,
	})
	return (
		<div className='relative z-10 bg-white p-8 rounded-lg sm:w-96'>
			<h2 className='text-2xl  mb-1 text-center'>Business Invitation</h2>
			<div className='text-gray-400 text-sm text-center mb-4'>
				{isLoading && <p>Checking invite link...</p>}
				{isSuccess && (
					<>
						<p>{data.message}</p>
						{data.status ? (
							<SubmitButton
								text='Back to login'
								onClick={() => navigate('/auth/login')}
							/>
						) : (
							<SubmitButton
								text='Proceed to Setup your Account'
								onClick={() =>
									navigate('/auth/reset-password/' + data.credentials)
								}
							/>
						)}
					</>
				)}
				{isError && (
					<>
						<p>Invalid invite link</p>
						<SubmitButton
							text='Back to login'
							onClick={() => navigate('/auth/login')}
						/>
					</>
				)}
			</div>
		</div>
	)
}

export default AcceptInvite
