import * as Yup from 'yup'
export const formValidation = Yup.object().shape({
	location_id: Yup.string().required().label('Location'),
	start_date: Yup.string().required('Start date is required'),
	end_date: Yup.string()
		.required('End date is required')
		.test(
			'is-greater-than-start',
			'End date must be greater than start date',
			function (value) {
				const { start_date } = this.parent
				if (!start_date || !value) {
					return true
				}
				return new Date(value) > new Date(start_date)
			}
		),
	invoice_date: Yup.string().required('Invoice date is required'),
	due_date: Yup.string().required('Due date is required'),
	currency: Yup.string(),
	discount: Yup.number(),
	tax: Yup.number(),
	// payment_instructions: Yup.string().required('Payment date is required'),
	note: Yup.string(),
	invoice_items: Yup.array().of(
		Yup.object().shape({
			schedule_id: Yup.string(),
			staff_id: Yup.string(),
			duration_min: Yup.number().required('Duration is required'),
			charge: Yup.number().required('Charge is required'),
			service: Yup.string(),
			staff: Yup.string(),
		})
	),
})
