import React from 'react'
import { LocationModel } from '../../service/location'
import { OtherButton } from '../../component/Buttons'
import { fileSrc } from '../../component/FileLoader'

function Item({ title, subtitle }: { title: string; subtitle: string }) {
	return (
		<div className='flex justify-between p-3 font-[400] text-[13px] text-[#1E2325]'>
			<p> {title}</p>
			<p> {subtitle}</p>
		</div>
	)
}

export default function ViewLocation({
	selectedData,
	setShowModal,
}: {
	selectedData: LocationModel
	setShowModal: any
}) {
	return (
		<>
			<div className='"grid grid-cols-1 divide-y'>
				{selectedData.file && (
					<img
						src={fileSrc(selectedData.file, 250, 250)}
						alt={selectedData.name}
						style={{ margin: 'auto' }}
					/>
				)}
				<Item title={'Name'} subtitle={selectedData.name} />
				<Item title={'Location type'} subtitle={selectedData.type} />
				<Item title={'Phone number'} subtitle={selectedData.phone} />
				<Item title={'Email'} subtitle={selectedData.email} />
				<Item title={'Address'} subtitle={selectedData.address} />
				<Item title={'Zip code'} subtitle={selectedData.zip_code} />
				<Item title={'City'} subtitle={selectedData.city} />
				<Item title={'State/Province'} subtitle={selectedData.state} />
				<Item title={'Country'} subtitle={selectedData.country} />
				<Item
					title={'Status'}
					subtitle={selectedData.status ? 'Active' : 'Inactive'}
				/>
				<hr />
			</div>
			<div className='"grid grid-cols-1 divide-y'>
				<p className='font-[600] text-[20px] p-3'>Optional</p>
				<Item title={'Latitude'} subtitle={selectedData.longitude} />
				<Item title={'Longitude'} subtitle={selectedData.latitude} />
				<Item title={'Note'} subtitle={selectedData.note} />
			</div>
			<div className='flex flex-row-reverse m-3'>
				<OtherButton
					onClick={() => setShowModal('edit')}
					id='locations'
					title='Edit'
					action='update'
				/>
			</div>
		</>
	)
}
