import React from 'react'
import Button from '../../../component/Button';
import { TimeoffCategoryModel } from '../../../service/timeoffCategory';
import { TimeOffRequestModel } from '../../../service/timeOffRequest';

function Item({ title, subtitle }: { title: string; subtitle: any }) {
	return (
        <div>
		<div className='flex justify-between p-3 font-[400] text-[13px] text-[#1E2325]'>
			<p> {title}</p>
			<p> {subtitle}</p>
		</div>

        {/* <hr /> */}
        </div>
	)
}

export default function ViewTimeoffCategory({
	selectedData,
	setShowModal,
}: {
	selectedData: TimeoffCategoryModel
	setShowModal: any
}) {
	return (
		<>
			<div className='"grid grid-cols-1 divide-y'>
				
				<Item title={'Leave Type'} subtitle={'Maternity Leave'} />
				<Item title={'Date Created'} subtitle={'1/12/2023'} />
				<Item title={'Description'} subtitle={ <p className='w-52'>This time off may be necessary for various medical reasons</p> } />
				<Item title={'Duration'} subtitle={'30 days'} />
				<hr />
			</div>
			<div className='flex flex-row-reverse m-3'>
				<Button
                 btnStyle = "btn-primary"
					onClick={() => setShowModal('edit')}
					title='Edit'
				/>
			</div>
		</>
	)
}
