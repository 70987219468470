import { faCameraRetro } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { primary_bg } from '../themes/themes'
import { UserModel, update_users_api } from '../service/user'
import { useRef } from 'react'
import { fileSrc } from './FileLoader'
import { uploadFile } from './uploadFile'
import { useMutation } from '@tanstack/react-query'

export default function ProfileCardAvater({
	data,
	refetch,
}: {
	data?: UserModel
	refetch?: () => void
}) {
	const update = useMutation({
		mutationFn: update_users_api,
		onSuccess: () => {
			refetch && refetch()
		},
	})
	const updateImage = async (file: File) => {
		const res = await uploadFile(file, 'profile')
		if (res.status) update.mutate({ id: data?.id, media: res.data })
	}
	return (
		<div>
			<div className='md:shrink-0 w-full bg-white py-3 rounded-xl  shadow-md'>
				<div className='h-48 w-full text-center object-cover md:h-full  md:w-60'>
					<div className='relative inline-block m-3'>
						<div className='w-fit border border-[#7C7F80] rounded-full'>
							<img
								className='inline-block h-[5.875rem] w-[5.875rem] m-1 rounded-full'
								src={
									data?.file
										? fileSrc(data.file)
										: 'https://i.pinimg.com/originals/c0/27/be/c027bec07c2dc08b9df60921dfd539bd.webp'
								}
								alt='Imag Description'
							/>
						</div>
						{data?.id && (
							<FileSelectInput
								id='image'
								name='media'
								label='Upload Image(JPG, PNG)'
								onChange={(file: File) => updateImage(file)}
								accept='image/*'
							/>
						)}
						{/* <span
							className={`absolute bottom-0 end-0 block text-white p-1 h-8 w-8 rounded-full ring-2 ring-white bg-[${primary_bg}]`}
						>
							<FontAwesomeIcon icon={faCameraRetro} />
						</span> */}
					</div>
					<p>
						{data?.firstname} {data?.lastname}
					</p>
					<p className='text-gray-500 text-sm my-1'>{data?.role?.name}</p>
					<p className='text-gray-500 text-sm'>User ID: {data?.id}</p>
				</div>
			</div>
		</div>
	)
}

const FileSelectInput = ({ onChange, ...rest }: any) => {
	const fileInputRef: any = useRef(null)

	const handleButtonClick = () => {
		fileInputRef.current.click()
	}

	const handleFileChange = (event: any) => {
		const selectedFile = event.target.files[0]

		onChange(selectedFile)
	}

	return (
		<div>
			<input
				type='file'
				id='file'
				name='file'
				onChange={handleFileChange}
				className='hidden' // Hide the default file input
				ref={fileInputRef}
				{...rest}
			/>
			<button
				type='button'
				onClick={handleButtonClick}
				className={`absolute bottom-0 end-0 block text-white p-1 h-8 w-8 rounded-full ring-2 ring-white bg-[${primary_bg}]`}
			>
				<FontAwesomeIcon icon={faCameraRetro} />
			</button>
		</div>
	)
}
