import React, { useRef } from 'react'
import { primary_bg } from '../themes/themes'
import { FieldMetaProps } from 'formik'

type InputProps = {
	meta?: FieldMetaProps<string>
	name: string
	label: string
	required?: boolean
	disabled?: boolean
	accept?: string
	onChange: (file: File) => void
}
const FileSelectInput = ({ meta, ...props }: InputProps) => {
	const handleFileChange = (event: any) => {
		const selectedFile = event.target.files[0]
		props.onChange(selectedFile)
	}

	return (
		<div>
			<div className='relative'>
				<label
					htmlFor={props.name}
					className={`block flex text-sm 
         ${meta?.touched && meta?.error ? 'text-[#F88078]' : 'text-[#7C7F80]'}`}
				>
					{props.label}{' '}
					{props.required && <div className='text-[#F44336]'>*</div>}
				</label>
				<input
					accept={props.accept}
					onChange={handleFileChange}
					type='file'
					className={`mt-1 p-2 border border-gray-200 rounded-md w-full  border
         ${props.disabled ? 'bg-[#EEEEEE]' : ''}
         ${
						meta?.touched && meta?.error ? 'border-red-500' : 'border-gray-300'
					} rounded-md`}
				/>
			</div>
			{meta?.touched && meta?.error && (
				<div className='text-red-500 text-sm'>{meta?.error}</div>
			)}
		</div>
	)
}

export default FileSelectInput

export const FileSelectInput1 = ({ label, onChange, ...rest }: any) => {
	const fileInputRef: any = useRef(null)

	const handleButtonClick = () => {
		fileInputRef.current.click()
	}

	const handleFileChange = (event: any) => {
		const selectedFile = event.target.files[0]

		onChange(selectedFile)
	}

	return (
		<div className=''>
			<label htmlFor='file' className='block text-sm  text-gray-600'>
				{label}
			</label>
			<div className=' border border-gray-200 p-5 mt-1  rounded-lg relative'>
				<input
					type='file'
					id='file'
					name='file'
					onChange={handleFileChange}
					className='hidden' // Hide the default file input
					ref={fileInputRef}
					{...rest}
				/>
				<button
					type='button'
					className={`  w-fit absolute top-1  right-2 text-white bg-[${primary_bg}] text-sm p-1  rounded-md`}
					onClick={handleButtonClick}
				>
					Upload image
				</button>
			</div>
		</div>
	)
}
