import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Button from '../../../component/Button'
import Table from '../../../component/table'
import { PayrollModel } from '../../../service/payroll'
import { SettingModel } from '../../../service/settings'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { fileSrc } from '../../../component/FileLoader'
import { print } from '../../../component/print'
dayjs.extend(utc)

export default function PayrollDetails({
	data,
	settings,
}: {
	data?: PayrollModel
	settings?: SettingModel
}) {
	const columns = [
		{
			key: 'rate',
			title: 'Rate/HR',
			render: (value: any, row: any) =>
				value.toLocaleString('en-US', {
					style: 'currency',
					currency: row.currency || 'USD',
				}),
		},
		{ key: 'hours_worked', title: 'Period Hours Worked' },
		{
			key: 'gross_pay',
			title: 'Period Pay',
			render: (value: any, row: any) =>
				value?.toLocaleString('en-US', {
					style: 'currency',
					currency: row.currency || 'USD',
				}),
		},
		{ key: 'ytd_hours_worked', title: 'YTD Hours Worked' },
		{
			key: 'ytd_pay',
			title: 'YTD Pay',
			render: (value: any, row: any) =>
				value?.toLocaleString('en-US', {
					style: 'currency',
					currency: row.currency || 'USD',
				}),
		},
		{
			key: 'net_pay',
			title: 'Net Pay',
			render: (value: any, row: any) =>
				value?.toLocaleString('en-US', {
					style: 'currency',
					currency: row.currency || 'USD',
				}),
		},
	]

	const DEDUCTIONScolumns = [
		// { key: 'id', title: 'ID' },
		{ key: 'leave_Type', title: 'FEDERAL TAX' },
		{ key: 'duration', title: 'PROVINCIAL TAX' },
		{ key: 'Description', title: 'CPP' },
		{ key: 'date_created', title: 'EI' },
		{ key: 'date_created', title: 'OTHER' },
		{ key: 'OTHER', title: 'EI' },
		{ key: 'TOTAL_DEDUCTIONS', title: 'TOTAL_DEDUCTIONS' },
	]
	const DEDUCTIONSdata = {
		count: '',
		data: {
			count: 5,
			rows: [
				{
					leave_Type: 'FEDERAL TAX',
					date_created: '$229.16',
					Description: '$119.57',
					duration: '$28000',
					OTHER: '$28000',
					TOTAL_DEDUCTIONS: '$28000',
				},
			],
		},
	}
	return (
		<div className='rounded-lg bg-white p-8'>
			<div className='flex justify-between'>
				<div className='flex-none text-xl font-semibold flex'>
					{settings?.business_logo && (
						<img
							className=' h-[30px] w-[30px] object-contain'
							src={fileSrc({
								id: settings?.business_logo,
								provider: 'cloudinary',
								format: '',
							})}
							alt=''
						/>
					)}
					<p className='px-2 text-[14px]'>{settings?.business_name} </p>
				</div>
				<p className='text-[#1E2325]'>PAYSLIP #{data?.id}</p>
			</div>
			<div className='font-[500] text-[14px] my-5'>
				<p
					dangerouslySetInnerHTML={{
						__html: settings?.business_address || '',
					}}
				></p>
			</div>
			<div className='font-[400] text-[14px] text-[#7C7F80]'>
				<p className='my-2'>
					{data?.user?.firstname} {data?.user?.lastname}
				</p>
				<p>{data?.user?.role?.name}</p>
				<p>User ID: {data?.user_id}</p>
			</div>

			<p className='font-[700] text-center m-6 text-[20px]'>
				{dayjs.utc(data?.start_date).format('Do MMMM YYYY')} -{' '}
				{dayjs.utc(data?.end_date).format('Do MMMM YYYY')}
			</p>

			<Table
				showHeaderIcon={true}
				columns={columns}
				tableStyle={'rounded-[10px] border border-gray-200 '}
				data={data ? [data] : []}
				hasCheckBox={false}
			/>

			<p className='font-[500] text-[14px] mt-5 ml-1'>DEDUCTIONS</p>
			<Table
				tableStyle={'rounded-[10px] border border-gray-200'}
				columns={DEDUCTIONScolumns}
				data={DEDUCTIONSdata.data.rows}
				showHeaderIcon={true}
				hasCheckBox={false}
			/>

			<div className='flex  justify-end px-6'>
				<div className=' w-fit p-3 mt-3'>
					<p className='text-[14px] font-[400] text-[#7C7F80]'>
						REGULAR PAY (PLUS GST/HST)
					</p>
					<p className='text-[16px] mt-1 font-[500]'>
						{data?.net_pay?.toLocaleString('en-US', {
							style: 'currency',
							currency: data.currency || 'USD',
						})}
					</p>
					<div className='no-print flex gap-2 mt-3'>
						<Button
							title='Download'
							className='h-fit'
							btnStyle='btn-primary'
							rightIcon={<FontAwesomeIcon icon={faDownload} />}
							onClick={() => print('landscape')}
						/>
						<Button title='Cancel' className='h-fit' btnStyle='btn-outline' />
					</div>{' '}
				</div>
			</div>
		</div>
	)
}
