// api.js

import axios from 'axios'
import { Response, queryParam } from './_model'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const timeoff_category_api = async (
	data: queryParam
): Promise<Response<TimeoffCategoryModel[]>> => {
	return await axios.get(
		`${BASE_URL}/timeoff-category?${data.queryKey[1] || ''}`
	)
}

export const create_timeoff_category_api = async (
	data: TimeoffCategoryModel
) => {
	return await axios.post(`${BASE_URL}/timeoff-category`, data)
}

export const update_timeoff_category_api = async (
	data: TimeoffCategoryModel
) => {
	return await axios.put(`${BASE_URL}/timeoff-category/${data.id}`, data)
}

export const delete_timeoff_category_api = async (data: string) => {
	return await axios.delete(`${BASE_URL}/timeoff-category/${data}`)
}

export type TimeoffCategoryModel = {
	id?: string
	name: string
	roles: (
		| string
		| {
				value: string
				label: string
		  }
	)[]
	paid: boolean
	status?: boolean
	note?: string
}
export const initialValues: TimeoffCategoryModel = {
	name: '',
	paid: false,
	roles: [],
	note: '',
}
