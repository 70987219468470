import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChartComponent = () => {
  const chartRef = useRef(null);
  const data = [55, 59, 50, 61, 56, 55, 40];
  const dataActive = [65, 65, 64, 81, 52, 55, 80];

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const myChart = new Chart(chartRef.current, {
        type: 'bar', // Changed from 'line' to 'bar'
        data: {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
            {
              label: 'Active',
              data: dataActive,
              backgroundColor: '#EC7211',
              barThickness: 10,
              borderRadius:4,
              barPercentage: 0.9, 
              categoryPercentage: 0.9, 
              grouped:true
            },
            {
              label: 'Contractors',
              data: data,
              backgroundColor: '#0D5F80',
              barThickness: 10,
              borderRadius:4,
              barPercentage: 0.9, 
              categoryPercentage: 0.9, 
              grouped:true
            
            },
          ],
        },
        options: {
          // Customize options as needed
          scales: {
            x: {
              // stacked: true, // If you want stacked bars
            },
            y: {
              // stacked: true, // If you want stacked bars
              beginAtZero: true, // Start y-axis from zero
            },
          },
        },
      });

      return () => {
        myChart.destroy(); // Cleanup chart on component unmount
      };
    }
  }, [data, dataActive]);

  return <canvas ref={chartRef} />;
};

export default BarChartComponent;
