import React, { useMemo, useState } from 'react'
import Modal from '../../component/Modal'
import Pagination from '../../component/Pagination'
import SearchInput from '../../component/SearchInput'
import Table from '../../component/table'
import Loading from '../../component/loading'
import { CreateService } from './form'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
	ServiceModel,
	delete_service_api,
	service_api,
} from '../../service/service'
import { useDebounce } from '@uidotdev/usehooks'
import ViewService from './ViewService'
import { settings_api } from '../../service/settings'
import { ActionButton } from '../../component/Buttons'
import FilterForm, { initialParams } from '../../component/FilterForm'
import CsvExportButton from '../../component/CsvExportButton'

export default function Service() {
	const [showModal, setShowModal] = useState<'create' | 'update' | 'read'>()
	const [selectedData, setSelectedData] = useState<ServiceModel>()
	const [pageParams, setPageParams] = useState(initialParams)
	const [search, setSearch] = useState('')
	const searchInput = useDebounce(search, 500)
	const queryParams = useMemo(() => {
		let res = `page_number=${pageParams.page_number}&page_size=${pageParams.page_size}&include=File`
		if (searchInput) res += `&search=${searchInput}`
		if (pageParams.filter) res += `&filter=${pageParams.filter}`
		return res
	}, [pageParams, searchInput])
	const updatePageParams = (key: string, value: any) => {
		setPageParams((prev) => ({ ...prev, [key]: value }))
	}
	const { data, isLoading, refetch } = useQuery({
		queryKey: ['services', queryParams],
		queryFn: service_api,
	})
	const { data: settings } = useQuery({
		queryKey: ['settings'],
		queryFn: settings_api,
	})

	const deleteMutation = useMutation({
		mutationFn: delete_service_api,
		onSuccess: () => {
			refetch()
		},
	})
	const actionClick = (
		action: 'create' | 'update' | 'read' | 'delete',
		row: any,
		value: any
	) => {
		if (action === 'delete') deleteMutation.mutate(value)
		else {
			setSelectedData(row)
			setShowModal(action)
		}
	}
	const columns = [
		{ key: 'id', title: 'Service ID' },
		{ key: 'name', title: 'name' },
		{
			key: 'charge',
			title: 'Charge',
			render: (value: any) =>
				value.toLocaleString('en-US', {
					style: 'currency',
					currency: settings?.currency || 'USD',
				}),
		},
		{
			key: 'status',
			title: 'Status',
			render: (value: any, row: any) => (
				<>
					<div
						className={`${
							value ? ' text-green-600 bg-green-100' : 'text-red-600 bg-red-100'
						} 
                    text-[12px] text-center p-1 px-2 w-fit   rounded-full`}
					>
						{value ? 'Active' : 'Inactive'}
					</div>
				</>
			),
		},
		{
			key: 'id',
			title: 'Action',
			render: (value: any, row: any) => (
				<div className={`flex gap-2`}>
					{['read', 'update', 'delete'].map((action: any) => (
						<ActionButton
							key={action}
							action={action}
							id='services'
							onClick={() => actionClick(action, row, value)}
						/>
					))}
				</div>
			),
		},
	]
	return (
		<div className=' bg-white rounded-lg'>
			<div className=' sm:flex p-4 justify-between'>
				<p className='text-[24px] font-medium'>Service</p>
				<div className='flex'>
					<ActionButton
						title='Add Service'
						action='create'
						id='services'
						onClick={() => actionClick('create', undefined, undefined)}
					/>
					<CsvExportButton
						data={data?.rows}
						headers={undefined}
						filename={'location'}
						buttonText={'Export as CSV'}
					/>
				</div>
			</div>
			<div className='sm:grid sm:grid-cols-8 gap-4 border-dashed border-t  p-4'>
				<FilterForm
					fields={['status']}
					values={JSON.parse(pageParams.filter || '{}')}
					action={(val) => updatePageParams('filter', val)}
				/>
				<div className='col-span-7 '>
					<SearchInput
						placeholder='Search ...'
						value={search}
						onChange={(e: any) => setSearch(e.target.value)}
					/>
				</div>
			</div>
			{isLoading ? (
				<Loading />
			) : (
				<Table
					columns={columns}
					data={data?.rows}
					showHeaderIcon={undefined}
					hasCheckBox={true}
				/>
			)}

			<div
				className='flex overflow-x-auto sm:justify-end'
				id='pagination-globle-style'
			>
				<Pagination
					setPageSize={(val: number) => updatePageParams('page_size', val)}
					setPageNumber={(val: number) => updatePageParams('page_number', val)}
					currentPage={pageParams.page_number}
					pageSize={pageParams.page_size}
					totalCount={data?.count || 0}
				/>
			</div>

			<Modal
				showModal={showModal === 'read'}
				setShowModal={setShowModal}
				title={`Service`}
				size={'md'}
			>
				<ViewService selectedData={selectedData!} setShowModal={setShowModal} />
			</Modal>

			<Modal
				showModal={['create', 'update'].includes(showModal || '')}
				setShowModal={setShowModal}
				title={`${selectedData?.id ? 'update' : 'Create'} Service`}
				size={'lg'}
			>
				<CreateService
					refetch={refetch}
					selectedData={selectedData}
					setShowModal={setShowModal}
				/>
			</Modal>
		</div>
	)
}
