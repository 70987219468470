import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import Button from '../../component/Button'
import FileSelectInput from '../../component/FileSelectInput'
import Input from '../../component/Input'
import RadioInput from '../../component/RadioInput'
import SelectInput from '../../component/SelectInput'
import {
	create_location_api,
	update_location_api,
} from '../../service/location'
import { locationValidation } from './formValidation'
import { countries } from '../../utility/countries'
import Textarea from '../../component/textarea'
import { compareObject } from '../../component/functions'
import { uploadFile } from '../../component/uploadFile'
import AsyncSelect from 'react-select/async'

export default function CreateLocation({
	refetch,
	setShowModal,
	selectedData,
}: any) {
	const [show, setShow] = useState(false)
	const mutation = useMutation({
		mutationFn: selectedData?.id ? update_location_api : create_location_api,
		onSuccess: () => {
			refetch()
			setShowModal(false)
		},
	})
	const initialValues = selectedData || {
		id: undefined,
		name: '',
		type: 'physical',
		phone: '',
		email: '',
		address: '',
		zip_code: '',
		city: '',
		state: '',
		country: '',
		longitude: '',
		latitude: '',
		note: '',
		status: true,
	}

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit: async (values) => {
			let v = { ...values }
			if (values.id) {
				v = compareObject(values, initialValues)
				v.id = values.id
			}
			if (v.media) {
				const res = await uploadFile(values.media, 'location')
				if (res.status) v.media = res.data
				else delete v.media
			}
			mutation.mutate(v)
		},

		validationSchema: locationValidation,
	})
	// const [status, setStatus] = useState('initial')
	// const userLocation = useRef<any>(null)
	// const getUserLocation = () => {
	// 	if (navigator.geolocation) {
	// 		setStatus('loading')
	// 		navigator.geolocation.getCurrentPosition(
	// 			(position) => {
	// 				userLocation.current = {
	// 					lat: position.coords.latitude,
	// 					lng: position.coords.longitude,
	// 				}
	// 				console.log(userLocation)
	// 				// mapObject.setCenter(userLocation);
	// 				setStatus('watching')
	// 				// ************ ADDED FROM HERE ***************
	// 				navigator.geolocation.watchPosition(
	// 					(position) => {
	// 						userLocation.current = {
	// 							lat: position.coords.latitude,
	// 							lng: position.coords.longitude,
	// 						}
	// 					},
	// 					(error) => {
	// 						// Insert code for handling Geolocation API errors
	// 					},
	// 					{ maximumAge: 0 }
	// 				)
	// 				// ************ ADDED UNTIL HERE **************
	// 			},
	// 			(error) => {
	// 				// Insert code for handling Geolocation API errors
	// 			},
	// 			{ maximumAge: 1000 }
	// 		)
	// 	} else {
	// 		// Insert code for legacy browsers not supporting Geolocation API
	// 	}
	// }
	// useEffect(() => {
	// 	getUserLocation()
	// }, [])

	const getAddresses = async (input: string) => {
		const res = await fetch(
			`https://places.googleapis.com/v1/places:autocomplete`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-Goog-Api-Key': process.env.REACT_APP_GOOGLE_API_KEY,
				} as HeadersInit,
				body: JSON.stringify({
					input,
					sessionToken: '123456',
				}),
			}
		)
		const result = await res.json()
		return result.suggestions.map((data: any) => {
			return {
				value: data.placePrediction.placeId,
				label: data.placePrediction.text?.text,
			}
		})
	}
	const getAddress = async (input: string) => {
		if (!input) return
		const res = await fetch(
			`https://places.googleapis.com/v1/places/${input}?sessionToken=123456`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'X-Goog-Api-Key': process.env.REACT_APP_GOOGLE_API_KEY,
					'X-Goog-FieldMask': 'formattedAddress,addressComponents',
				} as HeadersInit,
			}
		)
		const result = await res.json()
		formik.setFieldValue('address', result.formattedAddress)
		result.addressComponents.forEach((data: any) => {
			if (
				data.types.includes('locality') ||
				data.types.includes('postal_town')
			) {
				formik.setFieldValue('city', data.longText)
			}
			if (data.types.includes('administrative_area_level_1')) {
				formik.setFieldValue('state', data.longText)
			}
			if (data.types.includes('country')) {
				formik.setFieldValue('country', data.longText)
			}
			if (data.types.includes('postal_code')) {
				formik.setFieldValue('zip_code', data.longText)
			}
		})
	}
	const promiseOptions = (inputValue: string) =>
		new Promise<{ value: string; label: string }[]>((resolve) => {
			resolve(getAddresses(inputValue))
		})
	return (
		<div>
			<form onSubmit={formik.handleSubmit} className='sm:p-3'>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
					<Input
						formik={formik}
						required={true}
						label='Name'
						id='name'
						name='name'
						type='text'
						value={formik.values.name}
						onChange={formik.handleChange}
					/>
					<SelectInput
						formik={formik}
						label='Location type'
						id='type'
						name='type'
						onChange={formik.handleChange}
						value={formik.values.type}
						options={[
							{ id: 'physical', name: 'Physical' },
							{ id: 'virtual', name: 'Virtual' },
						]}
						option={{ value: 'id', label: 'name' }}
						required={true}
					/>

					<Input
						formik={formik}
						required={true}
						label='Email'
						id='email'
						name='email'
						type='email'
						value={formik.values.email}
						onChange={formik.handleChange}
					/>
					<Input
						formik={formik}
						label='Phone'
						id='phone'
						name='phone'
						type='text'
						value={formik.values.phone}
						onChange={formik.handleChange}
					/>

					<AsyncSelect
						className='col-span-2'
						cacheOptions
						loadOptions={promiseOptions}
						value={{
							value: formik.values.address,
							label: formik.values.address,
						}}
						defaultOptions={[
							{
								value: formik.values.address,
								label: formik.values.address,
							},
						]}
						onChange={(selectedOption: any) => getAddress(selectedOption.value)}
						placeholder='Search Address...'
					/>
					<Input
						formik={formik}
						required={true}
						label='City'
						id='city'
						name='city'
						value={formik.values.city}
						onChange={formik.handleChange}
					/>
					<Input
						formik={formik}
						required={true}
						label='State/Province'
						id='state'
						name='state'
						value={formik.values.state}
						onChange={formik.handleChange}
					/>
					<Input
						formik={formik}
						required={true}
						label='Zip Code'
						id='zip_code'
						name='zip_code'
						value={formik.values.zip_code}
						onChange={formik.handleChange}
					/>
					<SelectInput
						formik={formik}
						label='Country'
						id='country'
						name='country'
						onChange={formik.handleChange}
						value={formik.values.country}
						options={countries.map((country) => ({
							id: country,
							name: country,
						}))}
						option={{ value: 'id', label: 'name' }}
						required={true}
					/>
					{formik.values.id && (
						<div className=' gap-3 mb-2'>
							<p className='text-[#7C7F80]'>Status</p>
							<div className='flex gap-3 mt-2'>
								<RadioInput
									id='Status'
									name='status'
									value={true}
									label='Active'
									field={formik.getFieldProps('status')}
									onChange={() => formik.setFieldValue('status', true)}
								/>
								<RadioInput
									id='StatusNo'
									name='status'
									value={false}
									label='Inactive'
									field={formik.getFieldProps('status')}
									onChange={() => formik.setFieldValue('status', false)}
								/>
							</div>
						</div>
					)}
				</div>

				<button
					className='flex text-[16px] font-medium'
					type='button'
					onClick={() => setShow(!show)}
				>
					Optional
					<div className='mb-1 ml-2'>
						{show ? (
							<FontAwesomeIcon icon={faCaretDown} />
						) : (
							<FontAwesomeIcon icon={faCaretUp} />
						)}
					</div>
				</button>
				{show && (
					<div className='grid grid-cols-1 md:grid-cols-2 gap-4 my-3 '>
						<Input
							label='Longitude'
							id='longitude'
							name='longitude'
							type='number'
							value={formik.values.longitude}
							onChange={formik.handleChange}
						/>
						<Input
							label='Latitude'
							id='latitude'
							name='latitude'
							type='number'
							value={formik.values.latitude}
							onChange={formik.handleChange}
						/>
						<FileSelectInput
							meta={formik.getFieldMeta('media')}
							name='media'
							label='Upload Image(JPG, PNG)'
							onChange={(file: any) => formik.setFieldValue('media', file)}
							accept='image/*'
						/>

						<Textarea
							label='Note'
							id='note'
							name='note'
							type='text'
							value={formik.values.note}
							onChange={formik.handleChange}
						/>
					</div>
				)}

				<div className='flex flex-row-reverse gap-2'>
					<Button
						btnStyle='btn-outline'
						title='Cancel'
						type='button'
						onClick={() => setShowModal()}
					/>
					<Button
						type='submit'
						btnStyle='btn-primary'
						loading={mutation.isPending}
						title={formik.values.id ? 'Update' : 'Create'}
					/>
				</div>
			</form>
		</div>
	)
}
