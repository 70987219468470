import { useFormik } from 'formik'
import React from 'react'
import Textarea from '../../../component/textarea'
import { useMutation } from '@tanstack/react-query'
import { MultiSelect } from '../../../component/SelectInput'
import { update_timeoff_decision_api } from '../../../service/timeOffRequest'
import Button from '../../../component/Button'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
	id: Yup.string().required().label('Id'),
	status: Yup.string().required().label('Approval status'),
	note: Yup.string().label('Approval note'),
})
export default function TimeOffDecision({
	refetch,
	setShowModal,
	selectedData,
}: any) {
	const mutation = useMutation({
		mutationFn: update_timeoff_decision_api,

		onSuccess: () => {
			refetch()
			setShowModal(false)
		},
	})
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			id: selectedData.id,
			status: 'pending',
			approval_note: '',
		},
		onSubmit: (values) => {
			mutation.mutate(values)
		},
		validationSchema,
	})
	return (
		<div>
			<form
				onSubmit={formik.handleSubmit}
				className='sm:px-3 flex flex-col gap-2'
			>
				<MultiSelect
					meta={formik.getFieldMeta('status')}
					onChange={(v: any) => formik.setFieldValue('status', v?.value)}
					label='Approval Status'
					name='status'
					// isLoading={locationFetching}
					options={['pending', 'approved', 'declined'].map((data) => {
						return {
							value: data,
							label: data,
						}
					})}
				/>
				<Textarea
					label='Leave Note'
					id='approval_note'
					name='approval_note'
					type='text'
					value={formik.values.approval_note}
					onChange={formik.handleChange}
				/>
				<div className='flex flex-row-reverse gap-2 ...'>
					<Button
						btnStyle='btn-outline'
						title='Cancel'
						type='button'
						onClick={() => setShowModal()}
					/>
					<Button
						type='submit'
						btnStyle='btn-primary'
						loading={mutation.isPending}
						title='Submit'
					/>
				</div>
			</form>
		</div>
	)
}
