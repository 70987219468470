import { useDebounce } from '@uidotdev/usehooks'
import React, { useMemo, useState } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import Loading from '../../../component/loading'
import Modal from '../../../component/Modal'
import Pagination from '../../../component/Pagination'
import SearchInput from '../../../component/SearchInput'
import Table from '../../../component/table'
import CreateTimeOffCategory from './createTimeOffCategory'
import {
	TimeoffCategoryModel,
	delete_timeoff_category_api,
	timeoff_category_api,
} from '../../../service/timeoffCategory'
import {
	CreateButton,
	DeleteButton,
	EditButton,
} from '../../../component/Buttons'
import FilterForm, { initialParams } from '../../../component/FilterForm'
// import ViewTimeOff from '../request/viewTimeOff'
import ViewTimeoffCategory from './viewTimeoffCategory'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import CsvExportButton from '../../../component/CsvExportButton'

export default function TimeOffCategory() {
	const [showModal, setShowModal] = useState<'create' | 'edit' | 'view'>()
	const [selectedData, setSelectedData] = useState<TimeoffCategoryModel>()
	const [pageParams, setPageParams] = useState(initialParams)
	const [search, setSearch] = useState('')
	const searchInput = useDebounce(search, 500)
	const queryParams = useMemo(() => {
		let res = `page_number=${pageParams.page_number}&page_size=${pageParams.page_size}`
		if (searchInput) res += `&search=${searchInput}`
		if (pageParams.filter) res += `&filter=${pageParams.filter}`
		return res
	}, [pageParams, searchInput])
	const updatePageParams = (key: string, value: any) => {
		setPageParams((prev) => ({ ...prev, [key]: value }))
	}
	const { data, isLoading, refetch } = useQuery({
		queryKey: ['timeoff_categories', queryParams],
		queryFn: timeoff_category_api,
	})
	const deleteMutation = useMutation({
		mutationFn: delete_timeoff_category_api,
		onSuccess: () => {
			refetch()
		},
	})

	const columns = [
		{ key: 'name', title: 'Name' },
		{
			key: 'paid',
			title: 'Paid',
			render: (value: any) => (value ? 'Yes' : 'No'),
		},
		{
			key: 'status',
			title: 'status',
			render: (value: any, row: any) => (
				<div
					className={`${
						value ? ' text-green-600 bg-green-100' : 'text-red-600 bg-red-100'
					} 
                      text-[12px] text-center px-2 py-1 w-fit   rounded-full`}
				>
					{value ? 'Active' : 'Inactive'}
				</div>
			),
		},
		{
			key: 'id',
			title: 'Action',
			render: (value: any, row: TimeoffCategoryModel) => (
				<div className={`flex gap-2`} key={row.id}>
					<FontAwesomeIcon
						onClick={() => {
							setSelectedData(row)
							setShowModal('view')
						}}
						icon={faEye}
					/>
					<EditButton
						onClick={() => {
							setSelectedData(row)
							setShowModal('edit')
						}}
						id='timeoff-categories'
					/>
					<DeleteButton
						onClick={() => deleteMutation.mutate(value)}
						id='timeoff-categories'
					/>
				</div>
			),
		},
	]
	return (
		<div className=' bg-white rounded-lg'>
			{/* <button onClick={() => setShowModal(true)}>Open Modal</button> */}
			<div className=' sm:flex p-4 justify-between'>
				<p className='text-[24px] font-medium'>Time off category</p>
				<div className='flex'>
					<CreateButton
						title='Add New'
						onClick={() => {
							setSelectedData(undefined)
							setShowModal('create')
						}}
						id='timeoff-categories'
					/>
					<CsvExportButton
						data={data?.rows}
						headers={undefined}
						filename={'TimeoffRequest'}
						buttonText={'Export as CSV'}
					/>
				</div>
			</div>
			<div className='sm:grid sm:grid-cols-8 gap-4 border-dashed border-t  p-4'>
				<FilterForm
					fields={['status', 'paid']}
					values={JSON.parse(pageParams.filter || '{}')}
					action={(val) => updatePageParams('filter', val)}
				/>
				<div className='col-span-7 '>
					<SearchInput
						placeholder='Search ...'
						value={search}
						onChange={(e: any) => setSearch(e.target.value)}
					/>
				</div>
			</div>
			{isLoading ? (
				<Loading />
			) : (
				<Table
					columns={columns}
					data={data?.rows}
					showHeaderIcon={undefined}
					hasCheckBox={true}
				/>
			)}

			<div
				className='flex overflow-x-auto sm:justify-end'
				id='pagination-globle-style'
			>
				<Pagination
					setPageSize={(val: number) => updatePageParams('page_size', val)}
					setPageNumber={(val: number) => updatePageParams('page_number', val)}
					currentPage={pageParams.page_number}
					pageSize={pageParams.page_size}
					totalCount={data?.count || 0}
				/>
			</div>

			<Modal
				showModal={showModal === 'view'}
				setShowModal={setShowModal}
				title='View Timeoff'
				size={'md'}
			>
				<ViewTimeoffCategory
					selectedData={selectedData!}
					setShowModal={setShowModal}
				/>
			</Modal>
			<Modal
				showModal={['create', 'edit'].includes(showModal || '')}
				setShowModal={setShowModal}
				title={`${selectedData?.id ? 'Edit' : 'Create'} Timeoff Category`}
				size={'lg'}
			>
				<CreateTimeOffCategory
					refetch={refetch}
					selectedData={selectedData}
					setShowModal={setShowModal}
				/>
			</Modal>
		</div>
	)
}
