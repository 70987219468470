import React, { useState } from 'react'
import { useFormik } from 'formik'
import { NavLink } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { login_api, login_with_token_api } from '../../service/auth'
import PasswordInput from '../../component/PasswordInput'
import Input from '../../component/Input'
import { LoginValidation } from './helper/formValidation'

import { primary_bg, secondary_bg } from '../../themes/themes'
import { useAuth } from '../../context/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { SubmitButton } from '../../component/Buttons'

const Login = () => {
	const { setToken } = useAuth()
	const [rememberMe, setRememberMe] = useState(false)
	const mutation = useMutation({
		mutationFn: login_api,
		onSuccess: (data) => {
			if (data.token_type === 'Bearer') {
				setToken(data.token)
				localStorage.setItem('refresh_token', data.refresh_token)
			}
		},
	})
	const mutation2 = useMutation({
		mutationFn: login_with_token_api,
		onSuccess: (data) => {
			if (data.token_type === 'Bearer') {
				setToken(data.token)
				localStorage.setItem('refresh_token', data.refresh_token)
			}
		},
	})

	const handleRememberMeChange = () => {
		setRememberMe(!rememberMe)
	}

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: LoginValidation,
		onSubmit: (values: any) => mutation.mutate(values),
	})

	return (
		<div className='relative z-10 bg-white p-8 rounded-lg sm:w-96'>
			<h2 className='text-2xl  mb-1 text-center'>Welcome Back!</h2>
			<p className='text-gray-400 text-sm text-center mb-4'>
				it is great you are back
			</p>
			{mutation.data?.businesses ? (
				mutation.data.businesses.map((entity) => (
					<div
						key={entity.id}
						className='bg-white rounded p-2 flex flex-col  gap-2 h-fit'
					>
						<button
							onClick={() =>
								mutation2.mutate({
									business_id: entity.id,
									token: mutation.data.token,
								})
							}
							className={`flex w-full justify-between p-3  transition-all duration-300
								 ${
										mutation2.variables?.business_id === entity.id &&
										`text-primary bg-[${secondary_bg}]`
									}
								 rounded items-center`}
						>
							<p>{entity.name}</p>
							<FontAwesomeIcon icon={faChevronRight} />
						</button>
					</div>
				))
			) : (
				<form onSubmit={formik.handleSubmit}>
					{/* Username Input */}
					<Input
						required={true}
						type='text'
						id='email'
						name='email'
						onChange={formik.handleChange}
						label='Email'
						formik={formik}
						value={formik.values.email}
					/>
					<br />
					<PasswordInput
						required={true}
						label='Password'
						id='password'
						name='password'
						type='password'
						value={formik.values.password}
						formik={formik}
						onChange={formik.handleChange}
					/>

					{/* Remember Me Checkbox */}
					<div className='flex justify-between my-2 pt-4'>
						<div className='mb-4 flex items-center'>
							<input
								type='checkbox'
								id='rememberMe'
								className='mr-2'
								checked={rememberMe}
								onChange={handleRememberMeChange}
							/>
							<label htmlFor='rememberMe' className='text-sm  mr-3'>
								Remember me
							</label>
						</div>
						<NavLink to={'/auth/forget-password'}>
							<small className={`text-[${primary_bg}] ml-3 text-sm  `}>
								Forgot your password?
							</small>
						</NavLink>
					</div>

					{/* Submit Button */}
					<SubmitButton
						loading={mutation.isPending || mutation2.isPending}
						text='Login'
					/>
				</form>
			)}
		</div>
	)
}

export default Login
