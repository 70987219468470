
export function getColorWithOpacity(color: string, opacity: any) {
    // Convert hex to RGB
    let hex = color?.replace(/^#/, '');
    let rgb = parseInt(hex, 16);
    let r = (rgb >> 16) & 0xff;
    let g = (rgb >>  8) & 0xff;
    let b = (rgb >>  0) & 0xff;
  
    // Return RGBA string
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  
  