// Input.jsxs
import { FieldInputProps, FieldMetaProps } from 'formik'
import React from 'react'

const Input = ({
	label,
	id,
	name,
	type,
	value,
	onChange,
	formik,
	required,
	disabled,
}: any) => {
	return (
		<div>
			<div className='relative'>
				<label
					htmlFor={id}
					className={`block flex text-sm  text-[#7C7F80] 
         ${
						formik?.touched[name] && formik.errors[name] ? 'text-[#F88078]' : ''
					}`}
				>
					{label} {required && <div className='text-[#F44336]'>*</div>}
				</label>
				<input
					disabled={disabled}
					type={type}
					id={id}
					name={name}
					onChange={onChange ?? formik?.handleChange}
					value={value}
					className={`mt-1 p-1.5 border border-gray-200 rounded-md w-full  border
         ${disabled ? 'bg-[#EEEEEE]' : ''}
         ${
						formik?.touched[name] && formik.errors[name]
							? 'border-red-500'
							: 'border-gray-300'
					} rounded-md`}
				/>
			</div>
			{formik?.touched[name] && formik.errors[name] && (
				<div className='text-red-500 text-sm'>{formik.errors[name]}</div>
			)}
		</div>
	)
}
type InputProps = FieldInputProps<string> & {
	meta?: FieldMetaProps<string>
	label?: string
	required?: boolean
	disabled?: boolean
	type?: string
	placeholder?: string
}
export const Input2 = ({ label, meta, ...props }: InputProps) => {
	return (
		<div>
			<div className='relative'>
				{label && (
					<label
						htmlFor={props.name}
						className={`block flex text-sm 
         ${meta?.touched && meta?.error ? 'text-[#F88078]' : 'text-[#7C7F80]'}`}
					>
						{label} {props.required && <div className='text-[#F44336]'>*</div>}
					</label>
				)}
				<input
					{...props}
					className={`mt-1 p-2 border border-gray-200 rounded-md w-full  border
         ${props.disabled ? 'bg-[#EEEEEE]' : ''}
         ${
						meta?.touched && meta?.error ? 'border-red-500' : 'border-gray-300'
					} rounded-md`}
				/>
			</div>
			{meta?.touched && meta?.error && (
				<div className='text-red-500 text-sm'>{meta?.error}</div>
			)}
		</div>
	)
}

export default Input
