// Input.jsxs
import React from 'react';

const Textarea = ({ label, id, name, cols, value, onChange, formik, required, disabled }: any) => {
  return (
    <div>

      <div className="relative">
        <label htmlFor={id} className={`block flex text-sm  text-[#7C7F80] 
         ${formik?.touched[name]
           && formik.errors[name] ? 'text-[#F88078]' : ''}`}>
             
          {label}  {required && <div className='text-[#F44336]'>*</div>}
        </label>
        <textarea
        cols={cols}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          className={`mt-1 p-2 border border-gray-200 rounded-md w-full  border
         ${disabled ? 'bg-[#EEEEEE]' : ''}
         ${formik?.touched[name] && formik.errors[name] ? 'border-red-500' : 'border-gray-300'
            } rounded-md`}

        ></textarea>
    
        </div>
        {formik?.touched[name] && formik.errors[name] && (
          <div className="text-red-500 text-sm">{formik.errors[name]}</div>
        )}
      </div>
      );
};

      export default Textarea;
