import { get_signed_url, upload_file } from '../service/misc'

export const uploadFile = async (file: File, folder?: string) => {
	try {
		const signData = await get_signed_url(folder)
		const result = await upload_file(
			{
				file,
				api_key: signData.apikey,
				timestamp: signData.timestamp,
				signature: signData.signature,
				folder: signData.folder || 'schedule',
			},
			signData.cloudname
		)
		return {
			status: true,
			data: {
				id: result.data.public_id,
				format: result.data.format,
				provider: 'cloudinary',
			},
		}
	} catch (error) {
		return { status: false }
	}
}
